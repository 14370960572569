

<template>
	<div />
</template>

<script>
export default {
	name: 'ErrorModalPage',
	created () {
		this.$eDialog({
			title: this.$t('notice'),
			description: this.$t('failed_chunk_message'),
			actions: {
				close: {
					label: this.$t('ok'),
					key: false
				},
			},
		})
	},
}
</script>

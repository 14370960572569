<template>
	<div>
		<img
			v-if="pictureLink && !imageError"
			:class="className"
			:src="pictureLink"
			alt="profile image"
			@error="pictureError($event, 'link', setError)">
		<div
			v-if="imageError || !pictureLink"
			:class="iconClassName">
			<span
				v-if="pictureType === 'link'"
				class="icon-file-link" />
		</div>
	</div>
</template>

<script>
export default {
	name: "AttachmentHandler",
	props: {
		className: {
			type: String,
			required: false,
			default: "",
		},
		iconClassName: {
			type: String,
			required: false,
			default: "",
		},
		pictureType: {
			type: String,
			required: true,
			default: "",
		},
		// eslint-disable-next-line vue/require-default-prop
		pictureLink: [ String, Object ],
	},
	data: function () {
		return {
			imageError: false,
		}
	},
	methods:{
		setError () {
			this.imageError = true
		},
	},
}
</script>

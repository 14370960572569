export const state = () => ({
	locationHash: '',
	webActive: false,
	desktop: false,
	fullscreenChat: false,
	windowWidth: false,
	miniChat: false,
	fullChat: false,
	windowHasFocus: true,
	mobile: false,
	isIOS: false,
	showScrollDown: false,
	firstLoad: true,
	touchDevice: 'ontouchstart' in window || navigator.msMaxTouchPoints || false,
	isIe: false,
	isNative: false,
	appTheme: 'light',
	isOffline: false,
	platform: '',
	nativeVersion: 0,
	iosData: {},
	loginStatuss: '',
	returnUrl: '',
	virtualKeyboard: '',
	offlineFunction: () => {},
	unauthorizedFunction: () => {},
})

export const mutations = {
	updateAppTheme (state, value) {
		state.appTheme = value
	},
	updateLocationHash (state, value) {
		state.locationHash = value
	},
	updateWebActive (state, value) {
		state.webActive = value
	},
	updateDesktop (state, value) {
		state.desktop = value
	},
	updateMobile (state, value) {
		state.mobile = value
	},
	updateIsIOS (state, value) {
		state.isIOS = value
	},
	updateMiniChat (state, value) {
		state.miniChat = value
	},
	updateFullChat (state, value) {
		state.fullChat = value
	},
	updateWindowWidth (state, value) {
		state.windowWidth = value
	},
	updateFullscreenChat (state, value) {
		state.fullscreenChat = value
	},
	updateWindowHasFocus (state, value) {
		state.windowHasFocus = value
	},
	updateShowScrollDown (state, value) {
		state.showScrollDown = value
	},
	updateFirstLoad (state, value) {
		state.firstLoad = value
	},
	updateIsIe (state, value) {
		state.isIe = value
	},
	updateIsNative (state, value) {
		state.isNative = value
	},
	updatePlatform (state, value) {
		state.platform = value
	},
	updateNativeVersion (state, value) {
		state.nativeVersion = value
	},
	updateIosData (state, value) {
		state.iosData = value
	},
	updateOfflineFunction (state, value) {
		state.offlineFunction = value
	},
	updateOfflineStatus (state, value) {
		state.isOffline = value
	},
	updateUnauthorizedFunction (state, value) {
		state.unauthorizedFunction = value
	},
	updateReturnUrl (state, value) {
		state.returnUrl = value
	},
	updateVirtualKeyboard (state, value) {
		state.virtualKeyboard = value
	},
	updateLoginStatuss (state, value) {
		state.loginStatuss = value
	},
}

export const mapSetter = (state, setters = {}) => (
	Object.keys(state).reduce((storeObj, stateName) => {
		storeObj[stateName] = {
			get: state[stateName],
		};
		// check if theres a mapped mutation with the same name and set it as a setter for state
		if (setters[stateName]) {
			storeObj[stateName].set = setters[stateName]
		}
		return storeObj;
	}, {})
)
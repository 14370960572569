<template>
	<v-app>
		<main class="container-fluid h-100 pa-0">
			<!-- Router View -->
			<router-view v-if="!userSettingsBusy" />

			<AppLoader
				class="d-flex loader-overlay"
				:showLoader="userSettingsBusy" />
			<UserHandler />
		</main>
	</v-app>
</template>

<script lang="js">
import { getEnv } from '@env'
import UserHandler from '@childComponents/UserHandler'

import { userComputed, generalComputed, interfaceComputed, overviewComputed, messageListComputed, userMethods, overviewMethods, socketMethods } from '@storeHelper'

export default {
	name: 'App',
	components: {
		UserHandler,
	},
	data: function () {
		return {
			test: 0,
			offlineModalOpened: false,
			focusOnMiniChat: false,
		}
	},
	computed: {
		...userComputed,
		...generalComputed,
		...interfaceComputed,
		...overviewComputed,
		...messageListComputed,
	},
	watch: {
		focusOnMiniChat (){
			if(this.focusOnMiniChat){
				this.readLastMessage()
			}

		},
		windowHasFocus (newValue) {
			newValue ? this.readLastMessage() : ""
		},
	},
	created () {
		if(this.miniChat){
			window.addEventListener("message", (event) => {
				this.focusOnMiniChat = event.data.status
			})
		}
		this.offlineFunction = async () => {
			if (!this.offlineModalOpened) {
				this.offlineModalOpened = true
				this.offlineModalOpened = await this.kawaiErrorModal(this.$t('no_internet_connection'))
			}
		}
		this.unauthorizedFunction = this.unauthorizedHandler
		if(this.isNative){
			if(this.platform === 'ios'){
				window.ReactNativeWebView.postMessage('{"type": "getIosData"}')
			}
			window.addEventListener("message", (event) => {
				this.messageHandler(event)
			})
			window.ReactNativeWebView.postMessage('{"type": "getGeneralData"}')
		}



		if(!this.miniChat && !this.fullChat){
			this.$router.push({ path: '/' }, () => {})
		}
		this.getUserSettings().then(() => {
		}, error => {
			this.nativeLoadOk()
			this.showErrorModal(String(error))
		})
		this.checkDevice()

		setInterval(this.checkPageFocus, 300);

		const ua = window.navigator.userAgent  //Check the userAgent property of the window.navigator object
		const msie = ua.indexOf( 'MSIE ' ) // IE 10 or older
		const trident = ua.indexOf( 'Trident/' ) //IE 11

		this.isIe = (msie > 0 || trident > 0);
	},
	mounted () {
		this.setLocationHash()
		// set dark theme according to initial theme=dark query param
		if (this.appTheme === 'dark') {
			this.$vuetify.theme.dark = true
		}
		// vh css variable setup
		this.handleResize()
		window && window.addEventListener('resize', this.handleResize)
		window.visualViewport.addEventListener('resize', this.handleResize)
		document.addEventListener("visibilitychange", () => {
			setTimeout(() => this.handleResize(), 100)
		});
	},
	beforeDestroy () {
		window && window.removeEventListener('resize', this.handleResize)
		window.visualViewport.removeEventListener('resize', this.handleResize)
		document.removeEventListener("visibilitychange", () => {
			setTimeout(() => this.handleResize(), 100)
		});
	},
	methods: {
		...userMethods,
		...socketMethods,
		...overviewMethods,
		nativeLoadOk () {
			if(this.isNative){
				window.ReactNativeWebView.postMessage(JSON.stringify({ activityIndicatorChanged: true }))
			}
		},
		unauthorizedHandler () {
			if(this.isNative){
				window.ReactNativeWebView.postMessage('{"type": "logout", "data": "error"}')
			}else{
				setTimeout(() => {
					this.$eDialog({
						title: this.$t('unauthorized'),
						width: 485,
						actions: {
							close: {
								label: this.$t('go_to_login'),
								key: false,
								handler: () => {
									window.top.location.replace(getEnv('VUE_APP_FRONTEND_API_LOGIN_URL'))
								}
							},
							submit: {
								label: this.$t('stay'),
								key: true,
							}
						},
					})
				}, 200);
			}
		},
		messageHandler (event) {
			try{
				let postMessageData = JSON.parse(event.data)
				if(postMessageData.action == "phoneActive"){
					this.mobileRefresh(postMessageData)
				}else if(postMessageData.action == "iosData"){
					this.iosData = postMessageData.data
				}else if(postMessageData.action == "generalData"){
					this.nativeVersion = postMessageData.data.version
				}
			}catch(e){
				// eslint-disable-next-line no-console
				console.log(e)
			}
		} ,
		mobileRefresh () {
			// Please review this change. What's the point of closing the socket on mobile refresh?
			this.registerSocket()
			if(this.currentConversation && this.currentConversation.group){
				this.conversationLastLoaded = 0
				this.$root.$emit('reloadChat', this.currentConversation)
			}
			this.$root.$emit('checkInputFocus')
			this.clearConversationsMethod()
			this.getConversations().then(() => {
			}, error => {
				this.showErrorModal(String(error))
			})

		},
		handleResize () {
			const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
			this.desktop = width > 768
			this.$root.$emit('windowResized')

			if (this.isIOS) {
				this.fixIOSHeight()
			}

			if(!this.miniChat && !this.fullChat && this.desktop){
				if (this.$route.name == null) {
					this.$router.push({ path: '/main/' }, () => {})
				}
			}
		},
		fixIOSHeight () {
			const vh = window.visualViewport.height * 0.01 * window.visualViewport.scale
			document.documentElement.style.setProperty('--vh', `${vh}px`)
			window.scrollTo(0, 0);
			setTimeout(() => window.scrollTo(0, 0), 300)
		},
		postLooper () {
			if (window.parent) {
				const dataObject = {
					type: 'readMessages',
					amount: 1,
				}
				window.parent.postMessage(dataObject, '*', [])
			}
		},
		checkDevice () {
			this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
			this.isIOS = navigator && navigator.appVersion && navigator.appVersion.indexOf('iPhone') !== -1
		},
		setLocationHash () {
			const hashes = window.location.hash.split( '#' )
			this.locationHash = hashes.length > 1 ? hashes[1].replace(/\//g, '') : ''
			if(this.locationHash.includes("?")){
				this.locationHash = this.locationHash.split('?')[0]
			}
			this.webActive = this.locationHash == 'web' || this.locationHash == 'webFullScreen'
		},
		checkPageFocus () {
			const windowHasFocus = (document && document.hasFocus) ? (document.hasFocus()) : true
			if (windowHasFocus !== this.windowHasFocus) {
				this.windowHasFocus = windowHasFocus
			}
		},
	},
}

</script>

/* eslint-disable  no-useless-escape */
export const readUrlParam = param => {
	let url = window.location.href
	param = param.replace(/[\[\]]/g, '\\$&')
	let regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)')
	let results = regex.exec(url)
	if (!results) return null
	if (!results[2]) return ''
	return decodeURIComponent(results[2].replace(/\//g, ' '))
}

export default {
	'lv': {
		'HH:mm': 'HH:mm',
		'MMMM Do': 'Do MMMM',
		'MMM Do': 'Do MMM',
		'YYYY, MMMM Do': 'Do MMMM, YYYY',
		'YYYY, MMM Do': 'Do MMM, YYYY',
		'MMMM Do, HH:mm': "Do MMMM, HH:mm",
		'YYYY, MMMM Do, HH:mm': 'YYYY, Do MMMM, HH:mm',
	},
	'en': {
		'HH:mm': 'HH:mm',
		'MMMM Do': 'MMMM Do',
		'MMM Do': 'MMM Do',
		'YYYY, MMMM Do': 'YYYY, MMMM Do',
		'YYYY, MMM Do': 'YYYY, MMM Do',
		'MMMM Do, HH:mm': 'MMMM Do, HH:mm',
		'YYYY, MMMM Do, HH:mm': 'YYYY, MMMM Do, HH:mm',
	},
	'ru': {
		'HH:mm': 'HH:mm',
		'MMMM Do': 'Do MMMM',
		'MMM Do': 'Do MMM',
		'YYYY, MMMM Do': 'Do MMMM, YYYY',
		'YYYY, MMM Do': 'Do MMM, YYYY',
		'MMMM Do, HH:mm': "Do MMMM, HH:mm",
		'YYYY, MMMM Do, HH:mm': 'YYYY, Do MMMM, HH:mm',
	},
	'et': {
		'HH:mm': 'HH:mm',
		'MMMM Do': 'Do MMMM',
		'MMM Do': 'Do MMM',
		'YYYY, MMMM Do': 'Do MMMM, YYYY',
		'YYYY, MMM Do': 'Do MMM, YYYY',
		'MMMM Do, HH:mm': "Do MMMM, HH:mm",
		'YYYY, MMMM Do, HH:mm': 'YYYY, Do MMMM, HH:mm',
	},
}

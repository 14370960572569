import * as api from '../../api';
import * as types from '../mutation-types';
import { errorMessage } from '@utils';

export const state = () => ({
	newConversationTitle: '',
	selectedSchool: {},
	selectedYear: 0,
	schools: [],
	contacts: {},
	expandedContactGroups: [],
	processedContacts: {},
	contactTags: new Map(),
	createConversationBusy: false,
	contactsBusy: false,
	allContactsEmpty: false,
})

export const mutations = {
	reset (storeState) {
		Object.assign(storeState, state())
	},
	[types.GET_SCHOOLS] (state, addUser) {
		if (addUser) this.state.conversationOptions.conversationActionBusy = true;
		state.contactsBusy = true;
	},
	[types.GET_SCHOOLS_SUCCESS] (state, response) {
		this.state.conversationOptions.conversationActionBusy = false;
		state.contactsBusy = false;
		state.schools = response.data.data;
	},
	[types.GET_SCHOOLS_FAIL] (state) {
		this.state.conversationOptions.conversationActionBusy = false;
		state.contactsBusy = false;
	},
	[types.GET_CONTACTS] (state) {
		state.contactsBusy = true;
	},
	[types.GET_CONTACTS_SUCCESS] (state, response) {

		const data = { ...response.data.data }
		// TODO: TEST SECTION
		// const data1 = { ...response.data.data }
		// const randomTags = () => {
		// 	const array = []
		// 	const num = Math.round(Math.random() * 100)
		// 	for (let i = 0; i < num; i++) {
		// 		array.push('loremIpsumRole')
		// 	}
		// 	return array
		//
		// }
		// const multiplyData = (key) => {
		// 	let newArray = []
		// 	for (let i = 0; i < 300; i++) {
		// 		newArray = [...newArray, ...data1[key].map(item => {
		// 			return {
		// 				...item,
		// 				id: Date.now() + Math.random(),
		// 				tags: randomTags()
		// 			}
		// 		})]
		// 	}
		// 	return newArray
		// }
		// const multiplyData1 = (key) => {
		// 	let newObj = {}
		// 	for (let i = 0; i < 300; i++) {
		// 		newObj[`${Date.now() + Math.random()}`] = [ ...data1[key].map(item => {
		// 			return {
		// 				...item,
		// 				id: Date.now() + Math.random(),
		// 			}
		// 		})]
		// 	}
		// 	return newObj
		// }
		//
		// const data = { ...data1, 'administration': multiplyData('administration'), parents: multiplyData1('administration') }
		// TODO: END OF TEST SECTION
		state.contactsBusy = false;
		state.allContactsEmpty = false;
		let foundAtleastOneTab = false;
		// remove users with id -1 (users who are already in convo when adding users)
		let removeExistingUsers = (users, removable) => {
			Object.keys(users).forEach(key => {
				if (Array.isArray(users[key])) {
					users[key] = users[key].filter(user => {
						let userViable = false
						userViable = user.id !== -1
						if (typeof user.parents !== 'undefined') {
							// If there are parents and all parents are already added, remove user from list
							user.parents = user.parents.filter(parent => parent.id !== -1)
							// Show students without parents in parents_and_students tab
							// if (user.parents.length === 0) {
							// 	userViable = false
							// 	user.id = null
							// }
						}
						return userViable
					})
					if (users[key].length !== 0 && key !== 'official') {
						foundAtleastOneTab = true
					}
					if (removable && users[key].length === 0) {
						delete users[key]
					}
				} else {
					removeExistingUsers(users[key], true)
				}
			})
		}
		removeExistingUsers(data)
		state.allContactsEmpty = !foundAtleastOneTab
		state.contacts = { ...data } // without spread operator, both states use same observers

		// just makes the same data, but flattens everything down to ids for later use
		state.processedContacts = { ...data }
		Object.keys(state.contacts).forEach(tabName => {
			if(tabName !== 'students_with_parents' && tabName !== 'students' && tabName !== 'parents' && tabName !== 'official') {
				let stateCopy = [...state.contacts[tabName]]
				state.processedContacts[tabName] = stateCopy.map(user => user.id)
			}
			if(tabName === 'students' || tabName === 'parents'){
				let stateCopy = state.contacts[tabName]
				let preState = []
				Object.keys(stateCopy).forEach(className => {
					preState = [...preState, ...stateCopy[className].map(user => user.id)]
				})
				state.processedContacts[tabName] = preState
			}
			if(tabName === 'students_with_parents'){
				let stateCopy = state.contacts[tabName]
				let preState = []
				Object.keys(stateCopy).forEach(className => {
					preState = [
						...preState,
						...stateCopy[className].map(user => user.id),
						...stateCopy[className].map(user => user.parents ? user.parents.map(parent => parent.id) : null).flat(),
					]
				})
				state.processedContacts[tabName] = preState
			}
		})
		state.contactTags = new Map();
		if(!this.state.general.isIe){
			const userTags = (userArray) =>
				userArray.forEach((user) => {
					if (user.tags) {
						user.tags.forEach((tag) => {
							if (!state.contactTags.has(String(tag))) {
								state.contactTags.set(String(tag), []);
							}
							state.contactTags.get(String(tag)).push(user);
						});
					}
					if (user.parents) {
						user.parents.forEach((parent) => {
							if (parent.tags) {
								parent.tags.forEach((tag) => {
									if (!state.contactTags.has(String(tag))) {
										state.contactTags.set(String(tag), []);
									}
									state.contactTags.get(String(tag)).push(parent);
								});
							}
						});
					}
				});
			Object.keys(state.contacts).forEach((contactTab) => {
				if (contactTab == 'official') {
					return;
				}
				if (!Array.isArray(state.contacts[contactTab])) {
					Object.keys(state.contacts[contactTab]).forEach((nestedContactTab) =>
						userTags(state.contacts[contactTab][nestedContactTab])
					);
				} else {
					userTags(state.contacts[contactTab]);
				}
			});
		}
	},
	[types.GET_CONTACTS_FAIL] (state) {
		state.contactsBusy = false;
	},
	[types.CREATE_CONVERSATION] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'CREATE_CONVERSATION');
		this._vm.trackGA4clickEvent('CREATE_CONVERSATION')
		state.createConversationBusy = true;
	},
	[types.CREATE_CONVERSATION_SUCCESS] (state, response) {
		state.createConversationBusy = false;
		var found = false;
		this.state.overview.conversations.forEach((element) => {
			if (element.group.group_id == response.data.data[0].group_id) {
				found = true;
			}
		});
		if (!found) {
			this.state.overview.conversations.unshift(response.data.data[0]);
		}
	},
	[types.CREATE_CONVERSATION_FAIL] (state) {
		state.createConversationBusy = false;
	},

	[types.ADD_CONVERSATION_MEMBERS] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'ADD_CONVERSATION_MEMBERS');
		this._vm.trackGA4clickEvent('ADD_CONVERSATION_MEMBERS')
		state.contactsBusy = true;
	},
	[types.ADD_CONVERSATION_MEMBERS_SUCCESS] (state, payload) {
		state.contactsBusy = false;
		let users = payload.socket ? payload.data : payload.response.data.data
		// remove left_date parameter to enable chat actions for invited user
		var that = this;
		const currentUser = this.state.user.user
		if (users.find( u => u.user_id === currentUser.id )) {
			this.state.overview.conversations.forEach(function (item, index) {
				if (item.group.group_id === payload.groupId) {
					that.state.overview.conversations[index].left_date = null
				}
			});
		}
		//
		this.commit('members/editCurrentMemberList', { groupId: payload.groupId, users, action: 'add' });
		this.commit('members/editMemberList', { groupId: payload.groupId, users, action: 'add' });

	},
	[types.ADD_CONVERSATION_MEMBERS_FAIL] (state) {
		state.contactsBusy = false;
	},
	[types.SET_CONTACT_GROUP_EXPANDED] (state, { groupIndex, value }) {
		const index = state.expandedContactGroups.indexOf(groupIndex)
		if (index === -1 && value) {
			state.expandedContactGroups.push(groupIndex)
		} else if (index !== -1 && !value) {
			state.expandedContactGroups.splice(index, 1)
		}
	},
	[types.RESET_CONTACT_GROUP_EXPANDED] (state) {
		state.expandedContactGroups = []
	},
	updateNewConversationTitle (state, value) {
		state.newConversationTitle = value;
	},
	updateSelectedSchool (state, value) {
		state.selectedSchool = value;
	},
	updateSelectedYear (state, value) {
		state.selectedYear = value;
	},
};

export const actions = {
	getSchools: (store, addUsers) => {
		store.commit(types.GET_SCHOOLS, addUsers);
		return api.getSchools().then(
			(response) => {
				store.commit(types.GET_SCHOOLS_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.GET_SCHOOLS_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	getContacts: (store, { schoolId, yearId, groupId }) => {
		store.commit(types.GET_CONTACTS);
		return api.getContacts(schoolId, yearId, groupId).then(
			(response) => {
				store.commit(types.GET_CONTACTS_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.GET_CONTACTS_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	createConversation: (store, conversationObject) => {
		store.commit(types.CREATE_CONVERSATION);
		// return new Promise(resolve => setTimeout(resolve, 3000));
		return api.createConversation(conversationObject).then(
			(response) => {
				store.commit(types.CREATE_CONVERSATION_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.CREATE_CONVERSATION_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	addConversationMembers: (store, { groupId, users }) => {
		store.commit(types.ADD_CONVERSATION_MEMBERS);
		return api.addConversationMembers(groupId, users).then(
			(response) => {
				store.commit(types.ADD_CONVERSATION_MEMBERS_SUCCESS, { groupId, response });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.ADD_CONVERSATION_MEMBERS_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	setContactGroupExpanded: ({ commit }, payload) => {
		commit(types.SET_CONTACT_GROUP_EXPANDED, payload)
	},
	resetContactGroupExpanded: ({ commit }) => {
		commit(types.RESET_CONTACT_GROUP_EXPANDED)
	},
};

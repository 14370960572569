<template>
	<e-dialog
		:value="true"
		persistent
		description=""
		width="400"
		:title="$t('failed_chunk_message')"
		:button-submit="{label: 'Refresh'}"
		@click:submit="onSubmit" />
</template>

<script>
export default {
	name: 'ErrorPage',
	methods: {
		onSubmit () {
			window.location.reload();
		}
	}
}
</script>

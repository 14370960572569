export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL'

export const GET_SCHOOLS = 'GET_SCHOOLS'
export const GET_SCHOOLS_SUCCESS = 'GET_SCHOOLS_SUCCESS'
export const GET_SCHOOLS_FAIL = 'GET_SCHOOLS_FAIL'

export const GET_CONTACTS = 'GET_CONTACTS'
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS'
export const GET_CONTACTS_FAIL = 'GET_CONTACTS_FAIL'

export const CREATE_CONVERSATION = 'CREATE_CONVERSATION'
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_FAIL = 'CREATE_CONVERSATION_FAIL'

export const CHANGE_CONVERSATION_PICTURE = 'CHANGE_CONVERSATION_PICTURE'
export const CHANGE_CONVERSATION_PICTURE_SUCCESS = 'CHANGE_CONVERSATION_PICTURE_SUCCESS'
export const CHANGE_CONVERSATION_PICTURE_FAIL = 'CHANGE_CONVERSATION_PICTURE_FAIL'

export const CHANGE_CONVERSATION_TITLE = 'CHANGE_CONVERSATION_TITLE'
export const CHANGE_CONVERSATION_TITLE_FAIL = 'CHANGE_CONVERSATION_TITLE_FAIL'

export const CHANGE_CONVERSATION_DETAILS = 'CHANGE_CONVERSATION_DETAILS'

export const DELETE_CONVERSATION_PICTURE = 'DELETE_CONVERSATION_PICTURE'
export const DELETE_CONVERSATION_PICTURE_SUCCESS = 'DELETE_CONVERSATION_PICTURE_SUCCESS'
export const DELETE_CONVERSATION_PICTURE_FAIL = 'DELETE_CONVERSATION_PICTURE_FAIL'


export const ADD_CONVERSATION_MEMBERS = 'ADD_CONVERSATION_MEMBERS'
export const ADD_CONVERSATION_MEMBERS_SUCCESS = 'ADD_CONVERSATION_MEMBERS_SUCCESS'
export const ADD_CONVERSATION_MEMBERS_FAIL = 'ADD_CONVERSATION_MEMBERS_FAIL'

export const GET_CONTACT_CONVERSATIONS = 'GET_CONTACT_CONVERSATIONS'
export const GET_CONTACT_CONVERSATIONS_SUCCESS = 'GET_CONTACT_CONVERSATIONS_SUCCESS'
export const GET_CONTACT_CONVERSATIONS_FAIL = 'GET_CONTACT_CONVERSATIONS_FAIL'

export const GET_CONVERSATIONS = 'GET_CONVERSATIONS'
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS'
export const GET_CONVERSATIONS_FAIL = 'GET_CONVERSATIONS_FAIL'

export const GET_MEMBER_STATUS = 'GET_MEMBER_STATUS'
export const GET_MEMBER_STATUS_SUCCESS = 'GET_MEMBER_STATUS_SUCCESS'
export const GET_MEMBER_STATUS_FAIL = 'GET_MEMBER_STATUS_FAIL'

export const GET_CONVERSATION_DETAILS = 'GET_CONVERSATION_DETAILS'
export const GET_CONVERSATION_DETAILS_SUCCESS = 'GET_CONVERSATION_DETAILS_SUCCESS'
export const GET_CONVERSATION_DETAILS_FAIL = 'GET_CONVERSATION_DETAILS_FAIL'

export const GET_CONVERSATION_MEMBERS = 'GET_CONVERSATION_MEMBERS'
export const GET_CONVERSATION_MEMBERS_SUCCESS = 'GET_CONVERSATION_MEMBERS_SUCCESS'
export const GET_CONVERSATION_MEMBERS_FAIL = 'GET_CONVERSATION_MEMBERS_FAIL'

export const GET_CONVERSATION_MEMBERS_SEARCH = 'GET_CONVERSATION_MEMBERS_SEARCH'
export const GET_CONVERSATION_MEMBERS_SEARCH_SUCCESS = 'GET_CONVERSATION_MEMBERS_SEARCH_SUCCESS'
export const GET_CONVERSATION_MEMBERS_SEARCH_FAIL = 'GET_CONVERSATION_MEMBERS_SEARCH_FAIL'

export const GET_CONVERSATION_ATTACHMENTS = 'GET_CONVERSATION_ATTACHMENTS'
export const GET_CONVERSATION_ATTACHMENTS_SUCCESS = 'GET_CONVERSATION_ATTACHMENTS_SUCCESS'
export const GET_CONVERSATION_ATTACHMENTS_FAIL = 'GET_CONVERSATION_ATTACHMENTS_FAIL'

export const GET_CONVERSATION_MESSAGES = 'GET_CONVERSATION_MESSAGES'
export const GET_CONVERSATION_MESSAGES_SUCCESS = 'GET_CONVERSATION_MESSAGES_SUCCESS'
export const GET_CONVERSATION_MESSAGES_FAIL = 'GET_CONVERSATION_MESSAGES_FAIL'

export const GET_CONVERSATION_MESSAGES_SEARCH = 'GET_CONVERSATION_MESSAGES_SEARCH'
export const GET_CONVERSATION_MESSAGES_SEARCH_SUCCESS = 'GET_CONVERSATION_MESSAGES_SEARCH_SUCCESS'
export const GET_CONVERSATION_MESSAGES_SEARCH_FAIL = 'GET_CONVERSATION_MESSAGES_SEARCH_FAIL'

export const GET_MESSAGE_LAST_SEEN = 'GET_MESSAGE_LAST_SEEN'
export const GET_MESSAGE_LAST_SEEN_SUCCESS = 'GET_MESSAGE_LAST_SEEN_SUCCESS'
export const GET_MESSAGE_LAST_SEEN_FAIL = 'GET_MESSAGE_LAST_SEEN_FAIL'

export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'
export const CREATE_MESSAGE_FAIL = 'CREATE_MESSAGE_FAIL'

export const FORWARD_MESSAGES = 'FORWARD_MESSAGES'
export const FORWARD_MESSAGES_SUCCESS = 'FORWARD_MESSAGES_SUCCESS'
export const FORWARD_MESSAGES_FAIL = 'FORWARD_MESSAGES_FAIL'

export const UPDATE_MESSAGE_READ = 'UPDATE_MESSAGE_READ'
export const UPDATE_MESSAGE_SEEN = 'UPDATE_MESSAGE_SEEN'
export const UPDATE_MESSAGE_SEEN_SUCCESS = 'UPDATE_MESSAGE_SEEN_SUCCESS'
export const UPDATE_MESSAGE_SEEN_FAIL = 'UPDATE_MESSAGE_SEEN_FAIL'

export const READ_ALL_MESSAGES = 'READ_ALL_MESSAGES'
export const READ_ALL_MESSAGES_SUCCESS = 'READ_ALL_MESSAGES_SUCCESS'
export const READ_ALL_MESSAGES_FAIL = 'READ_ALL_MESSAGES_FAIL'

export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const HIDE_MESSAGE_SUCCESS = 'HIDE_MESSAGE_SUCCESS'
export const HIDE_MESSAGE_FAIL = 'HIDE_MESSAGE_FAIL'

export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS'
export const DELETE_MESSAGE_FAIL = 'DELETE_MESSAGE_FAIL'

export const EDIT_MESSAGE = 'EDIT_MESSAGE'
export const EDIT_MESSAGE_SUCCESS = 'EDIT_MESSAGE_SUCCESS'
export const EDIT_MESSAGE_FAIL = 'EDIT_MESSAGE_FAIL'

export const REMOVE_MEMBER = 'REMOVE_MEMBER'
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS'
export const REMOVE_MEMBER_FAIL = 'REMOVE_MEMBER_FAIL'

export const CHANGE_USER_PERMISSIONS = 'CHANGE_USER_PERMISSIONS'
export const CHANGE_USER_PERMISSIONS_SUCCESS = 'CHANGE_USER_PERMISSIONS_SUCCESS'
export const CHANGE_USER_PERMISSIONS_FAIL = 'CHANGE_USER_PERMISSIONS_FAIL'

export const CLEAR_CONVERSATION = 'CLEAR_CONVERSATION'
export const CLEAR_CONVERSATION_SUCCESS = 'CLEAR_CONVERSATION_SUCCESS'
export const CLEAR_CONVERSATION_FAIL = 'CLEAR_CONVERSATION_FAIL'

export const LEAVE_CONVERSATION = 'LEAVE_CONVERSATION'
export const LEAVE_CONVERSATION_SUCCESS = 'LEAVE_CONVERSATION_SUCCESS'
export const LEAVE_CONVERSATION_FAIL = 'LEAVE_CONVERSATION_FAIL'

export const DELETE_CONVERSATION = 'DELETE_CONVERSATION'
export const DELETE_CONVERSATION_SUCCESS = 'DELETE_CONVERSATION_SUCCESS'
export const DELETE_CONVERSATION_FAIL = 'DELETE_CONVERSATION_FAIL'

export const DELETE_CONVERSATION_PERM = 'DELETE_CONVERSATION_PERM'
export const DELETE_CONVERSATION_PERM_SUCCESS = 'DELETE_CONVERSATION_PERM_SUCCESS'
export const DELETE_CONVERSATION_PERM_FAIL = 'DELETE_CONVERSATION_PERM_FAIL'

export const FILE_UPLOAD = 'FILE_UPLOAD'
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS'
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL'
export const FILE_UPLOAD_UPDATE = 'FILE_UPLOAD_UPDATE'

export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS'
export const CLEAR_CONTACT_CONVERSATIONS = 'CLEAR_CONTACT_CONVERSATIONS'
export const CLEAR_CONVERSATION_MESSAGES = 'CLEAR_CONVERSATION_MESSAGES'
export const CLEAR_CONVERSATION_MEMBERS = 'CLEAR_CONVERSATION_MEMBERS'
export const CLEAR_CONVERSATION_ATTACHMENTS = 'CLEAR_CONVERSATION_ATTACHMENTS'
export const CLEAR_FILES = 'CLEAR_FILES'
export const CLEAR_FILE = 'CLEAR_FILE'
export const CLEAR_MESSAGE_LAST_SEEN = 'CLEAR_MESSAGE_LAST_SEEN'
export const CLEAR_CONVERSATION_MESSAGE_SEARCH = 'CLEAR_CONVERSATION_MESSAGE_SEARCH'

export const SET_CONVERSATION_MESSAGE_SEARCH_STRING = 'SET_CONVERSATION_MESSAGE_SEARCH_STRING'
export const SET_CONVERSATION_SEARCH_STRING = 'SET_CONVERSATION_SEARCH_STRING'
export const SET_CONVERSATION_FILTER = 'SET_CONVERSATION_FILTER'
export const SET_UPLOADED_FILES = 'SET_UPLOADED_FILES'

export const SET_SOCKET_FUNCTIONS = 'SET_SOCKET_FUNCTIONS'
export const SET_CONVERSATION = 'SET_CONVERSATION'

export const CREATE_MESSAGE_DRAFT = 'CREATE_MESSAGE_DRAFT'
export const CREATE_MESSAGE_DRAFT_SUCCESS = 'CREATE_MESSAGE_DRAFT_SUCCESS'
export const CREATE_MESSAGE_DRAFT_FAIL = 'CREATE_MESSAGE_DRAFT_FAIL'

export const GET_MESSAGE_DRAFT = 'GET_MESSAGE_DRAFT'
export const GET_MESSAGE_DRAFT_SUCCESS = 'GET_MESSAGE_DRAFT_SUCCESS'
export const GET_MESSAGE_DRAFT_FAIL = 'GET_MESSAGE_DRAFT_FAIL'

export const DELETE_MESSAGE_DRAFT = 'DELETE_MESSAGE_DRAFT'
export const DELETE_MESSAGE_DRAFT_SUCCESS = 'DELETE_MESSAGE_DRAFT_SUCCESS'
export const DELETE_MESSAGE_DRAFT_FAIL = 'DELETE_MESSAGE_DRAFT_FAIL'

export const MUTE_CONVERSATION = 'MUTE_CONVERSATION'
export const MUTE_CONVERSATION_SUCCESS = 'MUTE_CONVERSATION_SUCCESS'
export const MUTE_CONVERSATION_FAIL = 'MUTE_CONVERSATION_FAIL'

export const UNMUTE_CONVERSATION = 'UNMUTE_CONVERSATION'
export const UNMUTE_CONVERSATION_SUCCESS = 'UNMUTE_CONVERSATION_SUCCESS'
export const UNMUTE_CONVERSATION_FAIL = 'UNMUTE_CONVERSATION_FAIL'

export const GET_CAMPAIGN = 'GET_CAMPAIGN'
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS'
export const GET_CAMPAIGN_FAIL = 'GET_CAMPAIGN_FAIL'

export const DELETE_GET_CAMPAIGN = 'DELETE_GET_CAMPAIGN'
export const DELETE_GET_CAMPAIGN_SUCCESS = 'DELETE_GET_CAMPAIGN_SUCCESS'
export const DELETE_GET_CAMPAIGN_FAIL = 'DELETE_GET_CAMPAIGN_FAIL'


export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
export const SET_USER_SETTINGS_SUCCESS = 'SET_USER_SETTINGS_SUCCESS'
export const SET_USER_SETTINGS_FAIL = 'SET_USER_SETTINGS_FAIL'

export const GET_USER_SETTINGS = 'GET_USER_SETTINGS'
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS'
export const GET_USER_SETTINGS_FAIL = 'GET_USER_SETTINGS_FAIL'

export const TOGGLE_REPLIES = 'TOGGLE_REPLIES'
export const TOGGLE_REPLIES_SUCCESS = 'TOGGLE_REPLIES_SUCCESS'
export const TOGGLE_REPLIES_FAIL = 'TOGGLE_REPLIES_FAIL'

export const ARCHIVE_CONVERSATION = 'ARCHIVE_CONVERSATION'
export const ARCHIVE_CONVERSATION_SUCCESS = 'ARCHIVE_CONVERSATION_SUCCESS'
export const ARCHIVE_CONVERSATION_FAIL = 'ARCHIVE_CONVERSATION_FAIL'

export const IMPORTANT_MESSAGE_ALLOWED = 'IMPORTANT_MESSAGE_ALLOWED'
export const IMPORTANT_MESSAGE_ALLOWED_SUCCESS = 'IMPORTANT_MESSAGE_ALLOWED_SUCCESS'
export const IMPORTANT_MESSAGE_ALLOWED_FAIL = 'IMPORTANT_MESSAGE_ALLOWED_FAIL'

export const SET_CONTACT_GROUP_EXPANDED = 'SET_CONTACT_GROUP_EXPANDED'
export const RESET_CONTACT_GROUP_EXPANDED = 'RESET_CONTACT_GROUP_EXPANDED'

export const GET_SCHOOL_FEATURE = 'GET_SCHOOL_FEATURE'
export const GET_SCHOOL_FEATURE_SUCCESS = 'GET_SCHOOL_FEATURE_SUCCESS'

export const GET_SCHOOL_SIZE_LIMIT_SUCCESS = 'GET_SCHOOL_SIZE_LIMIT_SUCCESS'

export const GET_SCHOOL_LOGOS = 'GET_SCHOOL_LOGOS'
export const GET_SCHOOL_LOGOS_SUCCESS = 'GET_SCHOOL_LOGOS_SUCCESS'
export const GET_SCHOOL_LOGOS_FAIL = 'GET_SCHOOL_LOGOS_FAIL'
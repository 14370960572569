import { getEnv } from '@env'
/* eslint-disable no-undef */
// gtag('config', getEnv('VUE_APP_FRONTEND_GTM_TAG_ID'))
import Vue from 'vue'

export const analytics = {

	sendEvent: function (event, action, eventLabel, customValues){

		const dataObject = {
			'event_category': event,
			'event_label': eventLabel,
		}

		Object.assign(dataObject, customValues)
		Vue.prototype.$gtag.event(action, dataObject)

		// gtag('event', action, dataObject)

	},

	sendView: function (viewName){
		// gtag('config', getEnv('VUE_APP_FRONTEND_GTM_TAG_ID'), { 'page_path': '/' + viewName });
	},


	setData: function (data){
		Object.keys(data).forEach(function (key) {
			// gtag('set', key, data[key])
		})
	},

}

<template>
	<transition name="loader-fade">
		<e-circular-loading
			:width="loaderWidth"
			:class="loaderClass"
			:size="loaderSize"
			:value="localShowLoader" />
	</transition>
</template>

<script>
export default {
	name: "AppLoader",
	props: {
		showLoader: {
			type: Boolean,
			required: true,
			default: false,
		},
		loaderWidth: {
			type: Number,
			default: undefined,
		},
		loaderSize: {
			type: Number,
			default: undefined,
		},
		loaderDelay: {
			type: Number,
			required: false,
			default: 700,
		},
		loaderClass: {
			type: String,
			required: false,
			default: '',
		},
	},
	data: function () {
		return {
			localShowLoader: false,
		}
	},
	watch: {
		showLoader: function () {
			// Timeout toggle with short timout to see if value actually changed or not, so loader doesnt flicker if multiple busy params in show loader
			setTimeout(() => {
				this.toggleLoaders()
			}, 10);

		},
	},
	mounted () {
		setTimeout(() => {
			this.toggleLoaders()
		}, 10);
	},
	methods: {
		toggleLoaders () {
			if(this.showLoader){
				setTimeout(() => {
					this.localShowLoader = this.showLoader
				}, this.loaderDelay);
			}else{
				this.localShowLoader = this.showLoader
			}
		},
	},
}
</script>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import TimeLocales from './time-locales.js'
import { getEnv } from '@env'
import moment from 'moment'

const defaultLocale = getEnv( 'VUE_APP_FRONTEND_DEFAULT_LOCALE' )
const userLocale = defaultLocale

Vue.use(VueI18n)

const Locales = require.context(
	"./locales",
	true,
	/[A-Za-z0-9-_,\s]+\.json$/i
);
// If there is a new language added to app, add the locale from moment manually, since webpack is set to ignore all languages(decreases momentjs size)
require('moment/locale/de')
require('moment/locale/en-gb')
require('moment/locale/et')
require('moment/locale/lv')
require('moment/locale/ru')
require('moment/locale/ja')
require('moment/locale/fr')

// Moment set locale
moment.locale(userLocale)

// Vue config
// Vue.config.lang = userLocale

const messages = {}

Locales.keys().forEach(function (lang) {
	// new babel translator fix
	let langEntries = Locales(lang)
	// converts file name into locale key (lv_LV and so on)
	// could not figure out how to do from-to search that works on safari,if you can remove slice
	let langName = lang.match(/(.*)(?=\.json)/g)[0].slice(2)
	messages[langName] = {
		...langEntries,
		...(TimeLocales[langName] ? TimeLocales[langName] : TimeLocales['en']),
	}
})

const i18n = new VueI18n({
	locale: userLocale,
	fallbackLocale: defaultLocale,
	silentFallbackWarn: !getEnv('VUE_APP_FRONTEND_DEBUG'),
	// eslint-disable-next-line no-unused-vars
	missing: (locale, key, vm) => {
		// console.debug('Missing locale:', locale, key)
	},
	messages,
})

export default i18n

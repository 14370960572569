/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import '@utilsDir/beforeAppLoad'
import 'babel-polyfill'

import Vue from 'vue'
import VueDragscroll from 'vue-dragscroll'
// import VModal from 'vue-js-modal'
import VueUploadComponent from 'vue-upload-component'

import AttachmentHandler from '@childComponents/AttachmentHandler'
import ImageHandler from '@childComponents/ImageHandler'
import Loader from '@childComponents/Loader'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import { analytics } from './analytics'
import App from './components/App.vue'
import i18n from './i18n'
import router from './router'
import store from './store/store'
import { fileTypes } from './utils/allowedFileTypes'
import { chunkLoadFail } from './utils/utils'
import {
	kawaiErrorModal,
	modalCloseHandler,
	pictureError,
	showErrorModal,
	trackGA4clickEvent,
} from './utils/vueInstanceUtils'
import { getEnv } from '@env'
import vuetify from './plugins/vuetify'
// import VueEkoolPlugin from '@k12tech/ekool-vue-framework'

import {
	DialogPlugin,
	ToastPlugin,

	EDialog,
	EAvatar,
	EModal,
	EButton,
	ECheckbox,
	EIcon,
	EMenu,
	ETooltip,
	ECircularLoading,
	ESelect,
	EChip,
	EInputTag,
	ETextField,
	ETabs,
	ETab,
	EDivider,
	ERadioGroup,
	ERadio,
	EMenuList,
	EMenuListItem,
	EHint,
	EDatePicker,
	ESwitch,
} from '@k12tech/ekool-vue-framework'
//import VueGtag from 'vue-gtag'
import VueGtm from '@gtm-support/vue2-gtm'

let native = window.location.href.indexOf('native') > -1

const debugEnabled = (window.location.href.indexOf('debug') > -1) || process.env.VUE_APP_DEBUG_ENABLED
if (!debugEnabled) {
	Sentry.init({
		environment: native ? 'native' : 'desktop',
		dsn: getEnv('VUE_APP_FRONTEND_SENTRY_VUE_DSN'),
		integrations: [ new BrowserTracing({}) ],
	})
}

const context = {
	vuetify,
	router,
	store,
	i18n
}

Vue.use(VueVirtualScroller)

Vue.use(VueDragscroll)
// Vue.use(VueEkoolPlugin, { context })
Vue.use(DialogPlugin, { context })
Vue.use(ToastPlugin, { context })
Vue.component('EButton', EButton)
Vue.component('EDialog', EDialog)
Vue.component('EModal', EModal)
Vue.component('EAvatar', EAvatar)
Vue.component('ECheckbox', ECheckbox)
Vue.component('EIcon', EIcon)
Vue.component('EMenu', EMenu)
Vue.component('ETooltip', ETooltip)
Vue.component('ECircularLoading', ECircularLoading)
Vue.component('ESelect', ESelect)
Vue.component('EChip', EChip)
Vue.component('EInputTag', EInputTag)
Vue.component('ETextField', ETextField)
Vue.component('ETabs', ETabs)
Vue.component('ETab', ETab)
Vue.component('EDivider', EDivider)
Vue.component('ERadioGroup', ERadioGroup)
Vue.component('ERadio', ERadio)
Vue.component('EMenuList', EMenuList)
Vue.component('EMenuListItem', EMenuListItem)
Vue.component('EHint', EHint)
Vue.component('EDatePicker', EDatePicker)
Vue.component('ESwitch', ESwitch)
/*
Vue.use(VueGtag, {
	config: { id: (getEnv('VUE_APP_FRONTEND_GTM_TAG_ID') || '').toString() },
})
*/
Vue.use(VueGtm, {
	id: getEnv('VUE_APP_FRONTEND_GTM_TAG_ID') || "GTM-XXXXXX", // "GTM-XXXXXX" used for staging, where config doesnt have GTM_TAG_ID, otherwise plugin throws an error and app breaks
	defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
	compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
	nonce: '2726c7f26c', // Will add `nonce` to the script tag
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: false, // Whether or not display console logs debugs (optional)
	loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
	trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
})

Vue.component('FileUpload', VueUploadComponent)
// Vue.component('VSelect', vSelect)

Vue.config.debug = getEnv('VUE_APP_FRONTEND_DEBUG')
Vue.config.silent = !getEnv('VUE_APP_FRONTEND_DEBUG')
Vue.config.productionTip = !getEnv('VUE_APP_FRONTEND_DEBUG')

Vue.prototype.$fileTypes = fileTypes
Vue.prototype.$ga = analytics
Vue.prototype.trackGA4clickEvent = trackGA4clickEvent


window.chunkLoadFail = chunkLoadFail
require('vue2-animate/dist/vue2-animate.min.css')
require('@scss/app.scss')
// Vue.directive('click-outside', {
// 	bind: function (el, binding, vnode) {
// 		el.clickOutsideEvent = function (event) {
// 			// here I check that click was outside the el and his childrens
// 			if (!(el == event.target || el.contains(event.target))) {
// 				// and if it did, call method provided in attribute value
// 				vnode.context[binding.expression](event)
// 			}
// 		}
// 		document.body.addEventListener('click', el.clickOutsideEvent)
// 	},
// 	unbind: function (el) {
// 		document.body.removeEventListener('click', el.clickOutsideEvent)
// 	},
// })

Vue.mixin({
	methods: {
		showErrorModal: showErrorModal,
		kawaiErrorModal: kawaiErrorModal,
		pictureError: pictureError,
		modalCloseHandler: modalCloseHandler,
		trackGA4clickEvent: trackGA4clickEvent,
	},
})
Vue.component('ImageHandler', ImageHandler)
Vue.component('AttachmentHandler', AttachmentHandler)
Vue.component('AppLoader', Loader)
// Mount app
if (document.getElementById('app')) {
	/* eslint-disable vue/no-use-v-if-with-v-for */
	const vm = new Vue( {
		// name: 'vm',
		el: '#app',
		...context,
		render: ( h ) => h( App ),
	} ).$mount( '#app' )
}

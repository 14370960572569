export const fileTypes = `
application/msword,
application/vnd.openxmlformats-officedocument.wordprocessingml.document,
application/vnd.ms-excel,
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
application/vnd.ms-powerpoint,
application/vnd.openxmlformats-officedocument.presentationml.presentation,
application/vnd.openxmlformats-officedocument.presentationml.slideshow,
application/x-msaccess,
application/msaccess,
application/vnd.ms-access,
application/mdb,
application/x-mdb,
text/rtf,
application/rtf,
application/vnd.oasis.opendocument.text,
application/vnd.oasis.opendocument.presentation,
application/vnd.oasis.opendocument.spreadsheet,
application/vnd.wordperfect,
text/plain,
application/pdf,
application/x-pdf,
application/x-bzpdf,
application/x-gzpdf,
application/vnd.apple.keynote,
application/vnd.apple.pages,
application/vnd.apple.numbers,
application/vnd.wordperfect,
image/jpeg,
image/png,
image/apng,
image/bmp,
image/x-bmp,
image/x-ms-bmp,
image/gif,
image/webp,
image/tiff,
image/tiff-fx,
image/jp2,
image/jpx,
image/jpm,
image/pjpeg,
video/mj2,
image/xcf,
image/heif,
image/heic,
image/heif-sequence,
image/heic-sequence,
application/postscript,
image/vnd.adobe.photoshop,
image/x-targa,
image/x-tga,
application/eps,
application/x-eps,
image/eps,
image/x-eps,
image/svg+xml,
image/x-k25,
image/raw,
image/arw,
image/x-raw,
image/x-nrw,
image/vnd.microsoft.icon,
image/x-icon,
image/avif,
video/webm,
video/mpeg,
video/ogg,
application/ogg,
video/mp4,
video/vnd.avi,
video/avi,
video/msvideo,
video/x-msvideo,
video/quicktime,
video/x-flv,
video/x-ms-wmv,
video/x-ms-asf,
application/vnd.adobe.flash-movie,
application/x-shockwave-flash,
video/h264,
video/x-m4v,
video/x-matroska,
application/vnd.rn-realmedia,
audio/aac,
audio/aiff,
audio/x-aiff,
audio/vnd.wave,
audio/wav,
audio/wave,
audio/x-wav,
audio/flac,
audio/ogg,
audio/mpeg,
audio/MPA,
audio/x-pcm,
audio/x-ms-wma,
audio/webm,
audio/midi,
application/vnd.ms-wpl,
application/zip,
application/vnd.rar,
application/x-rar-compressed,
application/x-7z-compressed,
application/x-tar,
application/x-bzip2,
application/x-gzip,
application/epub+zip,
application/vnd.etsi.asic-e+zip,
text/csv,
application/x-indesign,
application/xml,
text/xml,
text/html,
text/css,
application/xhtml+xml,
application/vnd.amazon.ebook,
application/x-mobipocket-ebook,
application/vnd.etsi.asic-s+zip,
application/vnd.etsi.asic-e+zip,
application/vnd.etsi.asic-e,
application/vnd.etsi.asic-s
application/vnd.etsi.asic`
export const fileTypesArray = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
	'application/x-msaccess',
	'application/msaccess',
	'application/vnd.ms-access',
	'application/mdb',
	'application/x-mdb',
	'text/rtf',
	'application/rtf',
	'application/vnd.oasis.opendocument.text',
	'application/vnd.oasis.opendocument.presentation',
	'application/vnd.oasis.opendocument.spreadsheet',
	'application/vnd.wordperfect',
	'text/plain',
	'application/pdf',
	'application/x-pdf',
	'application/x-bzpdf',
	'application/x-gzpdf',
	'application/vnd.apple.keynote',
	'application/vnd.apple.pages',
	'application/vnd.apple.numbers',
	'application/vnd.wordperfect',
	'image/jpeg',
	'image/png',
	'image/apng',
	'image/bmp',
	'image/x-bmp',
	'image/x-ms-bmp',
	'image/gif',
	'image/webp',
	'image/tiff',
	'image/tiff-fx',
	'image/jp2',
	'image/jpx',
	'image/jpm',
	'image/pjpeg',
	'video/mj2',
	'image/xcf',
	'image/heif',
	'image/heic',
	'image/heif-sequence',
	'image/heic-sequence',
	'application/postscript',
	'image/vnd.adobe.photoshop',
	'image/x-targa',
	'image/x-tga',
	'application/eps',
	'application/x-eps',
	'image/eps',
	'image/x-eps',
	'image/svg+xml',
	'image/x-k25',
	'image/raw',
	'image/arw',
	'image/x-raw',
	'image/x-nrw',
	'image/vnd.microsoft.icon',
	'image/x-icon',
	'image/avif',
	'video/webm',
	'video/mpeg',
	'video/ogg',
	'application/ogg',
	'video/mp4',
	'video/vnd.avi',
	'video/avi',
	'video/msvideo',
	'video/x-msvideo',
	'video/quicktime',
	'video/x-flv',
	'video/x-ms-wmv',
	'video/x-ms-asf',
	'application/vnd.adobe.flash-movie',
	'application/x-shockwave-flash',
	'video/h264',
	'video/x-m4v',
	'video/x-matroska',
	'application/vnd.rn-realmedia',
	'audio/aac',
	'audio/aiff',
	'audio/x-aiff',
	'audio/vnd.wave',
	'audio/wav',
	'audio/wave',
	'audio/x-wav',
	'audio/flac',
	'audio/ogg',
	'audio/mpeg',
	'audio/MPA',
	'audio/x-pcm',
	'audio/x-ms-wma',
	'audio/webm',
	'audio/midi',
	'application/vnd.ms-wpl',
	'application/zip',
	'application/vnd.rar',
	'application/x-rar-compressed',
	'application/x-7z-compressed',
	'application/x-tar',
	'application/x-bzip2',
	'application/x-gzip',
	'application/epub+zip',
	'application/vnd.etsi.asic-e+zip',
	'text/csv',
	'application/x-indesign',
	'application/xml',
	'text/xml',
	'text/html',
	'text/css',
	'application/xhtml+xml',
	'application/vnd.amazon.ebook',
	'application/x-mobipocket-ebook',
	'application/vnd.etsi.asic-s+zip',
	'application/vnd.etsi.asic-e+zip',
]

export const fileExtensions = {
	documents: [
		'doc',
		'docx',
		'docm',
		'accdb',
		'mdb',
		'accdb',
		'mdb',
		'accdb',
		'mdb',
		'mdb',
		'mdb',
		'rtf',
		'rtf',
		'fodt',
		'fodp',
		'wdp',
		'txt',
		'html',
		'pdf',
		'pdf',
		'pdf',
		'pdf',
		'key',
		'pages',
		'numbers',
		'asice',
		'ggb',
	],
	presentation: [ 'odp', 'odt', 'pps', 'ppt', 'pptx', 'pptm', 'ppsx' ],
	sheets: [ 'csv', 'ods', 'fods', 'xml', 'xls', 'xlsx', 'xlsm', 'wpd' ],
	displayImage: [ 'ico', 'cur', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjpg', 'pjp', 'png', 'svg', 'gif', 'bmp', 'apng' ],
	image: [
		'avif',
		'jpg',
		'jfif-tbnl',
		'jpeg',
		'jpe',
		'jif',
		'jfif',
		'jfi',
		'png',
		'bmp',
		'bmp',
		'bmp',
		'gif',
		'webp',
		'apng',
		'tif',
		'tiff',
		'jp2',
		'jpf',
		'jpx',
		'jpm',
		'jp2',
		'xcf',
		'heif',
		'heic',
		'heif',
		'heic',
		'heif',
		'heic',
		'heif',
		'heic',
		'ai',
		'eps',
		'epse',
		'epsi',
		'psd',
		'tga',
		'tga',
		'eps',
		'epse',
		'epsi',
		'eps',
		'epse',
		'epsi',
		'eps',
		'epse',
		'epsi',
		'eps',
		'epse',
		'epsi',
		'svg',
		'svgz',
		'k25',
		'raw',
		'arw',
		'crw',
		'nrw',
	],
	video: [
		'webm',
		'mpa',
		'mpg',
		'mpeg',
		'mp2',
		'mpe',
		'ogg',
		'ogg',
		'mp4',
		'm4a',
		'm4p',
		'm4b',
		'm4r',
		'm4v',
		'mkv',
		'avi',
		'avi',
		'avi',
		'avi',
		'mov',
		'qt',
		'flv',
		'f4v',
		'f4p',
		'f4a',
		'f4b',
		'wmv',
		'wma',
		'wpl',
		'wmv',
		'swf',
		'swf',
		'ogx',
		'h264',
		'rm',
	],
	audio: [
		'aac',
		'aif',
		'aiff',
		'aifc',
		'aif',
		'aiff',
		'aifc',
		'wav',
		'wave',
		'wav',
		'wave',
		'wav',
		'wave',
		'wav',
		'wave',
		'weba',
		'flac',
		'ogg',
		'oga',
		'mp3',
		'mid',
		'midi',
		'pcm',
	],
	archive: [
		'zip',
		'zipx',
		'bdoc',
		'edoc',
		'rar',
		'rev',
		'r00',
		'r01',
		'rar',
		'7z',
		'bz2',
		'gz',
		'bz2',
		'tar',
		'gz',
		'epub',
	],
	special: [ 'edoc', 'bdoc' ],
	misc: [ 'csv', 'css', 'indd', 'ind', 'indt', 'xml', 'xml', 'html', 'xhtml', 'htm', 'azw', 'mobi', 'log' ],
}

export const fileExtensionsFlat = () => {
	let extensions = []
	Object.keys(fileExtensions).forEach((key) => {
		extensions = extensions.concat(fileExtensions[key])
	})
	return extensions
}

import Vue from 'vue';
import moment from 'moment';
import * as api from '../../api';
import * as types from '../mutation-types';
import { errorMessage } from '@utils';
import i18n from '../../i18n';

export const state = () => ({
	activeMessage: {},
	messageInEdit: {},
	checkedMessages: [],
	messageLastSeenUsers: [],
	messageLastSeenLastPage: 1,
	messageLastSeenUsersLastPage: false,
	messageLastSeenBusy: false,
	messageEditBusy: false,
	forwardMessagesBusy: false,
	forwardMessageActive: false,
	firstSeenDelay: null,
	sendImportant: false,
	foundEmojis: [],
	emojiSearchParams: {},
	lastReadMessage: 0,
})

export const mutations = {
	votePoll (state, payload) {
		var responsePoll = payload.response.data.data ? payload.response.data.data : payload.response.data;
		let message
		if (this.state.overview.currentConversation.group_id !== responsePoll.group_id ) {
			let foundSavedConversation = this.state.messageList.conversationsMessagesList.find(conversation => conversation.groupId === responsePoll.group_id)
			// 	return if we haven't opened conversation yet
			if (!foundSavedConversation) return
			message = foundSavedConversation.messages.find((message) => message.poll?.id === responsePoll.poll.id)
		} else {
			message = this.state.messageList.conversationMessages.find((message) => message.poll?.id === responsePoll.poll.id)
		}
		if (message) {
			message.poll = responsePoll.poll
		}
	},
	removeDeletedAttachmentFromMessage (state, { message, attachment }) {
		const groupId = message.group_id
		const messageId = message.message_id
		const attachmentId = attachment.attachment_id
		this.commit('attachments/deleteAttachments', { attachments: [attachment], groupId: groupId })

		this.state.messageList.conversationMessages.forEach(function (item) {
			if (item.message_id === messageId) {
				item.attachments = item.attachments.filter(attachment => attachment.attachment_id !== attachmentId)
			}
		});
		this.state.overview.conversations.forEach((item) => {
			if (item.group.group_id === groupId) {
				item.last_message = {
					...item.last_message,
					attachments: item.last_message.attachments.filter(attachment => attachment.attachment_id !== attachmentId)
				};
			}
		});
	},
	[types.EDIT_MESSAGE] (state, payload) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'EDIT_MESSAGE');
		this._vm.trackGA4clickEvent('EDIT_MESSAGE');
		state.messageEditBusy = true;
		this.state.messageList.conversationMessages.forEach((item, index) => {
			if (item.message_id == payload.messageId) {
				var messageInEdit = payload.messageInEdit;
				messageInEdit.content_plain = payload.message;
				messageInEdit.last_edited = moment().format();
				Vue.set(this.state.messageList.conversationMessages, index, messageInEdit);
			}
		});
		state.messageInEdit = '';
	},
	[types.EDIT_MESSAGE_SUCCESS] (state, payload) {
		state.messageEditBusy = false;
		var responseMessage = payload.response.data.data ? payload.response.data.data : payload.response.data;
		this.state.messageList.conversationMessages.forEach((item, index) => {
			if (item.message_id == responseMessage.message_id) {
				var updatedMessage = item;
				updatedMessage.content_plain = responseMessage.content_plain;
				updatedMessage.last_edited = responseMessage.last_edited;
				updatedMessage.poll = responseMessage.poll;
				Vue.set(this.state.messageList.conversationMessages, index, updatedMessage);
			}
		});
		this.commit('messageList/updateConversationMessage', { message: responseMessage, groupId: responseMessage.group_id, action:'edit' })
	},
	[types.EDIT_MESSAGE_FAIL] (state) {
		state.messageEditBusy = false;
	},
	[types.GET_MESSAGE_LAST_SEEN] (state) {
		state.messageLastSeenBusy = true;
		if (state.messageLastSeenLastPage == 1) {
			this._vm.trackGA4clickEvent('GET_MESSAGE_LAST_SEEN');
			// this._vm.$ga.sendEvent('USER_ACTION', 'GET_MESSAGE_LAST_SEEN');
		}
	},
	[types.GET_MESSAGE_LAST_SEEN_SUCCESS] (state, response) {
		state.messageLastSeenBusy = false;
		state.messageLastSeenUsers = state.messageLastSeenUsers.concat(response.data.data.data);
		state.messageLastSeenLastPage = state.messageLastSeenPage >= response.data.data.last_page;
		state.messageLastSeenPage++;
	},
	[types.GET_MESSAGE_LAST_SEEN_FAIL] (state) {
		state.messageLastSeenBusy = false;
	},
	[types.CLEAR_MESSAGE_LAST_SEEN] (state) {
		state.messageLastSeenUsers = [];
		state.messageLastSeenPage = 1;
		state.messageLastSeenLastPage = false;
	},
	[types.FORWARD_MESSAGES] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'FORWARD_MESSAGES');
		this._vm.trackGA4clickEvent('FORWARD_MESSAGES');
		state.forwardMessagesBusy = true;
	},
	[types.FORWARD_MESSAGES_SUCCESS] (state, response) {
		state.forwardMessagesBusy = false;
		response.data.data.forEach((responseItem) => {
			var found = false;
			this.state.overview.conversations.forEach(function (item, index, conversations) {
				if (item.group.group_id == responseItem.group_id) {
					found = true;
					conversations.splice(index, 1);
					conversations.unshift(responseItem);
				}
			});
			if (!found) {
				this.state.overview.conversations.unshift(responseItem);
			}
		});
	},
	[types.FORWARD_MESSAGES_FAIL] (state) {
		state.forwardMessagesBusy = false;
	},
	[types.UPDATE_MESSAGE_READ] (state, response) {
		if (this.state.overview.currentConversationId == response.data.group_id) {
			var foundMessages = this.state.messageList.conversationMessages.filter(
				(searchMessage) =>
					searchMessage.message_id <= response.data.message_id &&
					searchMessage.author &&
					searchMessage.author.id === this.state.user.user.id,
			);
			foundMessages.forEach((message) => {
				message.first_seen_any = response.data.first_seen_any;
			});
		}
		this.commit('messageList/updateConversationMessage', { message: response.data, groupId: response.data.group_id, action: 'read' })

	},
	[types.HIDE_MESSAGE] () {
		// this._vm.$ga.sendEvent('USER_ACTION', 'DELETE_MESSAGE_FOR_ME');
		this._vm.trackGA4clickEvent('DELETE_MESSAGE_FOR_ME');
		this.state.messageList.conversationMessagesBusy = true;
	},
	[types.HIDE_MESSAGE_SUCCESS] (state, payload) {
		this.state.messageList.conversationMessagesBusy = false;

		// remove message attachments from attachments tab
		const deletedMessage = this.state.messageList.conversationMessages.find(item => item.message_id === payload.messageId)
		this.commit('attachments/deleteAttachments', { attachments: [...deletedMessage.attachments], groupId: deletedMessage.group_id })

		this.state.messageList.conversationMessages.forEach(function (item, index, message) {
			if (item.message_id == payload.messageId) {
				message.splice(index, 1);
			}
		});
		this.state.overview.conversations.forEach((item, index, conversations) => {
			if (item.group.group_id == payload.groupId) {
				var messageBeforeThat =
					this.state.messageList.conversationMessages.length > 1 &&
					this.state.messageList.conversationMessages[this.state.messageList.conversationMessages.length -1]
						? this.state.messageList.conversationMessages[this.state.messageList.conversationMessages.length -1]
						: null;
				item.last_message = messageBeforeThat;
				conversations.splice(index, 1);
				conversations.unshift(item);
			}
		});
	},
	[types.HIDE_MESSAGE_FAIL] () {
		this.state.messageList.conversationMessagesBusy = false;
	},
	[types.DELETE_MESSAGE] () {
		// this._vm.$ga.sendEvent('USER_ACTION', 'DELETE_MESSAGE_FOR_EVERYONE');
		this._vm.trackGA4clickEvent('DELETE_MESSAGE_FOR_EVERYONE');
		this.state.messageList.conversationMessagesBusy = true;
	},
	[types.DELETE_MESSAGE_SUCCESS] (state, payload) {
		var messageContent = i18n.t('message_was_deleted');
		this.state.messageList.conversationMessagesBusy = false;
		// remove message attachments from attachments tab
		const deletedMessage = this.state.messageList.conversationMessages.find(item => item.message_id === payload.messageId)
		this.commit('attachments/deleteAttachments', { attachments: [...deletedMessage.attachments], groupId: deletedMessage.group_id })

		this.state.messageList.conversationMessages.forEach(function (item) {
			if (item.message_id == payload.messageId) {
				item.attachments = [];
				item.deleted_by_sender = true;
				item.content_plain = i18n.t('message_was_deleted');
				item.editable = false;
				item.reactions = [];
			}
		});
		this.state.overview.conversations.forEach((item, index, conversations) => {
			if (item.group.group_id == payload.groupId && item.last_message.message_id == payload.messageId) {
				item.last_message.attachments = [];
				item.last_message.content_plain = i18n.t('message_was_deleted');
				conversations.splice(index, 1);
				conversations.unshift(item);
			}
		});
		this.commit('messageList/updateConversationMessage', { message: { messageContent, message_id: payload.messageId }, groupId: payload.groupId || payload.group_id, action:'delete' })
	},
	[types.DELETE_MESSAGE_FAIL] () {
		this.state.messageList.conversationMessagesBusy = false;
	},
	[types.UPDATE_MESSAGE_SEEN] (state, messageId) {
		state.lastReadMessage = messageId
	},
	[types.UPDATE_MESSAGE_SEEN_SUCCESS] (state, response) {
		if (window.parent) {
			var dataObject = {
				type: 'readMessages',
				amount: response.data.data,
			};
			window.parent.postMessage(dataObject, '*', []);
		}
	},
	[types.UPDATE_MESSAGE_SEEN_FAIL] () {},
	updateMessageInEdit (state, value) {
		state.messageInEdit = value;
	},
	updateCheckedMessages (state, value) {
		state.checkedMessages = value;
	},
	updateForwardMessageActive (state, value) {
		state.forwardMessageActive = value;
	},
	updateSendImportant (state, value) {
		state.sendImportant = value;
	},
	updateFoundEmojis (state, value) {
		state.foundEmojis = value;
	},
	updateEmojiSearchParams (state, value) {
		state.emojiSearchParams = value;
	},
	updateFirstSeenLauncher (state, messageId) {
		if(messageId < 0) return
		if (state.firstSeenDelay) {
			clearTimeout(state.firstSeenDelay);
		}
		state.firstSeenDelay = setTimeout(() => this.dispatch('messageOptions/updateFirstSeen', messageId).then(() => {}, () => {}), 500);
	},
};

export const actions = {
	editMessage: (store, { message, messageId, polling }) => {
		var messageInEdit = store.state.messageInEdit;
		store.commit(types.EDIT_MESSAGE, { message, messageId, messageInEdit });
		return api.editMessage(message, messageId, polling).then(
			(response) => {
				store.commit(types.EDIT_MESSAGE_SUCCESS, { response, messageId, messageInEdit });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.EDIT_MESSAGE_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		);
	},
	deleteMessageAttachment: (store, { attachment, message }) => {
		const attachmentId = attachment.attachment_id

		return api.deleteMessageAttachment(attachmentId).then(
			(response) => {
				store.commit('removeDeletedAttachmentFromMessage', { message, attachment });
				return Promise.resolve(response)
			},
			(error) => {
				return Promise.reject(errorMessage(error))
			}
		)
	},
	getMessageSeenBy: (store, messageId) => {
		store.commit(types.GET_MESSAGE_LAST_SEEN);
		return api.getMessageSeenBy(messageId, store.state.messageLastSeenPage).then(
			(response) => {
				store.commit(types.GET_MESSAGE_LAST_SEEN_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.GET_MESSAGE_LAST_SEEN_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		);
	},
	forwardMessages: (store, { groupId, users, groups, messages, currentSchool, currentYear }) => {
		store.commit(types.FORWARD_MESSAGES, groupId);
		return api.forwardMessages(groupId, users, groups, messages, currentSchool, currentYear).then(
			(response) => {
				store.commit(types.FORWARD_MESSAGES_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.FORWARD_MESSAGES_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		);
	},
	deleteMessage: (store, { messageId, groupId, user }) => {
		store.commit(types.DELETE_MESSAGE);
		return api.deleteMessage(messageId).then(
			(response) => {
				store.commit(types.DELETE_MESSAGE_SUCCESS, { response, messageId, groupId, user });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.DELETE_MESSAGE_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		);
	},
	hideMessage: (store, { messageId, groupId }) => {
		store.commit(types.HIDE_MESSAGE);
		return api.hideMessage(messageId).then(
			(response) => {
				store.commit(types.HIDE_MESSAGE_SUCCESS, { response, messageId, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.HIDE_MESSAGE_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		);
	},
	updateFirstSeen: (store, messageId) => {
		store.commit(types.UPDATE_MESSAGE_SEEN, messageId);
		return api.updateFirstSeen(messageId).then(
			(response) => {
				store.commit(types.UPDATE_MESSAGE_SEEN_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.UPDATE_MESSAGE_SEEN_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		);
	},
	clearMessageLastSeen: (store) => {
		store.commit(types.CLEAR_MESSAGE_LAST_SEEN);
	},
};

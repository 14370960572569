import * as api from '../../api';
import * as types from '../mutation-types';
import { errorMessage } from '@utils';
import { getEnv } from '@env';

export const state = () => ({
	user: {
		firstname: '',
		lastname: '',
		profile_picture: '',
		locale: getEnv('VUE_APP_FRONTEND_DEFAULT_LOCALE'),
		id: 0,
	},
	schools: {},
	importantMessageAllowed: {},
	emailNotificationDelayType: 1,
	enterSendsMessage: false,
	showEmptyConvo: false,
	getUserBusy: false,
	userSettingsBusy: false,
	setUserSettingsBusy: false,
	readAllMessagesBusy: false,
	importantMessageAllowedBusy: false,
	schoolsSizeLimits: {}

})

export const mutations = {
	[types.GET_USER_SETTINGS] (state) {
		state.userSettingsBusy = true;
	},
	[types.GET_USER_SETTINGS_SUCCESS] (state, response) {
		state.userSettingsBusy = false;
		// state.userSettings = response.data.data
		state.emailNotificationDelayType = response.data.data.email_notification_delay_type;
		state.enterSendsMessage = response.data.data.enter_sends_message;
		state.showEmptyConvo = response.data.data.show_empty_convo;
		// Todo burn this after mobile app is done
		if (this.state.general.mobile) state.enterSendsMessage = false;
	},
	[types.GET_USER_SETTINGS_FAIL] (state) {
		state.userSettingsBusy = false;
		// Todo burn this after mobile app is done
		if (this.state.general.mobile) state.enterSendsMessage = false;
	},

	[types.GET_CURRENT_USER] (state) {
		state.getUserBusy = true;
		// Vue.set(state.userSettings, 'enter_sends_message', false)
	},

	[types.GET_CURRENT_USER_SUCCESS] (state, response) {
		state.getUserBusy = false;
		state.user = response.data.data;
	},
	[types.GET_CURRENT_USER_FAIL] (state) {
		state.getUserBusy = false;
	},

	[types.SET_USER_SETTINGS] (state, userSettings) {
		state.setUserSettingsBusy = true;
		this._vm.trackGA4clickEvent('CHANGE_SETTINGS');
	},
	[types.SET_USER_SETTINGS_SUCCESS] (state, response) {
		state.setUserSettingsBusy = false;
		state.emailNotificationDelayType = response.data.data.email_notification_delay_type;
		state.enterSendsMessage = response.data.data.enter_sends_message;
		state.showEmptyConvo = response.data.data.show_empty_convo;
	},
	[types.SET_USER_SETTINGS_FAIL] (state) {
		state.setUserSettingsBusy = false;
	},
	[types.READ_ALL_MESSAGES] (state) {
		state.readAllMessagesBusy = true;
		this._vm.trackGA4clickEvent('READ_ALL_MESSAGES');
	},
	readAllMessagesSuccess (state) {
		state.readAllMessagesBusy = false;
		this.state.overview.conversations.forEach(function (item) {
			item.unread_count = 0;
		});
	},
	[types.READ_ALL_MESSAGES_FAIL] (state) {
		state.readAllMessagesBusy = false;
	},
	[types.IMPORTANT_MESSAGE_ALLOWED] (state) {
		state.importantMessageAllowedBusy = true;
	},
	[types.IMPORTANT_MESSAGE_ALLOWED_SUCCESS] (state, payload) {
		state.importantMessageAllowedBusy = false;
		state.importantMessageAllowed = {
			groupId: payload.groupId,
			allowed: payload.response.data ? payload.response.data.data.is_staff : false,
		}
	},
	[types.IMPORTANT_MESSAGE_ALLOWED_FAIL] (state) {
		state.importantMessageAllowedBusy = false;
	},
	[types.GET_SCHOOL_FEATURE_SUCCESS] (state, { schoolId, features }) {
		state.schools = { ...state.schools, [schoolId]: features }
	},
	[types.GET_SCHOOL_SIZE_LIMIT_SUCCESS] (state, { schoolId, size }) {
		state.schoolsSizeLimits = { ...state.schoolsSizeLimits, [schoolId]: size }
	},
	updateEmailNotificationDelayType (state, value) {
		state.emailNotificationDelayType = value;
	},
	updateEnterSendsMessage (state, value) {
		state.enterSendsMessage = value;
	},
	updateShowEmptyConvo (state, value) {
		state.showEmptyConvo = value;
	},
};

export const actions = {
	getUser: (store) => {
		store.commit(types.GET_CURRENT_USER);
		return api.getUser().then(
			(response) => {
				store.commit(types.GET_CURRENT_USER_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.GET_CURRENT_USER_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	getUserSettings (store) {
		store.commit(types.GET_USER_SETTINGS);
		return api.getUserSettings().then(
			(response) => {
				store.commit(types.GET_USER_SETTINGS_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.GET_USER_SETTINGS_FAIL, error);
			}
		);
	},
	setUserSettings: (store, userSettings) => {
		store.commit(types.SET_USER_SETTINGS, userSettings);
		return api.setUserSettings(userSettings).then(
			(response) => {
				store.commit(types.SET_USER_SETTINGS_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.SET_USER_SETTINGS_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	readAllMessages: (store) => {
		store.commit(types.READ_ALL_MESSAGES);
		return api.readAllMessages().then(
			(response) => {
				store.commit('readAllMessagesSuccess', response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.READ_ALL_MESSAGES_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	getImportantMessageRole: (store, groupId) => {
		store.commit(types.IMPORTANT_MESSAGE_ALLOWED);
		return api.getImportantMessageRole(groupId).then(
			(response) => {
				store.commit(types.IMPORTANT_MESSAGE_ALLOWED_SUCCESS, { groupId, response });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.IMPORTANT_MESSAGE_ALLOWED_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		).catch(() => {
			// Handle promise errors caused by request abort
		});
	},
	getSchoolFeature (store, schoolId) {
		// store.commit(types.GET_SCHOOL_FEATURE);
		return api.getSchoolFeature(schoolId).then(
			(response) => {
				store.commit(types.GET_SCHOOL_FEATURE_SUCCESS, { schoolId, features: response.data.data });
				return Promise.resolve(response);
			},
			(error) => {
				// store.commit(types.GET_CURRENT_USER_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	getSchoolUploadLimit (store, schoolId) {
		if (store.state.schoolsSizeLimits[schoolId]) return
		if (!getEnv('VUE_APP_FRONTEND_UPLOAD_LIMIT_ENABLED')) return
		return api.getSchoolSizeLimit(schoolId).then(
			(response) => {
				store.commit(types.GET_SCHOOL_SIZE_LIMIT_SUCCESS, { schoolId, size: Number(response.data) });
				return Promise.resolve(response);
			},
			(error) => {
				// store.commit(types.GET_CURRENT_USER_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	}
};

import Vue from 'vue';
import moment from 'moment';
import * as api from '../../api';
import * as types from '../mutation-types';
import { errorMessage } from '@utils';

export const state = () => ({
	conversationDetails: [],
	conversationGroupPicture: [],
	conversationActionBusy: false,
	conversationDetailsChangeBusy: false,
	conversationDetailsBusy: false,
	clearConversationBusy: false,
	muteConversationBusy: false,
	savedConversationDetails: [],
})

export const mutations = {
	[types.ARCHIVE_CONVERSATION] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'ARCHIVE_CONVERSATION');
		this._vm.trackGA4clickEvent('ARCHIVE_CONVERSATION');
		state.conversationActionBusy = true;
		this.state.messageOptions.forwardMessageActive = false;
	},
	[types.ARCHIVE_CONVERSATION_SUCCESS] (state, payload) {
		state.conversationActionBusy = false;
		var groupId = payload.groupId;
		this.state.overview.conversations.forEach(function (item, index, element) {
			if (item.group.group_id == groupId) {
				element.splice(index, 1);
			}
		});
	},
	[types.ARCHIVE_CONVERSATION_FAIL] (state) {
		state.conversationActionBusy = false;
	},
	[types.CLEAR_CONVERSATION] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'CLEAR_CONVERSATION');
		this._vm.trackGA4clickEvent('CLEAR_CONVERSATION');
		state.conversationActionBusy = true;
		this.state.messageOptions.forwardMessageActive = false;
	},
	[types.CLEAR_CONVERSATION_SUCCESS] (state, payload) {
		state.conversationActionBusy = false;
		var groupId = payload.groupId;
		var that = this;
		this.state.overview.conversations.forEach(function (item, index) {
			if (item.group.group_id == groupId) {
				that.state.messageList.conversationMessages = [];
				that.state.overview.conversations[index].last_message = null;
				that.state.messageList.conversationMessagesLastPage = true;
			}
		});
	},
	[types.CLEAR_CONVERSATION_FAIL] (state) {
		state.conversationActionBusy = false;
	},
	[types.LEAVE_CONVERSATION] () {
		// this._vm.$ga.sendEvent('USER_ACTION', 'LEAVE_CONVERSATION');
		this._vm.trackGA4clickEvent('LEAVE_CONVERSATION');
		this.state.members.conversationMembersBusy = true;
	},
	[types.LEAVE_CONVERSATION_SUCCESS] (state, payload) {
		this.state.members.conversationMembersBusy = false;
		var groupId = payload.groupId;
		var that = this;
		this.state.overview.conversations.forEach(function (item, index) {
			if (item.group.group_id == groupId) {
				that.state.overview.conversations[index].left_date = moment().format();
			}
		});
	},
	[types.LEAVE_CONVERSATION_FAIL] () {
		this.state.members.conversationMembersBusy = false;
	},
	[types.DELETE_CONVERSATION] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'DELETE_CONVERSATION');
		this._vm.trackGA4clickEvent('DELETE_CONVERSATION');
		state.conversationActionBusy = true;
		this.state.messageOptions.forwardMessageActive = false;
	},
	[types.DELETE_CONVERSATION_SUCCESS] (state, payload) {
		state.conversationActionBusy = false;
		var groupId = payload.groupId;
		this.state.overview.conversations.forEach(function (item, index, element) {
			if (item.group.group_id == groupId) {
				element.splice(index, 1);
			}
		});
	},
	[types.DELETE_CONVERSATION_FAIL] (state) {
		state.conversationActionBusy = false;
	},
	[types.DELETE_CONVERSATION_PERM] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'DELETE_CONVERSATION_PERM');
		this._vm.trackGA4clickEvent('DELETE_CONVERSATION_PERM');
		state.conversationActionBusy = true;
	},
	[types.DELETE_CONVERSATION_PERM_SUCCESS] (state, payload) {
		state.conversationActionBusy = false;
		var groupId = payload.groupId;
		this.state.overview.conversations.forEach(function (item, index, element) {
			if (item.group.group_id == groupId) {
				element.splice(index, 1);
			}
		});
	},
	[types.DELETE_CONVERSATION_PERM_FAIL] (state) {
		state.conversationActionBusy = false;
	},
	[types.MUTE_CONVERSATION] (state) {
		// this._vm.$ga.sendEvent(
		// 	'USER_ACTION',
		// 	'MUTE_CONVERSATION',
		// 	this.state.overview.currentConversation.group
		// 		? this.state.overview.currentConversation.group.group_type +
		// 			(this.state.overview.currentConversation.group.is_private ? ' private' : '')
		// 		: ''
		// );
		this._vm.trackGA4clickEvent('MUTE_CONVERSATION', { type: this.state.overview.currentConversation.group
				? this.state.overview.currentConversation.group.group_type +
				(this.state.overview.currentConversation.group.is_private ? ' private' : '')
				: ''});
		state.conversationActionBusy = true;
	},
	[types.MUTE_CONVERSATION_SUCCESS] (state, payload) {
		state.conversationActionBusy = false;
		var groupId = payload.groupId;
		var that = this;
		this.state.overview.conversations.forEach(function (item, index) {
			if (item.group.group_id == groupId) {
				that.state.overview.conversations[index].is_muted = true;
			}
		});
	},
	[types.MUTE_CONVERSATION_FAIL] (state) {
		state.conversationActionBusy = false;
	},
	[types.UNMUTE_CONVERSATION] (state) {
		this._vm.trackGA4clickEvent('UNMUTE_CONVERSATION', { type: this.state.overview.currentConversation.group
				? this.state.overview.currentConversation.group.group_type +
				(this.state.overview.currentConversation.group.is_private ? ' private' : '')
				: ''});
		// this._vm.$ga.sendEvent(
		// 	'USER_ACTION',
		// 	'UNMUTE_CONVERSATION',
		// 	this.state.overview.currentConversation.group
		// 		? this.state.overview.currentConversation.group.group_type +
		// 			(this.state.overview.currentConversation.group.is_private ? ' private' : '')
		// 		: ''
		// );
		state.conversationActionBusy = true;
	},
	[types.UNMUTE_CONVERSATION_SUCCESS] (state, payload) {
		state.conversationActionBusy = false;
		var groupId = payload.groupId;
		var that = this;
		this.state.overview.conversations.forEach(function (item, index) {
			if (item.group.group_id == groupId) {
				that.state.overview.conversations[index].is_muted = false;
			}
		});
	},
	[types.UNMUTE_CONVERSATION_FAIL] (state) {
		state.conversationActionBusy = false;
	},
	[types.TOGGLE_REPLIES] (state, toggle) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'TOGGLE_REPLIES', toggle);
		this._vm.trackGA4clickEvent('TOGGLE_REPLIES', { value: toggle });
		state.conversationActionBusy = true;
	},
	[types.TOGGLE_REPLIES_SUCCESS] (state, payload) {
		state.conversationActionBusy = false;
		var isSocket = payload.socket
		var groupId = payload.groupId;
		var repliesMode = isSocket ? payload.replies : payload.response.data.data
		var that = this;
		// todo ask for ful lresponsonse
		this.state.overview.conversations.forEach(function (item, index) {
			if (item.group.group_id == groupId) {
				if (isSocket) {
					Vue.set(that.state.overview.conversations[index].group, 'group_type', repliesMode);
				} else {
					Vue.set(that.state.overview.conversations[index], 'group', repliesMode);
				}
			}
		});
	},
	[types.TOGGLE_REPLIES_FAIL] (state) {
		state.conversationActionBusy = false;
	},
	[types.DELETE_CONVERSATION_PICTURE] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'DELETE_CONVERSATION_PICTURE');
		this._vm.trackGA4clickEvent('DELETE_CONVERSATION_PICTURE');
		state.conversationDetailsChangeBusy = true;
	},
	[types.DELETE_CONVERSATION_PICTURE_SUCCESS] (state, groupId) {
		state.conversationDetailsChangeBusy = false;
		var that = this;
		this.state.overview.conversations.forEach(function (element, index) {
			if (element.group.group_id == groupId) {
				that.state.overview.conversations[index].group.group_picture = null;
			}
		});
	},
	[types.DELETE_CONVERSATION_PICTURE_FAIL] (state) {
		state.conversationDetailsChangeBusy = false;
	},
	[types.CHANGE_CONVERSATION_PICTURE] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'CHANGE_CONVERSATION_PICTURE');
		this._vm.trackGA4clickEvent('CHANGE_CONVERSATION_PICTURE');
		state.conversationDetailsChangeBusy = true;
	},
	[types.CHANGE_CONVERSATION_PICTURE_SUCCESS] (state, response) {
		state.conversationDetailsChangeBusy = false;
		var responseConversation = response.data.data;
		var that = this;
		this.state.overview.conversations.forEach(function (element, index) {
			if (element.group.group_id == responseConversation.group_id) {
				Vue.set(that.state.overview.conversations[index].group, 'group_picture', responseConversation.group_picture);
			}
		});
	},
	[types.CHANGE_CONVERSATION_PICTURE_FAIL] (state) {
		state.conversationDetailsChangeBusy = false;
	},
	[types.CHANGE_CONVERSATION_TITLE] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'CHANGE_CONVERSATION_TITLE');
		this._vm.trackGA4clickEvent('CHANGE_CONVERSATION_TITLE');
		state.conversationDetailsChangeBusy = true;
	},
	[types.CHANGE_CONVERSATION_DETAILS] (state, response) {
		state.conversationDetailsChangeBusy = false;
		var responseConversation = response.data.data;
		this.state.overview.conversations.forEach((element, index) => {
			if (element.group.group_id == responseConversation.group_id) {
				if(response.socket){
					Vue.set(this.state.overview.conversations[index].group, 'group_id', responseConversation.group_id);
					Vue.set(this.state.overview.conversations[index].group, 'group_picture', responseConversation.group_picture);
					Vue.set(this.state.overview.conversations[index].group, 'title', responseConversation.title);
				}else{
					Vue.set(this.state.overview.conversations[index].group, 'title', responseConversation.title);
				}
			}
		});
	},
	[types.CHANGE_CONVERSATION_TITLE_FAIL] (state) {
		state.conversationDetailsChangeBusy = false;
	},
	[types.GET_CONVERSATION_DETAILS] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'GET_CONVERSATION_DETAILS');
		this._vm.trackGA4clickEvent('GET_CONVERSATION_DETAILS');
		state.conversationDetailsBusy = true;
	},
	[types.GET_CONVERSATION_DETAILS_SUCCESS] (state, { response, replace }) {
		state.conversationDetailsBusy = false;
		state.conversationDetails = response.data.data;

		var foundConversation = false;
		this.state.overview.conversations.forEach(function (item) {
			if (item.group.group_id == state.conversationDetails.group.group_id) {
				foundConversation = true;
			}
		});

		if (!foundConversation) {
			this.state.overview.conversations.unshift(state.conversationDetails);
		}
		//replaces admin when requesting details
		if(replace){
			this.state.overview.currentConversation.group.admin = []
			this.state.overview.currentConversation.group.admin = state.conversationDetails.group.admin
			this.commit('conversationOptions/updateConversationList', state.conversationDetails);
		}
	},
	[types.GET_CONVERSATION_DETAILS_FAIL] (state) {
		state.conversationDetailsBusy = false;
	},
	setConversationActionBusy (state, value) {
		state.conversationActionBusy = value
	},
	updateConversationGroupPicture (state, value) {
		state.conversationGroupPicture = value;
	},
	clearConversationDetails (state) {
		state.conversationDetails = {};
	},
	updateConversationList (state, conversationData) {
		let conversationEntry = state.savedConversationDetails.find(conversation => conversation.group_id == conversationData.group_id)
		if(!conversationEntry) {
			state.savedConversationDetails.push(conversationData)
		}else{
			conversationEntry = conversationData
		}
	},
	updateConversationDetails (state, conversationDetails) {
		state.conversationDetails = conversationDetails
	},
};

export const actions = {
	archiveConversation: (store, { groupId, userId, archive }) => {
		store.commit(types.ARCHIVE_CONVERSATION);
		return api.archiveConversation(groupId, userId, archive).then(
			(response) => {
				store.commit(types.ARCHIVE_CONVERSATION_SUCCESS, { response, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.ARCHIVE_CONVERSATION_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	clearConversation: (store, groupId) => {
		store.commit(types.CLEAR_CONVERSATION);
		return api.clearConversation(groupId).then(
			(response) => {
				store.commit(types.CLEAR_CONVERSATION_SUCCESS, { response, groupId });
				store.commit('attachments/' + types.CLEAR_CONVERSATION_ATTACHMENTS, {}, { root: true })
				store.commit('messageList/clearConversationMessages', { groupId }, { root: true })
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.CLEAR_CONVERSATION_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	leaveConversation: (store, groupId) => {
		store.commit(types.LEAVE_CONVERSATION);
		return api.leaveConversation(groupId, store.rootState.user.user.id).then(
			(response) => {
				store.commit(types.LEAVE_CONVERSATION_SUCCESS, { response, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.LEAVE_CONVERSATION_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	deleteConversation: (store, groupId) => {
		store.commit(types.DELETE_CONVERSATION);
		return api.deleteConversation(groupId, store.rootState.user.user.id).then(
			(response) => {
				store.commit(types.DELETE_CONVERSATION_SUCCESS, { response, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.DELETE_CONVERSATION_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	deleteConversationPermanently: (store, groupId) => {
		store.commit(types.DELETE_CONVERSATION_PERM);
		return api.deleteConversationPermanently(groupId).then(
			(response) => {
				store.commit(types.DELETE_CONVERSATION_PERM_SUCCESS, { response, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.DELETE_CONVERSATION_PERM_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	muteConversation: (store, { groupId, muteLevel }) => {
		store.commit(types.MUTE_CONVERSATION);
		return api.muteConversation(groupId, muteLevel).then(
			(response) => {
				store.commit(types.MUTE_CONVERSATION_SUCCESS, { response, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.MUTE_CONVERSATION_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	unmuteConversation: (store, groupId) => {
		store.commit(types.UNMUTE_CONVERSATION);
		return api.unmuteConversation(groupId).then(
			(response) => {
				store.commit(types.UNMUTE_CONVERSATION_SUCCESS, { response, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.UNMUTE_CONVERSATION_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	toggleReplies: (store, { groupId, groupType }) => {
		store.commit(types.TOGGLE_REPLIES);
		return api.toggleReplies(groupId, groupType).then(
			(response) => {
				store.commit(types.TOGGLE_REPLIES_SUCCESS, { response, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.TOGGLE_REPLIES_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	deleteConversationPicture: (store, groupId) => {
		store.commit(types.DELETE_CONVERSATION_PICTURE);
		return api.deleteConversationPicture(groupId).then(
			(response) => {
				store.commit(types.DELETE_CONVERSATION_PICTURE_SUCCESS, groupId);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.DELETE_CONVERSATION_PICTURE_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	changeConversationPicture: (store, { groupId, conversationGroupPicture }) => {
		store.commit(types.CHANGE_CONVERSATION_PICTURE);
		return api.changeConversationPicture(groupId, conversationGroupPicture).then(
			(response) => {
				store.commit(types.CHANGE_CONVERSATION_PICTURE_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.CHANGE_CONVERSATION_PICTURE_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	changeConversationTitle: (store, { groupId, conversationTitle }) => {
		store.commit(types.CHANGE_CONVERSATION_TITLE);
		return api.changeConversationTitle(groupId, conversationTitle).then(
			(response) => {
				store.commit(types.CHANGE_CONVERSATION_DETAILS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.CHANGE_CONVERSATION_TITLE_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	getConversationDetails: (store, { groupId, replace }) => {
		let conversationEntry = store.state.savedConversationDetails.find(conversation => conversation.group_id == groupId)
		if(conversationEntry){
			api.cancelRequest('getConversationDetails')
			store.commit('updateConversationDetails', conversationEntry)
			return Promise.resolve()
		}
		store.commit(types.GET_CONVERSATION_DETAILS);
		return api.getConversationDetails(groupId).then(
			(response) => {
				store.commit(types.GET_CONVERSATION_DETAILS_SUCCESS, { response, replace });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.GET_CONVERSATION_DETAILS_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
};

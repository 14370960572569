
export const state = () => ({
	checkedOfficialGroups: [],
	checkedContactTags: [],
	checkedContactUserTags: [],
	checkedContacts: [],
	checkedConversations: [],
	currentContactTab: 'official',
	conversationType: '',
	userSearchString: '',
	selectedClass: '',
	userSearchOpen: false,
	lastSelectedSchool: {},
	lastSelectedYear: 0,
	contactTabNames: {
		students: 'students',
		parents: 'parents',
		students_with_parents: 'parents_with_students',
		personal_contacts: 'personal',
		administration: 'administration',
		teachers: 'teachers',
		official: 'official',
		conversations: 'conversations',
	},
	showCreateSoloConversationModal: () => {},
	showSoloConversationWarning: () => {},
})

export const mutations = {
	updateCheckedOfficialGroups (state, value) {
		state.checkedOfficialGroups = value
	},
	updateCheckedContactTags (state, value) {
		state.checkedContactTags = value
	},
	updateCheckedContactUserTags (state, value) {
		state.checkedContactUserTags = value
	},
	updateCheckedContacts (state, value) {
		state.checkedContacts = value
	},
	updateCheckedConversations (state, value) {
		state.checkedConversations = value
	},
	updateCurrentContactTab (state, value) {
		state.currentContactTab = value
	},
	updateConversationType (state, value) {
		state.conversationType = value
	},
	updateUserSearchString (state, value) {
		state.userSearchString = value
	},
	updateUserSearchOpen (state, value) {
		state.userSearchOpen = value
	},
	updateSelectedClass (state, value) {
		state.selectedClass = value
	},
	updateLastSelectedSchool (state, value) {
		state.lastSelectedSchool = value
	},
	updateLastSelectedYear (state, value) {
		state.lastSelectedYear = value
	},
	setShowCreateSoloConversationModal (state, value) {
		state.showCreateSoloConversationModal = value
	},
	setShowSoloConversationWarning (state, value) {
		state.showSoloConversationWarning = value
	},
	setCheckedContacts (state, contacts) {
		state.checkedContacts = contacts
	},
	toggleCheckedConversations (state, conversation) {
		if (conversation.left_date) {
			return
		}
		var found = false
		state.checkedConversations.forEach(function (item, index, element) {
			if (item.group.group_id == conversation.group.group_id) {
				element.splice(index, 1)
				found = true
			}
		})
		if (!found) {
			state.checkedConversations.push(conversation)
		}
	},
	removeCheckedTag (state, tag) {
		var tagUsers = this.state.contacts.contactTags.get(tag)
		tagUsers.forEach((tagUser) => {
			state.checkedContacts.forEach((checkedItem) => {
				var foundTaggedUser = false
				if (tagUser.id == checkedItem.id) {
					var anotherTagUsed = false
					if (tagUser.tags.length > 1) {
						tagUser.tags.forEach((tagItem) => {
							if (state.checkedContactTags.includes(tagItem) && tagItem != tag) {
								anotherTagUsed = true
							}
						})
					}
					foundTaggedUser = state.checkedContactUserTags.filter(
						checkedContactUserTag => checkedContactUserTag.id === tagUser.id
					).length > 0
					if (!anotherTagUsed && !foundTaggedUser) {
						state.checkedContacts = state.checkedContacts.filter(
							checkedContact => checkedContact.id !== tagUser.id
						)
					}
				}
			})
		})
		var tagIndex = state.checkedContactTags.indexOf(tag)

		if (tagIndex !== -1) {
			state.checkedContactTags.splice(tagIndex, 1)
		}
	},
	toggleOfficialGroups (state, group) {
		var found = state.checkedOfficialGroups.filter((officialGroup) => officialGroup === group).length > 0
		if (found) {
			state.checkedOfficialGroups = state.checkedOfficialGroups.filter((officialGroup) => officialGroup !== group)
		} else {
			state.checkedOfficialGroups = [...state.checkedOfficialGroups, group]
		}
	},
	addCheckedContactUpdate (state, { user, addTag }) {
		var foundUser = state.checkedContacts.filter(checkedContact => checkedContact.id === user.id).length > 0
		var foundTag =
			state.checkedContactUserTags.filter(checkedContactUserTags => checkedContactUserTags.id === user.id)
				.length > 0
		if (foundUser) {
			state.checkedContacts = state.checkedContacts.filter(checkedContact => checkedContact.id !== user.id)
			state.checkedContactUserTags = state.checkedContactUserTags.filter(
				checkedContactUserTag => checkedContactUserTag.id !== user.id
			)
			var userTags = typeof user.tags !== 'undefined' ? user.tags : []
			if (state.checkedContactTags.length > 0 && userTags.length > 0) {
				userTags.forEach(tagName => {
					if (state.checkedContactTags.includes(tagName)) {
						var tagUsers = this.state.contacts.contactTags.get(tagName)
						// checks if atleast one user from tag group is still checked to remove empty selected tags
						// use some cause it stops as soon as it returns true and wont loop through all
						var foundCheckedUserWithTag = tagUsers.some(tagUser => {
							return state.checkedContacts.some(checkedContact => checkedContact.id === tagUser.id)
						})
						if (!foundCheckedUserWithTag) {
							this.commit('selectedContacts/removeCheckedTag', tagName)
						}
					}
				})
			}
		} else {
			state.checkedContacts = [...state.checkedContacts, user]
		}
		if (!foundTag && !foundUser && addTag) {
			state.checkedContactUserTags = [...state.checkedContactUserTags, user]
		}
	},
}

export const actions = {
	toggleSelectAllClassContacts (store, schoolClass) {
		if (store.getters.checkedClassTabs.includes(schoolClass)) {
			store.dispatch(
				'deselectAllContacts',
				store.rootState.contacts.contacts[store.state.currentContactTab][schoolClass]
			)
		} else {
			store.dispatch(
				'selectAllContacts',
				store.rootState.contacts.contacts[store.state.currentContactTab][schoolClass]
			)
		}
	},
	addCheckedContact (store, { user, addTag }) {
		if (store.rootState.contacts.createConversationBusy || !user.id) {
			return
		}
		if (store.rootState.user.user.id === user.id && store.state.conversationType !== 'forward') {
			if (
				store.state.checkedContacts.length === 0 ||
				(store.state.checkedContacts.length === 1 && store.state.checkedContacts[0].id === store.rootState.user.user.id)
			) {
				store.state.showCreateSoloConversationModal()
			} else {
				store.state.showSoloConversationWarning()
			}
			return
		}
		store.commit('addCheckedContactUpdate', { user, addTag })
	},
	selectAllContacts ({ state, getters, commit }, contacts) {
		const contactsToCheck = []
		const run = (contacts) => {
			contacts.forEach((contact) => {
				if (contact.id && !getters.checkedContactsByIds[contact.id]) {
					contactsToCheck.push(contact)
				}
				if (contact.parents && contact.parents.length) {
					run(contact.parents)
				}
			})
		}
		run(contacts)
		if (contactsToCheck.length) {
			commit('setCheckedContacts', [...state.checkedContacts, ...contactsToCheck])
		}
	},
	deselectAllContacts ({ state, getters, commit }, contacts) {
		let contactsToUncheckExist = false
		const contactsToUncheck = {}
		const run = (contacts) => {
			contacts.forEach((contact) => {
				if (contact.id && getters.checkedContactsByIds[contact.id]) {
					contactsToUncheck[contact.id] = true
					contactsToUncheckExist = true
				}
				if (contact.parents && contact.parents.length) {
					run(contact.parents)
				}
			})
		}
		run(contacts)
		if (contactsToUncheckExist) {
			commit('setCheckedContacts', state.checkedContacts.filter(contact => !contactsToUncheck[contact.id]))
		}
	},
}

export const getters = {
	checkedContactsByIds: (state) => {
		return state.checkedContacts.reduce((out, checkedContact) => {
			out[checkedContact.id] = checkedContact
			return out
		}, {})
	},
	specialContactTabSelected: (state) => {
		return (
			state.currentContactTab === 'students_with_parents' ||
			state.currentContactTab === 'students' ||
			state.currentContactTab === 'parents'
		)
	},
	classIds: (state, getters, storeRoot) => {
		var classedContacts = {}
		var contacts = storeRoot.contacts.contacts
		Object.keys(contacts).filter((key) => contacts[key].constructor === Object).forEach((key) => {
			classedContacts[key] = {}
			Object.keys(contacts[key]).forEach((schoolClass) => {
				classedContacts[key][schoolClass] = Array.isArray(contacts[key][schoolClass])
					? contacts[key][schoolClass].flatMap((user) => {
						return user.parents ? [ user.id, ...user.parents.map((parent) => parent.id) ] : user.id
					})
					: []
			})
		})
		return classedContacts
	},
	checkedClassTabs: (state, getters, storeRoot) => {
		return getters.specialContactTabSelected
			? Object.keys(storeRoot.contacts.contacts[state.currentContactTab]).filter((key) => {
				var currentTabData = getters.classIds[state.currentContactTab]
					? getters.classIds[state.currentContactTab][key]
					: []
				let checkedUsers = state.checkedContacts.map((user) => user.id)
				return currentTabData.every((userId) => checkedUsers.includes(userId) || userId == null)
			})
			: []
	},
	emptyClasses: (state, getters, storeRoot) => {
		return getters.specialContactTabSelected
			? Object.keys(storeRoot.contacts.contacts[state.currentContactTab]).filter((key) => {
				var currentTabData = getters.classIds[state.currentContactTab][key]

				return currentTabData.every((userId) => userId == null)
			})
			: []
	},
	showContactTab: (state, getters, storeRoot) => (index) => {
		if (index == 'teachers') {
			return true
		}
		if (index == 'conversations' && state.conversationType == 'forward') {
			return true
		}
		if (index == 'official') {
			return (
				state.conversationType != 'addMembers' &&
				state.conversationType != 'forward' &&
				storeRoot.contacts.contacts[index] &&
				(storeRoot.contacts.contacts[index].length > 0 ||
					(typeof storeRoot.contacts.contacts[index] === 'object' &&
						Object.keys(storeRoot.contacts.contacts[index]).length != 0))
			)
		}
		return (
			storeRoot.contacts.contacts[index] &&
			(storeRoot.contacts.contacts[index].length > 0 ||
				(typeof storeRoot.contacts.contacts[index] === 'object' &&
					Object.keys(storeRoot.contacts.contacts[index]).length != 0))
		)
	},
}

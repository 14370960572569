import * as api from '../../api';
import * as types from '../mutation-types';
import { errorMessage } from '@utils';
import { fileType } from '@utilsDir/utils'

export const state = () => ({
	conversationAttachments: [],
	conversationAttachmentsPage: 1,
	conversationAttachmentsBusy: false,
	conversationAttachmentsLastPage: false,
	currentMediaObject: {},
	savedAttachmentLists: [],
	currentFileTab: {
		name: "Photos",
		value: "image",
	},
	galleryAttachments: [],
})

export const mutations = {
	[types.GET_CONVERSATION_ATTACHMENTS] (state) {
		if (state.conversationAttachmentsPage == 1) {
			// this._vm.$ga.sendEvent('USER_ACTION', 'GET_CONVERSATION_ATTACHMENTS');
			this._vm.trackGA4clickEvent('GET_CONVERSATION_ATTACHMENTS')
		}
		state.conversationAttachmentsBusy = true;
	},
	[types.GET_CONVERSATION_ATTACHMENTS_SUCCESS] (state, { response, groupId, type }) {
		if (state.currentFileTab.value === type) {
			state.conversationAttachments = state.conversationAttachments.concat(response.data.data.data);
			state.conversationAttachmentsLastPage = response.data.data.next_page_url === null;
			state.conversationAttachmentsPage++;
		}
		state.conversationAttachmentsBusy = false;
		this.commit('attachments/updateAttachmentLists', { response, groupId, type });
	},
	[types.GET_CONVERSATION_ATTACHMENTS_FAIL] (state) {
		state.conversationAttachmentsBusy = false;
	},
	[types.CLEAR_CONVERSATION_ATTACHMENTS] (state) {
		state.conversationAttachments = [];
		state.conversationAttachmentsPage = 1;
		state.conversationAttachmentsLastPage = false;
	},
	updateCurrentMediaObject (state, value) {
		state.currentMediaObject = value;
	},
	updateCurrentFileTab (state, value) {
		state.currentFileTab = value;
	},
	updateGalleryAttachments (state, value) {
		state.galleryAttachments = value;
	},
	updateAttachmentLists (state, { response, groupId, type }){
		if(response.data.data.data){
			let attachments = response.data.data.data
			let attachmentListEntry = state.savedAttachmentLists.find(attachmentList => attachmentList.groupId == groupId)
			if(!attachmentListEntry) {
				let attachmentEntryTemplate = {
					groupId,
					conversationAttachments: {},
					conversationAttachmentsLastPage: response.data.data.next_page_url === null,
					conversationAttachmentsPage: state.conversationAttachmentsPage,
				}
				attachmentEntryTemplate.conversationAttachments[type] = attachments
				state.savedAttachmentLists.push(attachmentEntryTemplate)
				attachmentListEntry = attachmentEntryTemplate
			} else {
				// cant push template here, because objects are too deep nested(state wont update)
				if(attachmentListEntry.conversationAttachments[type]){
					attachmentListEntry.conversationAttachments[type] = [...attachmentListEntry.conversationAttachments[type], ...attachments]
				}else{
					attachmentListEntry.conversationAttachments[type] = attachments
				}
				attachmentListEntry.conversationAttachmentsLastPage = response.data.data.next_page_url === null
				attachmentListEntry.conversationAttachmentsPage = state.conversationAttachmentsPage
			}
		}
	},
	updateAttachmentList (state, { attachmentListEntry, type }) {
		let copyWithoutRefrence = [...attachmentListEntry.conversationAttachments[type]]
		state.conversationAttachments = attachmentListEntry.conversationAttachments[type] ? copyWithoutRefrence : []
		state.conversationAttachmentsLastPage = attachmentListEntry.conversationAttachmentsLastPage
		state.conversationAttachmentsPage = attachmentListEntry.conversationAttachmentsPage
	},
	deleteAttachments: (state, { attachments = [], groupId }) => {
		// remove attachments linked with deleted message
		const attachmentIds = attachments.map(attachment => attachment.attachment_id)
		// state.conversationAttachments = state.conversationAttachments.filter(attachment => !attachmentIds.includes(attachment.attachment_id))
		state.savedAttachmentLists = state.savedAttachmentLists.map(list => {
			if (list.groupId === groupId) {
				const newObject = {}
				Object.entries(list.conversationAttachments).forEach(entry => {
					const [key, value] = entry
					newObject[key] = value.filter(attachment => !attachmentIds.includes(attachment.attachment_id))
				})
				return {
					...list,
					conversationAttachments: newObject
				}
			}
			return list
		})
	},
	updateAttachments (state, message) {
		if(!message.group_id) return
		let attachmentListEntry = state.savedAttachmentLists.find(attachmentList => attachmentList.groupId == message.group_id)
		let currentConversationMessage = this.state.overview.currentConversation.group_id === message.group_id
		let attachmentIndex = -1;
		if(!attachmentListEntry && !currentConversationMessage) return
		message.attachments.forEach((attachment) => {
			// for future types need more manual checks or sync types with backend
			attachment.author = message.author
			if (fileType(attachment.extension) === 'displayImage') {
				if(attachmentListEntry?.conversationAttachments?.image) {
					// remove last file beofre inserting new one, so pagination doesnt produce duplicattions
					if (!attachmentListEntry.conversationAttachmentsLastPage) {
						attachmentListEntry.conversationAttachments.image.pop()
					}
					attachmentIndex = attachmentListEntry.conversationAttachments.image.findIndex(a => a.attachment_id === attachment.attachment_id);
					if(attachmentIndex < 0) {
						attachmentListEntry.conversationAttachments.image.unshift(attachment)
					}
				}
				if (state.currentFileTab.value === 'image') {
					if (!state.conversationAttachmentsLastPage) {
						state.conversationAttachments.pop()
					}
					attachmentIndex = state.conversationAttachments.findIndex(a => a.attachment_id === attachment.attachment_id);
					if(attachmentIndex < 0) {
						state.conversationAttachments.unshift(attachment)
					}
				}
			} else {
				if (attachmentListEntry?.conversationAttachments?.file) {
					if (!attachmentListEntry.conversationAttachmentsLastPage) {
						attachmentListEntry.conversationAttachments.file.pop()
					}
					attachmentIndex = attachmentListEntry.conversationAttachments.file.findIndex(a => a.attachment_id === attachment.attachment_id);
					if(attachmentIndex < 0) {
						attachmentListEntry.conversationAttachments.file.unshift(attachment)
					}
				}
				if (state.currentFileTab.value === 'file') {
					if (!state.conversationAttachmentsLastPage) {
						state.conversationAttachments.pop()
					}
					attachmentIndex = state.conversationAttachments.findIndex(a => a.attachment_id === attachment.attachment_id);
					if(attachmentIndex < 0) {
						state.conversationAttachments.unshift(attachment)
					}
				}
			}
		})
	},
};

export const actions = {
	getConversationAttachments: (store, { groupId, type }) => {
		if(store.state.conversationAttachmentsPage === 1){
			let attachmentListEntry = store.state.savedAttachmentLists.find(attachmentList => attachmentList.groupId == groupId)
			if (attachmentListEntry && attachmentListEntry.conversationAttachments[type] && type !== 'link') {
				api.cancelRequest('getConversationAttachments')
				store.commit('updateAttachmentList', { attachmentListEntry, type })
				return Promise.resolve()
			}
		}
		store.commit(types.GET_CONVERSATION_ATTACHMENTS);
		return api.getConversationAttachments(groupId, type, store.state.conversationAttachmentsPage).then(
			(response) => {
				store.commit(types.GET_CONVERSATION_ATTACHMENTS_SUCCESS, { response, groupId, type });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.GET_CONVERSATION_ATTACHMENTS_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
	clearConversationAttachments: (store) => {
		store.commit(types.CLEAR_CONVERSATION_ATTACHMENTS);
	},
	getAttachmentPdf: (store, { attachment }) => {
		return api.getAttachmentPdf(attachment.path_in_filesystem + attachment.original_filename).then(
			(response) => {
				// store.commit(types.GET_CONVERSATION_ATTACHMENTS_SUCCESS, { response, groupId, type });
				return Promise.resolve(response);
			},
			(error) => {
				// store.commit(types.GET_CONVERSATION_ATTACHMENTS_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	}
};

export const getters = {
	conversationAttachments: ( state ) => {
		return state.conversationAttachments[0] ? state.conversationAttachments : null
	},
};

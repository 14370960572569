import * as api from '../../api'
import * as types from '../mutation-types'
import { errorMessage, splitMessage } from '@utils'
export const state = () => ({
	currentConversation: {},
	currentConversationId: null,
	conversations: [],
	contactConversations: [],
	conversationsPage: 1,
	contactConversationsPage: 1,
	conversationsLastPage: false,
	conversationsLoaded: false,
	contactConversationsLastPage: false,
	conversationsBusy: false,
	contactConversationsBusy: false,
	contactConversationSearchString: '',
	conversationSearchString: '',
	conversationFilter: '',
	contactConversationFilter: '',
	lastSelectedConversation: {},
	conversationFilterTypes: {
		is_group_conversation: 'group_conversations',
		has_attachment: 'has_attachment',
		has_attachment_in_last: 'last_message_has_attachment',
		is_private_conversation: 'private_conversation',
		has_unread_messages: 'unread_conversations',
		is_announcement: 'announcement',
		is_official: 'official',
		is_archived: 'archives',
	},
	uniqueSchoolIds: [],
	schoolLogos: [],
	schoolLogosBusy: false,
})

export const mutations = {
	[types.GET_CONVERSATIONS] (state) {
		if (state.conversationsPage == 1) {
			this._vm.trackGA4clickEvent('GET_CONVERSATIONS');
		}
		state.conversationsBusy = true
	},
	[types.GET_CONVERSATIONS_SUCCESS] (state, response) {
		state.conversationsLoaded = true
		state.conversationsBusy = false
		state.conversationsLastPage = response.data.data.next_page_url === null
		state.conversationsPage++
		// state.conversations = state.conversations.concat(response.data.data.data)
		response.data.data.data.forEach((responseItem) => {
			var found = false
			state.conversations.forEach((item, index) => {
				if (item.group.group_id == responseItem.group.group_id) {
					state.conversations[index] = responseItem
				}
			})
			if (!found) {
				state.conversations.push(responseItem)
			}
		})
		//get all school id's from conversations
		var schoolIds = []
		state.conversations.forEach((item) => {
			if (!item.group.is_private) {
				item.group.group_contact_keys.forEach((contact) => {
					if (contact.school_id) {
						schoolIds.push(contact.school_id)
					}
				})
			}
		})
		//remove duplicates from schoolIds array
		schoolIds = schoolIds.filter((v, i, a) => a.indexOf(v) === i)
		//check if state.uniqueSchoolIds doesnt contain any of the schoolIds
		schoolIds.forEach((schoolId) => {
			if (!state.uniqueSchoolIds.includes(schoolId)) {
				state.uniqueSchoolIds.push(schoolId)
			}
		})
	},
	[types.GET_CONVERSATIONS_FAIL] (state) {
		state.conversationsBusy = false
	},
	[types.GET_CONTACT_CONVERSATIONS] (state) {
		if (state.contactConversationsPage == 1) {
			// this._vm.$ga.sendEvent('USER_ACTION', 'GET_CONTACT_CONVERSATIONS')
			this._vm.trackGA4clickEvent('GET_CONTACT_CONVERSATIONS');
		}
		state.contactConversationsBusy = true
	},
	[types.GET_CONTACT_CONVERSATIONS_SUCCESS] (state, response) {
		state.contactConversationsBusy = false
		state.contactConversationsLastPage = state.contactConversationsPage >= response.data.data.last_page
		state.contactConversationsPage++
		state.contactConversations = state.contactConversations.concat(response.data.data.data)
	},
	[types.GET_CONTACT_CONVERSATIONS_FAIL] (state) {
		state.contactConversationsBusy = false
	},
	// todo, rename to simple
	[types.CLEAR_CONVERSATIONS] (state) {
		state.conversations = []
		state.conversationsPage = 1
		state.conversationsLastPage = false
	},
	[types.CLEAR_CONTACT_CONVERSATIONS] (state) {
		state.contactConversations = []
		state.contactConversationsPage = 1
		state.contactConversationsLastPage = false
	},
	[types.SET_CONVERSATION_SEARCH_STRING] (state, string) {
		state.conversationSearchString = string
	},

	[types.SET_CONVERSATION_FILTER] (state, filter) {
		state.conversationFilter = filter
	},
	updateConversationSearchString (state, value) {
		state.conversationSearchString = value
	},
	updateConversations (state, value) {
		state.conversations = state.conversations.map(conversation => {
			if (conversation.group_id === value.group_id) {
				conversation.draftMessageContent = splitMessage(value)
				conversation.draftMessagePolling = value.poll || null
			}
			return conversation
		})
	},
	updateContactConversationSearchString (state, value) {
		state.contactConversationSearchString = value
	},
	updateContactConversationFilter (state, value) {
		state.contactConversationFilter = value
	},
	updateConversationFilter (state, value) {
		state.conversationFilter = value
	},
	updateLastSelectedConversation (state, value) {
		state.lastSelectedConversation = value
	},
	updateCurrentConversation: (state, conversation) => {
		state.currentConversationId = conversation.group && conversation.group.group_id ? conversation.group.group_id : null
		state.currentConversation = conversation
	},
	overviewCountClear: (state) => {
		state.currentConversation.unread_count = 0
	},
	prependConversations: (state, conversation) => {
		state.conversations.unshift(conversation)
	},
	[types.GET_SCHOOL_LOGOS] (state) {
		state.schoolLogosBusy = true
	},
	[types.GET_SCHOOL_LOGOS_SUCCESS] (state, response) {
		state.schoolLogosBusy = false
		state.schoolLogos = response.body.data
	},
	[types.GET_SCHOOL_LOGOS_FAIL] (state) {
		state.schoolLogosBusy = false
	},
}

export const actions = {
	getConversations: (store) => {
		store.commit(types.GET_CONVERSATIONS)
		return api
			.getConversations(
				store.state.conversationsPage,
				store.state.conversationSearchString,
				store.state.conversationFilter,
				store.rootState.user.showEmptyConvo,
				false
			)
			.then(
				(response) => {
					store.commit(types.GET_CONVERSATIONS_SUCCESS, response)
					return Promise.resolve(response)
				},
				(error) => {
					store.commit(types.GET_CONVERSATIONS_FAIL, error)
					return Promise.reject(errorMessage(error))
				}
			)
			.catch(() => {
				// Handle promise errors caused by request abort
			})
	},
	getContactConversations: (store) => {
		store.commit(types.GET_CONTACT_CONVERSATIONS)
		return api
			.getConversations(
				store.state.contactConversationsPage,
				store.state.contactConversationSearchString,
				store.state.contactConversationFilter,
				true,
				true
			)
			.then(
				(response) => {
					store.commit(types.GET_CONTACT_CONVERSATIONS_SUCCESS, response)
					return Promise.resolve(response)
				},
				(error) => {
					store.commit(types.GET_CONTACT_CONVERSATIONS_FAIL, error)
					return Promise.reject(errorMessage(error))
				}
			)
	},
	//todo, remmake to mutations
	clearContactConversations: (store) => {
		store.commit(types.CLEAR_CONTACT_CONVERSATIONS)
	},
	clearConversations: (store) => {
		store.commit(types.CLEAR_CONVERSATIONS)
	},
	setConversationSearchString: (store, string) => {
		store.commit(types.SET_CONVERSATION_SEARCH_STRING, string)
	},
	setConversationFilter: (store, type) => {
		store.commit(types.SET_CONVERSATION_FILTER, type)
	},
	getSchoolLogos: (store) => {
		store.commit(types.GET_CONTACT_CONVERSATIONS)
		return api.getSchoolLogos(store.state.uniqueSchoolIds).then(
			(response) => {
				store.commit(types.GET_SCHOOL_LOGOS_SUCCESS, response)
				return Promise.resolve(response)
			},
			(error) => {
				store.commit(types.GET_SCHOOL_LOGOS_FAIL, error)
				return Promise.reject(errorMessage(error))
			}
		)
	}
}

export const getters = {
	conversations: (state) => {
		var editedConversations = state.conversations ? state.conversations : []
		editedConversations.forEach((element, index) => {
			if (editedConversations[index].last_message) {
				editedConversations[index].blocks = splitMessage(editedConversations[index].last_message)
			}
		})
		return editedConversations
	},
	contactConversations: (state) => {
		var editedConversations = state.contactConversations ? state.contactConversations : []
		editedConversations.forEach((element, index) => {
			if (editedConversations[index].last_message) {
				editedConversations[index].blocks = splitMessage(editedConversations[index].last_message)
			}
		})
		return editedConversations
	},
}

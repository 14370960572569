<template>
	<div :class="className">
		<div :class="{'position-relative': allowSchoolLogo}">
			<e-avatar
				:large="large"
				:small="small"
				:x-large="xLarge"
				:title="title"
				:description="description"
				:src="pictureLink"
				:letters="groupType === 'private' || pictureType === 'profile' ? textToShow : ''"
				:icon="computedIcon"
				:color="makeBackground()"
				
			/>
			<e-tooltip
				v-if="allowSchoolLogo && showSchoolLogo(groupData)"
				:disabled="$vuetify.breakpoint.mobile"
				right>
				<template #activator="{on}">	
					<img
						:src="(schoolLogoData && schoolLogoData.logo) ? schoolLogoData.logo : require('../../../img/default-school.svg')"
						alt="school logo"
						class="conversation-entry__school-logo"
						v-on="on"
						@error="handleSchoolLogoError">
				</template>
				{{ schoolLogoData ? schoolLogoData.name : '' }}
			</e-tooltip>
		</div>
	</div>
</template>

<script>
// import { HSVtoRGB } from '@utils'
import { HSVtoHEX } from '../../utils/utils'
import {
	overviewComputed,
} from '@storeHelper'
export default {
	name: "ImageHandler",
	props: {
		className: {
			type: String,
			required: false,
			default: "",
		},
		groupData: {
			type: Object,
			required: false,
			default: () => {},
		},
		userData: {
			type: Object,
			required: false,
			default: () => {},
		},
		pictureType: {
			type: String,
			required: true,
			default: "",
		},
		pictureLink: {
			type: String,
			required: true,
			default: "",
		},
		// size: {
		// 	type: String,
		// 	required: false,
		// 	default: "medium",
		// },
		title: String,
		description: String,
		large: Boolean,
		xLarge: Boolean,
		small: Boolean,
		darken: {
			type: Boolean,
			required: false,
			default: false,
		},
		allowSchoolLogo: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return{
			schoolLogoData: {},
		}
	},
	computed: {
		...overviewComputed,
		computedIcon () {
			return this.groupType === 'new_announcement' || this.groupType === 'official_announcement' ? 'icon-megaphone'
				: this.groupType === 'simple' ? 'icon-persons'
					: this.groupType === 'forever_alone' ? 'icon-smilling-face'
						: this.groupType === 'official' ? 'icon-institution'
							: this.groupType === 'campaign' ? 'icon-star' : ''
		},
		textToShow () {
			if(this.pictureType === 'group' && this.groupData.group.is_private) {
				let name = this.groupData.group.title.split(" ")
				return (name[0] ? name[0][0] : "") + (name[1] ? name[1][0] : "")
			}
			if(this.pictureType === 'profile'){
				if (!this.userData.firstname && !this.userData.lastname) {
					return ""
				}
				return (this.userData.firstname[0] ? this.userData.firstname[0] : "") + (this.userData.lastname[0] ? this.userData.lastname[0] : "")
			}
			return ""
		},
		groupType () {
			if(this.pictureType === 'campaign'){
				return 'campaign'
			}
			if(this.pictureType === 'group'){
				return this.groupData.group.is_private ? "private" : this.groupData.group.group_type
			}
			return ""
		},
		uniqueData () {
			return this.pictureType === 'group' ? this.groupData.group.group_id : (this.userData?.id ? this.userData.id : 12345)
		},
	},
	methods: {
		makeBackground () {
			let uniqueDataString = this.uniqueData.toString()
			if(uniqueDataString && uniqueDataString.length < 3){
				uniqueDataString = "123" + uniqueDataString
			}
			let lastInt = parseInt(uniqueDataString[uniqueDataString.length-1])
			let thirdLastInt = parseInt(uniqueDataString[uniqueDataString.length-2])
			let secondLastInt = parseInt(uniqueDataString[uniqueDataString.length-3])

			let selectedHue = parseFloat(((lastInt+1).toString() + (secondLastInt + 1).toString())) / 100
			let selectedSaturation = (( secondLastInt + 1) * 2) / 100
			let selectedValue = ((this.darken ? 93 : 88)+thirdLastInt) / 100

			// let selectedTextValue = (50+thirdLastInt + 1 * 2) / 100

			return HSVtoHEX(selectedHue * 100, selectedSaturation * 100 , selectedValue * 75)

			// let generatedColors = HSVtoRGB(selectedHue, selectedSaturation, selectedValue)
			// let generatedTextColors = HSVtoRGB(selectedHue, selectedSaturation, selectedTextValue)
			// return { background: `rgb(${generatedColors.r},${generatedColors.g},${generatedColors.b})`, color: `rgb(${generatedTextColors.r},${generatedTextColors.g},${generatedTextColors.b})` }
		},
		showSchoolLogo (groupData) {
			if(!groupData.group.is_private && groupData.group.group_contact_keys[0].school_id){
				let schoolId = groupData.group.group_contact_keys[0].school_id
				this.schoolLogoData = this.schoolLogos.find((el) => el.id === schoolId)
				return true
			}
			else{
				return false
			}
		},
		handleSchoolLogoError (e){
			e.target.src = require('../../../img/default-school.svg')
		}
	},
}
</script>

import Vue from 'vue'
import moment from 'moment'
import * as api from '../../api'
import * as types from '../mutation-types'
import { errorMessage, bytesToSize } from '@utils'
import i18n from '../../i18n'

export const state = () => ({
	newMessageContent: '',
	lastMessageContent: '',
	currentMessage	: {},
	lastUnreadMessage: {},
	files: [],
	messageAttachments: [],
	currentMessageUser: {},
	newMessagePolling: null,
	currentMessagePolling: null
})

export const mutations = {
	[types.CREATE_MESSAGE] (state, payload) {
		// state.conversationMessagesBusy = true
		// this._vm.$ga.sendEvent('USER_ACTION', 'CREATE_MESSAGE')
		this._vm.trackGA4clickEvent('CREATE_MESSAGE');
		const conversationObject = {
			loading: true,
			preloadId: payload.preloadId,
			message_id: 0,
			content_plain: payload.message,
			author_user_id: this.state.user.user.id,
			creation_date: moment().format(),
			group_id: payload.groupId,
			message_type: 'simple',
			master_message_id: null,
			deleted_by_sender: false,
			first_seen_any: null,
			is_deletable_for_all: false,
			is_important: payload.sendImportant,
			author: {
				id: this.state.user.user.id,
				firstname: this.state.user.user.firstname,
				lastname: this.state.user.user.lastname,
				profile_picture: this.state.user.user.profile_picture,
				locale: 'en',
				creation_date: moment().format(),
			},
			attachments: [],
			polling: payload.polling || null,
		}
		payload.files.forEach(function (element) {
			if (element.responseId) {
				const attachmentObject = {
					attachment_id: 0,
					original_filename: '',
					extension: '',
					message_id: 0,
					preload: true,
					fileBlob: '',
				}
				const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(?:[\w]+)$/gim
				const fileExtension = element.name.toLowerCase().match( fileExtensionPattern )
				attachmentObject.attachment_id = element.responseId
				attachmentObject.original_filename = element.name
				attachmentObject.extension = fileExtension ? fileExtension[0] : 'txt'
				attachmentObject.fileBlob = URL.createObjectURL(element.file)
				conversationObject.attachments.push(attachmentObject)
			}
		})
		if (this.state.overview.currentConversationId == payload.groupId) {
			this.state.messageList.conversationMessages.push(conversationObject)
		}
	},
	[types.CREATE_MESSAGE_SUCCESS] (state, payload) {
		// state.conversationMessagesBusy = false
		const newMessage = payload.socket ? payload.response.data : payload.response.data.data
		let found = false
		const that = this
		let conversationFound = false
		let preloadFound = false
		const groupId = newMessage.group_id

		function updateFirstSeenA () {
			if (that.state.general.windowHasFocus && newMessage.message_id != that.state.messageOptions.lastReadMessage) {
				if(newMessage.message_id < 0) return
				that.commit('messageOptions/updateFirstSeenLauncher', newMessage.message_id)
			}
		}
		if (newMessage.group_id == this.state.overview.currentConversationId) {
			this.state.messageList.conversationMessages.forEach((item, index) => {
				if (item.preloadId) {
					preloadFound = true
				}
				if (item.message_id == newMessage.message_id) {
					found = true
				}
				if (!found && payload.preloadId && item.preloadId && item.preloadId == payload.preloadId) {
					// message.splice(index, 1)
					Vue.set(this.state.messageList.conversationMessages, index, newMessage)
					// //Check if message is not already present in case ws is first
					// if(message.filter(searchMessage => (searchMessage.message_id === newMessage.message_id)).length == 0){
					// 	message.unshift(newMessage)
					// }
					found = true
				}
			})

			if (!found) {
				if (payload.socket) {
					if (newMessage.author && newMessage.author.id != this.state.user.user.id) {
						setTimeout(updateFirstSeenA, 2000)
					}
				}
				if ((payload.socket && !preloadFound) || !payload.socket) {
					this.state.messageList.conversationMessages.push(newMessage)
				}
			}
			// TODO: check it after fix for newMessage websocket
			// if (found) {
			// 	this.state.messageList.conversationMessages = this.state.messageList.conversationMessages.map(item => {
			// 		if (item.message_id === newMessage.message_id) {
			// 			return newMessage
			// 		}
			// 		return item
			// 	})
			// }
		}

		this.state.overview.conversations.forEach((item, index, conversations) => {
			if (item.group.group_id == newMessage.group_id) {
				conversationFound = true
				Vue.set(this.state.overview.conversations[index], 'last_message', '')
				Vue.set(this.state.overview.conversations[index], 'last_message', newMessage)
				conversations.splice(index, 1)
				conversations.unshift(item)
				if (
					newMessage.author.id != this.state.user.user.id &&
					(newMessage.group_id != this.state.overview.currentConversationId ||
						!this.state.general.windowHasFocus)
				) {
					item.unread_count++
				}
			}
		})

		if (!conversationFound) {
			this.dispatch('conversationOptions/getConversationDetails', { groupId })
		}

		if (this.state.overview.currentConversationId == newMessage.group_id && payload.socket) {
			this.state.socket.socketOnMessageFunction()
		}
		this.commit('messageList/updateConversationMessage', { message: newMessage, groupId, action:'create' })
		if(newMessage.attachments && newMessage.attachments.length > 0){
			this.commit('attachments/updateAttachments', newMessage)
		}
	},
	[types.CREATE_MESSAGE_FAIL] (state, payload) {
		this.state.messageList.conversationMessages.forEach((item, index) => {
			if (payload.preloadId && item.preloadId && item.preloadId == payload.preloadId) {
				const tempMsg = { ...item }
				tempMsg.loading = false
				tempMsg.error = errorMessage(payload.error)
				Vue.set(this.state.messageList.conversationMessages, index, tempMsg)
			}
		})
	},
	[types.FILE_UPLOAD] (state, { uploadedFile, extension }) {
		state.files.forEach(function (file) {
			if (file.id == uploadedFile.id) {
				file.extension = extension
				if (!file.success && !file.loading) {
					file.loading = true
					file.size = bytesToSize(file.file.size)
					file.blob = URL.createObjectURL(file.file)
				}
			}
		})
	},
	[types.FILE_UPLOAD_SUCCESS] (state, payload) {
		const fileId = payload.file.id
		state.files.forEach(function (file) {
			if (file.id == fileId) {
				file.loading = false
				file.success = true
				file.responseId = payload.response.data.data.attachment_id
			}
		})
	},
	[types.FILE_UPLOAD_FAIL] (state, payload) {
		const fileId = payload.file.id
		const extensionRegex = /(?:\.([^.]+))?$/
		const extension = extensionRegex.exec( payload.file.name )
		state.files.forEach(function (file) {
			if (file.id == fileId) {
				file.loading = false
				file.success = false
				file.progress = 100
				file.extension = extension ? extension[1] : "misc"
				if (payload.manual) {
					file.error = payload.error
				} else {
					file.error =
						payload.error.data && payload.error.data.message
							? payload.error.data.message
							: i18n.t('server_error_occured')
				}
			}
		})
	},
	[types.FILE_UPLOAD_UPDATE] (state, payload) {
		const fileId = payload.file.id
		const progress = payload.progress
		state.files.forEach(function (file) {
			if (file.id == fileId) {
				file.progress = progress
			}
		})
	},
	[types.CLEAR_FILES] (state) {
		state.files = []
	},
	[types.CLEAR_FILE] (state, file) {
		state.files.forEach(function (item, index, element) {
			if (item.id == file.id) {
				element.splice(index, 1)
			}
		})
	},
	setUploadedFiles (state, string) {
		state.files = string
	},
	setMessageAttachments (state, attachments) {
		state.messageAttachments = attachments
	},
	updateNewMessageContent (state, value) {
		state.newMessageContent = value
	},
	updateLastMessageContent (state, value) {
		state.lastMessageContent = value
	},
	updateCurrentMessage (state, value) {
		state.currentMessage = value
	},
	updateNewMessagePolling (state, value) {
		state.newMessagePolling = value
	},
	updateCurrentMessagePolling (state, value) {
		state.currentMessagePolling = value
	},
	resendMessagePrep (state, payload) {
		this.state.messageList.conversationMessages.forEach((item, index) => {
			if (payload.preloadId && item.preloadId && item.preloadId == payload.preloadId) {
				const tempMsg = { ...item }
				tempMsg.loading = true
				Vue.set(this.state.messageList.conversationMessages, index, tempMsg)
			}
		})
	},
	updateLastUnreadMessage (state, value) {
		state.lastUnreadMessage = value
	},
	updateCurrentMessageUser (state, value) {
		state.currentMessageUser = value
	},
}

export const actions = {
	createMessage: (store, { groupId, message, files, polling, sendImportant }) => {
		const preloadId = String( Math.floor( Math.random() * (0 - 99999999 + 1) ) ) + moment().format( 'x' )
		const fileIds = []
		files.forEach((element) => {
			if (element.responseId) {
				fileIds.push(element.responseId)
			}
		})
		store.commit(types.CREATE_MESSAGE, {
			groupId,
			message,
			preloadId,
			files,
			sendImportant,
			polling
		})
		return Promise.resolve()
			.then(() => api.createMessage(groupId, message, fileIds, false, sendImportant, polling))
			.then((response) => {
				store.commit(types.CREATE_MESSAGE_SUCCESS, {
					response,
					socket: false,
					preloadId,
				})
				return Promise.resolve(response)
			}, (error) => {
				store.commit(types.CREATE_MESSAGE_FAIL, {
					error,
					preloadId,
				})
				return Promise.reject(errorMessage(error))
			})
	},
	resendMessage: (store, { groupId, message, files, polling, preloadId, sendImportant }) => {
		store.commit('resendMessagePrep', { preloadId })
		return api.createMessage(groupId, message, files, false, sendImportant, polling).then(
			(response) => {
				store.commit(types.CREATE_MESSAGE_SUCCESS, {
					response,
					socket: false,
					preloadId,
				})
				return Promise.resolve(response)
			},
			(error) => {
				store.commit(types.CREATE_MESSAGE_FAIL, {
					error,
					preloadId,
				})
				return Promise.reject(errorMessage(error))
			}
		)
	},
	fileUpload: (store, { file, extension, schoolId }) => {
		store.commit(types.FILE_UPLOAD, { uploadedFile:file, extension })
		return api
			.fileUpload(file.file, schoolId, (progress) => {
				store.commit(types.FILE_UPLOAD_UPDATE, { file, progress })
			})
			.then(
				(response) => {
					store.commit(types.FILE_UPLOAD_SUCCESS, { response, file })
					return Promise.resolve(response)
				},
				(error) => {
					store.commit(types.FILE_UPLOAD_FAIL, { error, file })
					return Promise.reject(errorMessage(error))
				}
			)
	},
	clearFiles: (store) => {
		store.commit(types.CLEAR_FILES)
	},
	clearFile: (store, file) => {
		store.commit(types.CLEAR_FILE, file)
	},
}

export const getters = {}

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import { config } from '@k12tech/ekool-vue-framework/src/main'
import colors from '@k12tech/ekool-vue-framework/src/scss/presets/colors.module.scss'
import { VuetifyLocales } from '@k12tech/ekool-vue-framework/src/plugins/vuetify'

Vue.use(Vuetify)

const vuetify = new Vuetify({
	lang: {
		locales: VuetifyLocales,
	},
	theme: {
		themes: {
			light: colors,
			dark: colors,
		},
		dark:
			localStorage.getItem('active-theme') === null
				? false
				: localStorage.getItem('active-theme') === 'dark',
		options: {
			customProperties: true,
			variations: false,
		},
	},
	breakpoint: {
		mobileBreakpoint: 'sm',
		thresholds: {
			xs: 576,
			sm: 769,
			md: 992,
			lg: 1200,
			xl: 1600,
		},
	}
})

export default vuetify

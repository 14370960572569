import * as api from '../../api'
import { errorMessage } from '@utils'
import moment from 'moment'
// All of this wierd logic ir partially because there is no reactions list but just amount you have to shift around
export const state = () => ({
	createMessageReactionBusy: false,
	deleteMessageReactionBusy: false,
	getMessageReactionsBusy: false,
	messageReactions: [],
	messageReactionsAddTempQueue: [],
	messageReactionsRemoveTempQueue: [],
	busyReactions: {},
})

export const mutations = {
	createMessageReaction (state, { reaction, messageId, preloadId }) {
		// this._vm.$ga.sendEvent('REACTION', 'ADD_REACTION', reaction)
		this._vm.trackGA4clickEvent('ADD_REACTION', { reaction });
		state.createMessageReactionBusy = true
		state.messageReactionsAddTempQueue.push(preloadId)
		let message = this.state.messageList.conversationMessages.filter((message) => message.message_id === messageId)[0]
		let simpleReaction = () => {
			message.reactions.push({ reaction, amount: 1, my_reaction: true, process_id: preloadId, busy: true })
		}
		if (message) {
			this.commit('reaction/updateBusyReactions', { reactionId: reaction, messageId: message.message_id, preloadId, busy: true });
			if (message.reactions.length === 0) {
				simpleReaction()
			} else {
				let foundReactionOwn = message.reactions.filter(reactionEntry => reactionEntry.my_reaction === true)[0]
				if (foundReactionOwn) {
					foundReactionOwn.amount--
					foundReactionOwn.my_reaction = false
					if (foundReactionOwn.amount === 0) {
						message.reactions.splice(message.reactions.findIndex(reactionEntry => reactionEntry.reaction === foundReactionOwn.reaction), 1)
					}
				}
				let foundReaction = message.reactions.filter(reactionEntry => reactionEntry.reaction === reaction)[0]
				if (foundReaction) {
					foundReaction.process_id = preloadId,
					foundReaction.amount++
					foundReaction.my_reaction = true
					foundReaction.busy = true
				} else {
					simpleReaction()
				}
			}
		}
	},
	createMessageReactionSuccess (state, { response, socket, preloadId }) {
		state.createMessageReactionBusy = false
		let reaction = socket ? response.data : response.data.data
		let message = {}
		let processId = preloadId ? preloadId : reaction.new.process_id

		if (this.state.overview.currentConversation.group_id !== reaction.new.group_id ) {
			let foundSavedConversation = this.state.messageList.conversationsMessagesList.find(conversation => conversation.groupId == reaction.new.group_id)
			// 	return if we haven't opened conversation yet
			if (!foundSavedConversation) return
			message = foundSavedConversation.messages.filter((message) => message?.message_id === reaction.new?.message_id)[0]
		} else {
			message = this.state.messageList.conversationMessages.filter((message) => message?.message_id === reaction.new?.message_id)[0]
		}
		this.commit('reaction/updateBusyReactions', { reactionId: reaction.new.reaction, messageId: message?.message_id, preloadId: processId, busy:false });

		// Check if there is preloadID, if so just return(need this to have instant feedback)
		if (state.messageReactionsAddTempQueue.filter(reactionEntryId => reactionEntryId === processId).length > 0) {
			// Remove preload from message so user can delete reaction if needed
			let foundReaction = message.reactions.filter(reactionEntry => reactionEntry.process_id === preloadId)[0]
			if (foundReaction) {
				delete foundReaction.busy
				// some wierd update bug, wont update unless doing this, might change to vue set later
				foundReaction.amount++
				foundReaction.amount--
			}
			return
		}

		let simpleReaction = () => {
			message.reactions.push({
				reaction: reaction.new.reaction,
				amount: 1,
				my_reaction: this.state.user.user.id === reaction.new.user_id,
			})
		}
		//remove old first
		if (reaction.old) {
			this.commit('reaction/deleteMessageReactionSuccess', {
				reactionId: reaction.old.reaction,
				authorId: reaction.old.user_id,
				messageId: reaction.old.message_id,
				groupId: reaction.old.group_id,
				processId: reaction.old.process_id,
				socket,
				preload:false,
			});
		}

		if (message) {
			if (message.reactions.length === 0) {
				simpleReaction()
			} else {
				let foundReaction = message.reactions.filter(reactionEntry => reactionEntry.reaction === reaction.new.reaction)[0]
				if (foundReaction) {
					foundReaction.amount++
					foundReaction.my_reaction = foundReaction.my_reaction || this.state.user.user.id === reaction.new.user_id
				} else {
					simpleReaction()
				}
			}
		}

	},
	createMessageReactionError (state) {
		state.createMessageReactionBusy = false
	},
	deleteMessageReaction (state, { reaction }) {
		state.deleteMessageReactionBusy = true
		// this._vm.$ga.sendEvent('REACTION', 'REMOVE_REACTION', reaction)
		this._vm.trackGA4clickEvent('REMOVE_REACTION', { reaction });
	},
	deleteMessageReactionSuccess (state, { reactionId, authorId, messageId, groupId, processId, preload }) {
		state.deleteMessageReactionBusy = false
		let message = {}
		if (this.state.overview.currentConversation.group_id !== groupId) {
			let foundSavedConversation = this.state.messageList.conversationsMessagesList.find(conversation => conversation.groupId == groupId)
			// 	return if we haven't opened conversation yet
			if (!foundSavedConversation) return
			message = foundSavedConversation.messages.filter((message) => message.message_id === messageId)[0]
		} else {
			message = this.state.messageList.conversationMessages.filter((message) => message.message_id === messageId)[0]
		}
		this.commit('reaction/updateBusyReactions', { reactionId, messageId: message.message_id, preloadId: processId, busy: preload });

		if (state.messageReactionsRemoveTempQueue.filter(reactionEntry => reactionEntry === processId).length > 0) {
			return
		} else {
			state.messageReactionsRemoveTempQueue.push(processId)
		}
		if (message) {
			let foundReaction = message.reactions.filter(reactionEntry => reactionEntry.reaction === reactionId)[0]
			if (foundReaction) {
				if (foundReaction.amount === 1) {
					message.reactions.splice(message.reactions.findIndex(reactionEntry => reactionEntry.reaction === reactionId), 1)
				} else {
					if (foundReaction.my_reaction && authorId == this.state.user.user.id) {
						foundReaction.my_reaction = false
					}
					foundReaction.amount--
				}
			}
		}
	},
	deleteMessageReactionError (state) {
		state.deleteMessageReactionBusy = false
	},
	getMessageReactions (state) {
		state.getMessageReactionsBusy = true
		state.messageReactions = {}
	},
	getMessageReactionsSuccess (state, { response }) {
		state.getMessageReactionsBusy = false
		state.messageReactions = response.data.data
		if (Object.keys(state.messageReactions).length > 1) {
			this.state.interface.currentReactionsModalTab = 'all'
			let allReactions = []
			Object.keys(state.messageReactions).forEach((key) => {
				allReactions.push(...state.messageReactions[key])
			})
			state.messageReactions['all'] = allReactions
		} else {
			this.state.interface.currentReactionsModalTab = Object.keys(state.messageReactions)[0]
		}

	},
	getMessageReactionsError (state) {
		state.getMessageReactionsBusy = false
	},
	updateBusyReactions (state, { reactionId, messageId, preloadId, busy }) {
		if (state.busyReactions[messageId]) {
			if (state.busyReactions[messageId][reactionId]) {
				let foundIndex = state.busyReactions[messageId][reactionId].findIndex(entry => entry.preloadId == preloadId)
				if (foundIndex !== -1) {
					state.busyReactions[messageId][reactionId][foundIndex].busy = busy
				} else {
					state.busyReactions[messageId][reactionId].push({ preloadId, busy })
					return
				}
			} else {
				state.busyReactions[messageId][reactionId] = []
				this.commit('reaction/updateBusyReactions', { reactionId, messageId, preloadId, busy });
				return
			}
		} else {
			state.busyReactions[messageId] = {}
			this.commit('reaction/updateBusyReactions', { reactionId, messageId, preloadId, busy });
			return
		}
	},
}

export const actions = {
	createMessageReaction: (store, reaction) => {
		var preloadId = String(Math.floor(Math.random() * (0 - 99999999 + 1))) + moment().format('x')
		let messageId = store.rootState.message.currentMessage.message_id
		store.commit('createMessageReaction', { reaction, messageId, preloadId })
		return api.createMessageReaction(messageId, reaction, preloadId).then(
			(response) => {
				store.commit('createMessageReactionSuccess', { response, preloadId })
				return Promise.resolve(response)
			},
			(error) => {
				store.commit('createMessageReactionError')
				return Promise.reject(errorMessage(error))
			}
		)
	},
	deleteMessageReaction: (store, reaction) => {
		var processId = String(Math.floor(Math.random() * (0 - 99999999 + 1))) + moment().format('x')
		let messageId = store.rootState.message.currentMessage.message_id
		store.commit('deleteMessageReaction', { reaction })
		store.commit('deleteMessageReactionSuccess', {
			reactionId: reaction,
			authorId: store.rootState.user.user.id,
			groupId: store.rootState.overview.currentConversation.group_id,
			messageId,
			processId,
			preload: true,
		})
		return api.deleteMessageReaction(messageId, reaction, processId).then(
			(response) => {
				store.commit('deleteMessageReactionSuccess',
					{
						reactionId: reaction,
						authorId: store.rootState.user.user.id,
						groupId: store.rootState.overview.currentConversation.group_id,
						messageId,
						processId,
						preload: false,
					}
				)
				return Promise.resolve(response)
			},
			(error) => {
				store.commit('deleteMessageReactionError')
				return Promise.reject(errorMessage(error))
			}
		)
	},
	getMessageReactions: (store) => {
		store.commit('getMessageReactions')
		let messageId = store.rootState.message.currentMessage.message_id
		return api.getMessageReactions(messageId).then(
			(response) => {
				store.commit('getMessageReactionsSuccess',{ response }
				)
				return Promise.resolve(response)
			},
			(error) => {
				store.commit('getMessageReactionsError')
				return Promise.reject(errorMessage(error))
			}
		)
	},
}

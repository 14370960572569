import * as types from '../mutation-types'
import VueNativeSock from 'vue-native-websocket'
import Vue from 'vue'
import { getEnv } from '@env'

export const state = () => ({
	socket: {
		message: '',
		isConnected: false,
		reconnectError: false,
		reconnecting: false,
	},

	socketController: '',
	socketErrorFunction: '',
	socketOnMessageFunction: '',

	socketClosing: true,
	pingLoop: null,
})

export const mutations = {
	[types.SET_SOCKET_FUNCTIONS] (state, socketFunctions) {
		state.socketErrorFunction = socketFunctions.onError
		state.socketOnMessageFunction = socketFunctions.onMessage
	},
	SOCKET_ONOPEN (state) {
		state.socket.isConnected = true
		var authToken = null
		if (window.messaging.token && window.messaging.token.base) {
			authToken = window.messaging.token.base
			if(window.messaging.token.type === 2) {
				authToken = "Bearer " + authToken
			}
		}
		if (state.socket.isConnected) {
			state.socketController.sendObj({
				api: 'register',
				user_id: this.state.user.user.id,
				user_name: this.state.user.user.firstname,
				user_surname: this.state.user.user.lastname,
				['access_token' + (window.messaging.token && window.messaging.token.type === 2 ? "2" : "")]: authToken,
			})
		}
		function pingLooper () {
			if (state.socket.isConnected) {
				state.socketController.sendObj({ api: 'ping' })
			}
		}
		// eslint-disable-next-line no-console
		console.log("WS registering", state.socketController.readyState)

		state.socket.reconnecting = false
		state.pingLoop = setInterval(pingLooper, 70000)
	},
	SOCKET_ONCLOSE (state) {
		// eslint-disable-next-line no-console
		console.log("WS Closed")
		clearInterval(state.pingLoop)
	},
	SOCKET_ONERROR (state) {
		state.socket.isConnected = false
		// if(!state.socket.reconnecting){
		//  state.socketErrorFunction()
		// }
	},
	SOCKET_ONMESSAGE (state, message) {
		state.socket.message = message
	},
	SOCKET_RECONNECT () {
		// this.commit('socket/registerSocket')
	},
	SOCKET_RECONNECT_ERROR (state) {
		state.socket.reconnectError = true
	},
	registerSocket (state) {
		Vue.use(VueNativeSock, getEnv('VUE_APP_FRONTEND_WEBSOCKET_CONNECTION'), {
			connectManually: false,
			store: this,
			format: 'json',
			reconnection: true,
			reconnectionAttempts: 20,
			reconnectionDelay: 5000,
			passToStoreHandler: function (eventName, event) {
				if (!eventName.startsWith('SOCKET_')) {
					return
				}
				let method = 'commit'
				let target = eventName.toUpperCase()
				let msg = event
				if (this.format === 'json' && event.data) {
					msg = JSON.parse(event.data)
					if (msg.mutation) {
						target = [ msg.namespace || '', msg.mutation ].filter((e) => !!e).join('/')
					} else if (msg.action) {
						method = 'dispatch'
						target = [ msg.namespace || '', msg.action ].filter((e) => !!e).join('/')
					}
				}
				this.store[method]('socket/' + target, msg)
			},
		})
		// eslint-disable-next-line no-console
		console.log("WS opening")
		// this._vm.$connect()
		state.socketController = this._vm.$socket
	},
	resetSocket () {
		console.log("WS reseting")
		state.socket.isConnected = false
		//state.socketController.close()
	},
}

export const actions = {
	newMessage: (store, data) => {
		store.commit('message/' + types.CREATE_MESSAGE_SUCCESS, { response: data, socket: true }, { root: true })
	},
	addPoll: (store, data) => {
		store.commit('message/' + types.CREATE_MESSAGE_SUCCESS, { response: data, socket: true }, { root: true })
	},
	votePoll: (store, data) => {
		store.commit('messageOptions/votePoll', { response: data }, { root: true })
	},
	messageEdit: (store, data) => {
		store.commit('messageOptions/' + types.EDIT_MESSAGE_SUCCESS, { response: data }, { root: true })
	},
	messageRead: (store, data) => {
		store.commit('messageOptions/' + types.UPDATE_MESSAGE_READ, data, { root: true })
	},
	onlineStatus: (store, data) => {
		store.commit('members/' + types.GET_MEMBER_STATUS_SUCCESS, data, { root: true })
	},
	messageDeleted: (store, data) => {
		store.commit(
			'messageOptions/' + types.DELETE_MESSAGE_SUCCESS,
			{
				messageId: data.data.message_id,
				groupId: data.data.group_id,
			},
			{ root: true }
		)
	},
	memberRemoved: (store, data) => {
		store.commit(
			'members/' + types.REMOVE_MEMBER_SUCCESS,
			{
				groupId: data.data.group_id,
				userId: data.data.user.user_id,
			},
			{ root: true }
		)
	},
	memberAdded: (store, data) => {
		store.commit(
			'contacts/' + types.ADD_CONVERSATION_MEMBERS_SUCCESS,
			{
				groupId: data.data.group_id,
				data: data.data.users,
				socket: true,
			},
			{ root: true }
		)
	},
	memberAccessChanged: (store, data) => {
		store.commit(
			'members/' + types.CHANGE_USER_PERMISSIONS_SUCCESS,
			{
				data: data.data,
				socket: true,
			},
			{ root: true }
		)
	},
	conversationUpdated: (store, data) => {
		var response = { data, socket: true }
		store.commit('conversationOptions/' + types.CHANGE_CONVERSATION_DETAILS, response, { root: true })
	},
	setReplies: (store, data) => {
		var response = { replies: data.data.group.group_type, groupId: data.data.group.group_id, socket: true }
		store.commit('conversationOptions/' + types.TOGGLE_REPLIES_SUCCESS, response, { root: true })
	},
	conversationDeleted: (store, data) => {
		var response = { groupId: data.data.group_id, socket: true }
		store.commit('conversationOptions/' + types.DELETE_CONVERSATION_PERM_SUCCESS, response, { root: true })
	},
	addReaction: (store, data) => {
		var response = { response:data, socket: true }
		store.commit('reaction/createMessageReactionSuccess', response, { root: true })
	},
	deleteReaction: (store, data) => {
		var response = {
			reactionId: data.data.reaction,
			authorId: data.data.user_id,
			messageId: data.data.message_id,
			groupId: data.data.group_id,
			processId: data.data.process_id,
			socket: true }
		store.commit('reaction/deleteMessageReactionSuccess', response, { root: true })
	},
	readAll: (store, data) => {
		store.commit('user/readAllMessagesSuccess', data, { root: true })
	},
	readLastMessage: (store, onlyClear) => {
		if (
			store.rootState.overview.currentConversation &&
			store.rootState.overview.currentConversation.group &&
			store.rootState.messageList.conversationMessages.length > 0 &&
			store.rootState.user.user
		) {

			store.commit('overview/overviewCountClear', null, { root: true })
			// take first filtered message cause backend sends messages from newest to oldest
			if(!onlyClear){
				var filteredMessages = store.rootState.messageList.conversationMessages.filter(
					(message) => message.author && message.author.id !== store.rootState.user.user.id
				)

				var lastMessage = filteredMessages.length > 0 ? filteredMessages[filteredMessages.length-1] : null
				if(lastMessage && !lastMessage.first_seen_by_me && lastMessage.message_id != store.rootState.messageOptions.lastReadMessage){
					store.commit('messageOptions/updateFirstSeenLauncher', lastMessage.message_id, { root: true })

				}
			}
		}
	},
	setSocketFunctions: (store, socketFunctions) => {
		store.commit(types.SET_SOCKET_FUNCTIONS, socketFunctions)
	},
}

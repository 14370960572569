import * as api from '../../api'
import * as types from '../mutation-types'
import { errorMessage } from '@utils'

export const state = () => ({
	campaign: {},
	campaignContent: [],
})

export const mutations = {
	[types.GET_CAMPAIGN] () {},
	[types.GET_CAMPAIGN_SUCCESS] (state, response) {
		state.campaign = response.data.data
	},
	[types.GET_CAMPAIGN_FAIL] () {},
	[types.DELETE_GET_CAMPAIGN] () {
		// this._vm.$ga.sendEvent('USER_ACTION', 'DELETE_GET_CAMPAIGN', '')
		this._vm.trackGA4clickEvent('DELETE_GET_CAMPAIGN')
		this.state.interface.campaignOpen = false
	},
	[types.DELETE_GET_CAMPAIGN_SUCCESS] (state) {
		this.state.interface.campaignOpen = false
		state.campaign = {}
		state.campaignContent = []
	},
	[types.DELETE_GET_CAMPAIGN_FAIL] (state) {
		this.state.interface.campaignOpen = false
		state.campaign = {}
		state.campaignContent = []
	},
}

export const actions = {
	getCampaign: (store) => {
		store.commit(types.GET_CAMPAIGN)
		return api.getCampaign().then(
			(response) => {
				store.commit(types.GET_CAMPAIGN_SUCCESS, response)
				return Promise.resolve(response)
			},
			(error) => {
				store.commit(types.GET_CAMPAIGN_FAIL, error)
				return Promise.reject(errorMessage(error))
			}
		)
	},
	deleteCampaign: (store, campaignId) => {
		store.commit(types.DELETE_GET_CAMPAIGN)
		return api.deleteCampaign(campaignId).then(
			(response) => {
				store.commit(types.DELETE_GET_CAMPAIGN_SUCCESS, response)
				return Promise.resolve(response)
			},
			(error) => {
				store.commit(types.DELETE_GET_CAMPAIGN_FAIL, error)
				return Promise.reject(errorMessage(error))
			}
		)
	},
}

export const getters = {
	campaignContent: (state) => {
		if (state.campaign.contents) {
			const sortedBlocks = []
			const magicUrlRegex = /((?:(?:https?:\/\/))[^\s]+)/g
			const spanBlocks = state.campaign.contents.split( magicUrlRegex )

			spanBlocks.forEach((block) => {
				let spanBlock
				if (magicUrlRegex.test(block)) {
					spanBlock = {
						type: 'url',
						content: block,
					}
				} else {
					spanBlock = {
						type: 'simple',
						content: block,
					}
				}
				if (spanBlock.content != '') {
					let markRemoved = false
					const lastChar = spanBlock.content[spanBlock.content.length - 1]
					const marks = '.?,!(){}[];\':*'
					if (marks.includes(lastChar) && spanBlock.type == 'url') {
						spanBlock.content = spanBlock.content.slice(0, -1)
						markRemoved = true
					}

					sortedBlocks.push(spanBlock)
					if (markRemoved) {
						sortedBlocks.push({
							type: 'simple',
							content: lastChar,
						})
					}
				}
			})
			return sortedBlocks
		}
	},
}

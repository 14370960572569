import Vue from 'vue';
import moment from 'moment';
import * as api from '../../api';
import * as types from '../mutation-types';
import { errorMessage } from '@utils';

export const state = () => ({
	conversationMembers: [],
	conversationMembersSearch: [],
	conversationMembersPage: 1,
	conversationMemberSearchPage: 1,
	conversationMembersLastPage: false,
	conversationMemberSearchLastPage: false,
	conversationMembersBusy: false,
	conversationMemberSearchBusy: false,
	conversationMembersBusyOverlay: false,
	conversationMemberSearchString: '',
	savedConversationMembers: [],
})

export const mutations = {
	[types.GET_CONVERSATION_MEMBERS] (state) {
		if (state.conversationMembersPage == 1) {
			// this._vm.$ga.sendEvent('USER_ACTION', 'GET_CONVERSATION_MEMBERS');
			this._vm.trackGA4clickEvent('GET_CONVERSATION_MEMBERS');
		}
		state.conversationMembersBusy = true;
	},
	[types.GET_CONVERSATION_MEMBERS_SUCCESS] (state, { response, groupId }) {
		state.conversationMembersBusy = false;
		state.conversationMembers = state.conversationMembers.concat(response.data.data.data);
		state.conversationMembersLastPage = response.data.data.next_page_url === null;
		state.conversationMembersPage++;
		this.commit('members/updateMemberLists', { response, groupId });
	},
	[types.GET_CONVERSATION_MEMBERS_FAIL] (state) {
		state.conversationMembersBusy = false;
	},
	[types.GET_CONVERSATION_MEMBERS_SEARCH] (state) {
		if (state.conversationMembersPage == 1) {
			// this._vm.$ga.sendEvent('USER_ACTION', 'GET_CONVERSATION_MEMBERS_SEARCH');
			this._vm.trackGA4clickEvent('GET_CONVERSATION_MEMBERS_SEARCH');
		}
		state.conversationMemberSearchBusy = true;
	},
	[types.GET_CONVERSATION_MEMBERS_SEARCH_SUCCESS] (state, response) {
		state.conversationMemberSearchBusy = false;
		state.conversationMembersSearch = state.conversationMembersSearch.concat(response.data.data.data);
		state.conversationMemberSearchLastPage = response.data.data.next_page_url === null;
		state.conversationMemberSearchPage++;
	},
	[types.GET_CONVERSATION_MEMBERS_SEARCH_FAIL] (state) {
		state.conversationMemberSearchBusy = false;
	},
	[types.CLEAR_CONVERSATION_MEMBERS] (state) {
		state.conversationMembers = [];
		state.conversationMembersPage = 1;
		state.conversationMembersLastPage = false;
	},
	[types.CHANGE_USER_PERMISSIONS] (state) {
		state.conversationMembersBusyOverlay = true;
	},
	[types.CHANGE_USER_PERMISSIONS_SUCCESS] (state, payload) {
		state.conversationMembersBusyOverlay = false;
		var isSocket = payload.socket
		var userId = isSocket ? payload.data.user.user_id : payload.userId
		var groupId = isSocket ? payload.data.group_id : payload.groupId
		var permissionLevel = isSocket ? payload.data.user.permission_level_id : payload.response.data.data.permission_level_id
		var user = isSocket ? payload.data.user.user : payload.response.data.data.user
		var userObject = isSocket ? payload.data.user : payload.response.data.data.user

		//next thing Todo
		// this._vm.$ga.sendEvent('USER_ACTION', 'CHANGE_USER_PERMISSIONS', permissionLevel);
		this._vm.trackGA4clickEvent('CHANGE_USER_PERMISSIONS', { level: permissionLevel });
		this.state.overview.conversations.forEach(function (conversationItem) {
			if (conversationItem.group.group_id == groupId) {
				var foundAdmin = false;
				conversationItem.group.admin.forEach(function (adminItem, adminIndex, adminElement) {
					if (adminItem.id == userId) {
						foundAdmin = true;
						if (permissionLevel == 2) {
							adminElement.splice(adminIndex, 1);
						}
					}
				});
				if (!foundAdmin && permissionLevel == 1) {
					conversationItem.group.admin.push(user);
				}
			}
		});

		this.commit('members/editMemberList', { groupId, users: [userObject], userId: user.id, action: 'edit' });
	},
	[types.CHANGE_USER_PERMISSIONS_FAIL] (state) {
		state.conversationMembersBusyOverlay = false;
	},
	[types.REMOVE_MEMBER] (state) {
		// this._vm.$ga.sendEvent('USER_ACTION', 'REMOVE_MEMBER');
		this._vm.trackGA4clickEvent('REMOVE_MEMBER');
		state.conversationMembersBusyOverlay = true;
	},
	[types.REMOVE_MEMBER_SUCCESS] (state, payload) {
		state.conversationMembersBusyOverlay = false;
		var userId = payload.userId;
		state.conversationMembers.forEach( (item, index, element) => {
			if (item.user.id == userId) {
				this.state.overview.currentConversation.member_count -= 1;
				element.splice(index, 1);
			}
		});
		state.conversationMembersSearch.forEach( (item, index, element) => {
			if (item.user.id == userId) {
				this.state.overview.currentConversation.member_count -= 1;
				element.splice(index, 1);
			}
		});
		var that = this;
		const currentUser = this.state.user.user
		if (currentUser.id === userId) {
			this.state.overview.conversations.forEach(function (item, index) {
				if (item.group.group_id === payload.groupId) {
					that.state.overview.conversations[index].left_date = moment().format();
				}
			});
		}
		this.commit('members/editMemberList', { ...payload, action:'remove' });
	},
	[types.REMOVE_MEMBER_FAIL] (state) {
		state.conversationMembersBusyOverlay = false;
	},
	[types.GET_MEMBER_STATUS] () {},
	[types.GET_MEMBER_STATUS_SUCCESS] (state, response) {
		var responseData = response.data.data ? response.data.data : response.data;
		if (
			this.state.overview.currentConversation.group &&
			this.state.overview.currentConversationId == responseData.group_id
		) {
			Vue.set(this.state.overview.currentConversation, 'onlineStatus', {});
			Vue.set(this.state.overview.currentConversation.onlineStatus, 'status', responseData.status);
			if (responseData.status == 1) {
				Vue.set(this.state.overview.currentConversation.onlineStatus, 'last_activity', moment());
			}
		}
	},
	[types.GET_MEMBER_STATUS_FAIL] () {},
	updateConversationMemberSearchString (state, value) {
		this.commit('members/clearConversationMembersSearch');
		state.conversationMemberSearchString = value;
	},
	clearConversationMembersSearch (state) {
		state.conversationMemberSearchString = '';
		state.conversationMemberSearchPage = 1;
		state.conversationMemberSearchLastPage = false;
		state.conversationMembersSearch = [];
	},
	updateMemberLists (state, { response, groupId }){
		if(response.data.data.data){
			let users = response.data.data.data
			let membersListEntry = state.savedConversationMembers.find(conversation => conversation.groupId == groupId)
			let conversationMemberTemplate = {
				groupId,
				conversationMembers: users,
				conversationMembersLastPage: response.data.data.next_page_url === null,
				conversationMembersPage: state.conversationMembersPage,
			}
			if(!membersListEntry) {
				conversationMemberTemplate.conversationMembers = users
				state.savedConversationMembers.push(conversationMemberTemplate)
			} else {
				conversationMemberTemplate.users = [...membersListEntry.conversationMembers, ...users]
				membersListEntry = conversationMemberTemplate
			}
		}
	},
	updateMemberList (state, membersListEntry) {
		state.conversationMembers = [...membersListEntry.conversationMembers]
		state.conversationMembersLastPage = membersListEntry.conversationMembersLastPage
		state.conversationMembersPage = membersListEntry.conversationMembersPage
	},
	editMemberList (state, payload){
		let groupId = payload.groupId
		let userId = payload.userId
		let action = payload.action
		let users = payload.users
		let membersListEntry = state.savedConversationMembers.find(conversation => conversation.groupId == groupId)
		if (membersListEntry && membersListEntry.conversationMembers){
			switch(action){
			case 'remove':
				var foundUser = membersListEntry.conversationMembers.findIndex(user => user.user.id === userId)
				if (foundUser !== -1) {
					membersListEntry.conversationMembers.splice(foundUser,1);
				}
				break
			case 'add':
				// remove potential duplicate entries
				users = users.filter((user) => {
					let userId = user.user ? user.user.id : user.id
					return !membersListEntry.conversationMembers.find(savedMember => (savedMember.user ? savedMember.user.id : savedMember.id) === userId)
				})

				// remove entries before adding new ones, so on next page load you dont duplicate the results
				if(!membersListEntry.conversationMembersLastPage){
					membersListEntry.conversationMembers.splice(membersListEntry.conversationMembers.length - users.length, membersListEntry.conversationMembers.length);
				}
				membersListEntry.conversationMembers.unshift(...users)
				this.state.overview.currentConversation.member_count += users.length;
				break
			case 'edit': {
				let foundUser = membersListEntry.conversationMembers.find(user => (user.user ? user.user.id : user.id) === userId);
				if (foundUser) {
					foundUser.permission_level_id = users[0].permission_level_id
				} else {
					this.commit('members/editMemberList', { groupId, users, action: 'add' });
				}
				break

			}
			}
		}
	},
	editCurrentMemberList (state, payload){
		let groupId = payload.groupId
		let users = payload.users
		let action = payload.action
		if (this.state.overview.currentConversationId == groupId) {
			switch (action) {
			case 'add':
				// remove potential duplicate entries
				users = users.filter((user) => {
					let userId = user.user ? user.user.id : user.id
					return !state.conversationMembers.find(conversationMember => (conversationMember.user ? conversationMember.user.id : conversationMember.id) === userId)
				})
				state.conversationMembers = [...users, ...state.conversationMembers]
				// remove entries before adding new ones, so on next page load you dont duplicate the results
				if(!state.conversationMembersLastPage){
					state.conversationMembers.splice(state.conversationMembers.length - users.length, state.conversationMembers.length)
				}
				break
			case 'edit': {
				let foundUser = state.conversationMembers.find(user => user.user.id === users[0].id);
				if (foundUser) {
					foundUser.permission_level_id = users[0].permission_level_id
				} else {
					this.commit('members/editCurrentMemberList', { groupId, users, action: 'add' });
				}
				break

			}
			}
		}
	},
};

export const actions = {
	getConversationMembers: (store, groupId) => {
		if(store.state.conversationMembersPage === 1){
			let membersListEntry = store.state.savedConversationMembers.find(conversation => conversation.groupId == groupId)
			if(membersListEntry) {
				api.cancelRequest('getConversationMembers')
				store.commit('updateMemberList', membersListEntry)
				return Promise.resolve()
			}
		}
		store.commit(types.GET_CONVERSATION_MEMBERS);
		return api.getConversationMembers(groupId, store.state.conversationMembersPage).then(
			(response) => {
				store.commit(types.GET_CONVERSATION_MEMBERS_SUCCESS, { response, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.GET_CONVERSATION_MEMBERS_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		).catch(() => {
			// Handle promise errors caused by request abort
		});
	},
	getConversationMembersSearch: (store, groupId) => {
		store.commit(types.GET_CONVERSATION_MEMBERS_SEARCH);
		return api
			.getConversationMembers(
				groupId,
				store.state.conversationMemberSearchPage,
				store.state.conversationMemberSearchString,
			)
			.then(
				(response) => {
					store.commit(types.GET_CONVERSATION_MEMBERS_SEARCH_SUCCESS, response);
					return Promise.resolve(response);
				},
				(error) => {
					store.commit(types.GET_CONVERSATION_MEMBERS_SEARCH_FAIL, error);
					return Promise.reject(errorMessage(error));
				},
			);
	},
	changeUserPermissions: (store, { groupId, userId, permissionLevel }) => {
		store.commit(types.CHANGE_USER_PERMISSIONS);
		return api.changeUserPermissions(groupId, userId, permissionLevel).then(
			(response) => {
				store.commit(types.CHANGE_USER_PERMISSIONS_SUCCESS, { response, userId, groupId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.CHANGE_USER_PERMISSIONS_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		);
	},
	removeMember: (store, { groupId, userId }) => {
		store.commit(types.REMOVE_MEMBER);
		return api.leaveConversation(groupId, userId).then(
			(response) => {
				store.commit(types.REMOVE_MEMBER_SUCCESS, { response, groupId, userId });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.REMOVE_MEMBER_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		);
	},
	getMemberStatus: (store, groupId) => {
		store.commit(types.GET_MEMBER_STATUS);
		return api.getMemberStatus(groupId).then(
			(response) => {
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.GET_MEMBER_STATUS_FAIL, error);
				return Promise.reject(errorMessage(error));
			},
		);
	},
	clearConversationMembers: (store) => {
		store.commit(types.CLEAR_CONVERSATION_MEMBERS);
	},
};

export const getters = {
	conversationMembersSearch: (state) => (state.conversationMembersSearch[0] ? state.conversationMembersSearch : []),
	conversationMembers: (state) => (state.conversationMembers[0] ? state.conversationMembers : []),
};

import moment from 'moment'
import { isSameDay, format } from 'date-fns'
// import { emojiIndex } from 'emoji-mart-vue'
import data from 'emoji-mart-vue-fast/data/apple.json'
import { EmojiIndex } from 'emoji-mart-vue-fast'
import i18n from '../i18n'
import { fileExtensions } from './allowedFileTypes'
import { getEnv } from '@env'

let emojiIndex = new EmojiIndex(data)
const magicRegex = /((?:(?:https?:\/\/))[^\s]+)|(e&e:[-a-z_0-9+]*:e&e)/g
const magicUrlRegex = /((?:(?:https?:\/\/))[^\s]+)/g
const magicEmojiRegex = /(e&e:[-a-z_0-9+]*:e&e)/g
export const restrictedEmojis = [
	// retricted
	'middle_finger',

	// unsupported by win10
	'melting_face',
	'smiling_face_with_tear',
	'face_with_open_eyes_and_hand_over_mouth',
	'face_with_peeking_eye',
	'saluting_face',
	'dotted_line_face',
	'disguised_face',
	'face_with_diagonal_mouth',
	'face_holding_back_tears',
	'rightwards_hand',
	'leftwards_hand',
	'palm_down_hand',
	'palm_up_hand',
	'pinched_fingers',
	'hand_with_index_finger_and_thumb_crossed',
	'index_pointing_at_the_viewer',
	'anatomical_heart',
	'lungs',
	'biting_lip',
	'ninja',
	'person_with_crown',
	'pregnant_man',
	'pregnant_person',
	'troll',
	'people_hugging',
	'bison',
	'mammoth',
	'beaver',
	'dodo',
	'feather',
	'seal',
	'coral',
	'beetle',
	'cockroach',
	'fly',
	'worm',
	'lotus',
	'potted_plant',
	'empty_nest',
	'nest_with_eggs',
	'blueberries',
	'olive',
	'bell_pepper',
	'beans',
	'flatbread',
	'tamale',
	'fondue',
	'teapot',
	'pouring_liquid',
	'bubble_tea',
	'jar',
	'magic_wand',
	'hamsa',
	'pinata',
	'mirror_ball',
	'nesting_dolls',
	'knot',
	'rock',
	'wood',
	'hut',
	'playground_slide',
	'pickup_truck',
	'roller_skate',
	'wheel',
	'ring_buoy',
	'thong_sandal',
	'military_helmet',
	'accordion',
	'long_drum',
	'low_battery',
	'coin',
	'boomerang',
	'carpentry_saw',
	'screwdriver',
	'ladder',
	'crutch',
	'x-ray',
	'elevator',
	'mirror',
	'window',
	'plunger',
	'mouse_trap',
	'bucket',
	'bubbles',
	'toothbrush',
	'headstone',
	'placard',
	'identification_card',
	'heavy_equals_sign',
	'hearth_hands',
	// emojis consisted of two emoji in win10
	'face_in_clouds',
	'face_exhaling',
	'face_with_spiral_eyes',
	'heart_on_fire',
	'mending_heart',
	'man_with_beard',
	'woman_with_beard',
	'red_haired_person',
	'curly_haired_person',
	'white_haired_person',
	'bald_person',
	'health_worker',
	'student',
	'teacher',
	'judge',
	'farmer',
	'cook',
	'mechanic',
	'factory_worker',
	'office_worker',
	'scientist',
	'technologist',
	'singer',
	'artist',
	'pilot',
	'astronaut',
	'firefighter',
	'man_in_tuxedo',
	'woman_in_tuxedo',
	'man_with_veil',
	'woman_with_veil',
	'woman_feeding_baby',
	'man_feeding_baby',
	'person_feeding_baby',
	'mx_claus',
	'person_with_probing_cane',
	'person_in_motorized_wheelchair',
	'person_in_manual_wheelchair',
	'black_cat',
	'transgender_flag',
]

export function emojisToShowFilter (emoji) {
	return emoji.short_names.find(name => !restrictedEmojis.includes(name))
}

export function mergeLastMessageBlocks (blocks) {
	return blocks.map(block => ['simple', 'url'].includes(block.type) ? block.content : block.type === 'emoji' ? getEmojiByName(block.content) : '').join('')

}
export function getEmojiByName (name) {
	const emoji = emojiIndex.search(name).find(function (emoji) {
		return emoji.id == name
	})
	return emoji.native
}
export function scrollLoad (e, busy, lastPage, loadFunction) {
	if (e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 60 && !busy && !lastPage) {
		loadFunction()
	}
}
export function openLink (link) {
	window.ReactNativeWebView.postMessage('{"type": "link", "data":"' + link + '"}')
}
export function getToken () {
	return window.messaging.token && window.messaging.token.url ? window.messaging.token.url : ''
}
export function getUrl (attachment, size) {
	let setSize = size === 'big' ? null : size
	const url = getEnv( 'VUE_APP_FRONTEND_API_URL' ) ? `${ getEnv( 'VUE_APP_FRONTEND_API_URL' ) }` : window.messaging.baseUrl
	return (
		url +
		'/attachments/' +
		attachment?.attachment_id +
		(window.messaging.token && window.messaging.token.url ? window.messaging.token.url : '') +
		(setSize ? (window.messaging.token && window.messaging.token.url ? '&' : '?') + 'size=' + setSize : '')
	)
}

export function errorMessage (response) {
	if (response.status == 0 || response.status == 401) {
		return ''
	}
	if (response.status == 403) {
		return i18n.t('permission_denied')
	}
	if (
		response.data &&
		response.data.errors &&
		typeof response.data.errors === 'object' &&
		Object.keys(response.data.errors).length != 0
	) {
		let errorMsg = ''
		Object.keys(response.data.errors).forEach(function (key) {
			const errorArray = response.data.errors[key]
			if (Array.isArray(errorArray)) {
				errorArray.forEach(function (errorMessage) {
					errorMsg += i18n.t(errorMessage) + '<br>'
				})
			} else {
				errorMsg = i18n.t(errorArray.message)
			}
		})
		return errorMsg == '' ? i18n.t('server_error_occured') : errorMsg
	} else if (response.data && response.data.error) {
		return response.data.error.description ? response.data.error.description : i18n.t('server_error_occured')
	}
	return i18n.t('server_error_occured')
}

export function bytesToSize (bytes) {
	const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ]
	if (bytes == 0) return 'n/a'
	const i = parseInt( Math.floor( Math.log( bytes ) / Math.log( 1024 ) ) )
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

export function fileType (extension) {
	let formatedExtension = extension
	if (extension && extension.includes('/')) {
		let splitFormat = extension.split('/')
		formatedExtension = splitFormat[1]
	}
	formatedExtension = formatedExtension.toLowerCase()
	if (fileExtensions.documents.includes(formatedExtension)) {
		return 'document'
	} else if (fileExtensions.presentation.includes(formatedExtension)) {
		return 'presenation'
	} else if (fileExtensions.sheets.includes(formatedExtension)) {
		return 'sheet'
	} else if (fileExtensions.displayImage.includes(formatedExtension)) {
		return 'displayImage'
	} else if (fileExtensions.image.includes(formatedExtension)) {
		return 'image'
	} else if (fileExtensions.video.includes(formatedExtension)) {
		return 'video'
	} else if (fileExtensions.audio.includes(formatedExtension)) {
		return 'audio'
	} else if (fileExtensions.archive.includes(formatedExtension)) {
		return 'archive'
	} else if (fileExtensions.special.includes(formatedExtension)) {
		return 'special'
	} else if (fileExtensions.misc.includes(formatedExtension)) {
		return 'misc'
	} else if (formatedExtension === 'link') {
		return 'link'
	}
	return 'other'
}

export function fileName (extension) {
	let formatedExtension = extension
	if (extension && extension.includes('/')) {
		let splitFormat = extension.split('/')
		formatedExtension = splitFormat[1]
	}
	if (fileExtensions.image.includes(formatedExtension)) {
		return i18n.t('image')
	} else if (fileExtensions.video.includes(formatedExtension)) {
		return i18n.t('video')
	} else if (fileExtensions.audio.includes(formatedExtension)) {
		return i18n.t('audio')
	} else if (fileExtensions.archive.includes(formatedExtension)) {
		return i18n.t('archive')
	}

	return i18n.t('document')
}

export function uppercaseString (stringToUppercase) {
	return stringToUppercase.charAt(0).toUpperCase() + stringToUppercase.slice(1)
}

export function getDate (date, timestamp, short = false) {
	const messageDate = moment.utc( date ).local()
	if (moment().diff(messageDate, 'minutes') < 5) {
		return i18n.t('now')
	} else if (moment().isSame(messageDate, 'day')) {
		return i18n.t('today') + (timestamp ? "" : messageDate.format(' HH:mm'))
	} else if (moment().subtract(1, 'day').isSame(messageDate, 'day')) {
		return i18n.t('yesterday')
	} else if (moment().isSame(messageDate, 'week')) {
		return uppercaseString(messageDate.format('dddd'))
	} else if (moment().isSame(messageDate, 'year')) {
		return uppercaseString(messageDate.format(i18n.t(short ? 'MMM Do': 'MMMM Do')))
	} else {
		return messageDate.format(i18n.t(short ? 'YYYY, MMM Do' : 'YYYY, MMMM Do'))
	}
}

export function convertDate (date, format) {
	return moment.utc(date).local().format(i18n.t(format))
}

export function attachmentIcon (attachment) {
	let iconType = []
	let attachmentFormat = attachment.extension || attachment.type
	switch (fileType(attachmentFormat)) {
	case 'document':
		iconType = 'icon-file-document'
		break
	case 'presentation':
		iconType = 'icon-file-presentation'
		break
	case 'sheet':
		iconType = 'icon-file-spreadsheet'
		break
	case 'displayImage':
		iconType = 'icon-file-image'
		break
	case 'image':
		iconType = 'icon-file-image'
		break
	case 'video':
		iconType = 'icon-file-video'
		break
	case 'audio':
		iconType = 'icon-file-audio'
		break
	case 'archive':
		iconType = 'icon-file-zip'
		break
	case 'link':
		iconType = 'no-icon'
		break
	default:
		iconType = 'icon-file-typ'
		break
	}

	return iconType
}

export function authorName (conversation) {
	if (!conversation.last_message.author) {
		return ''
	}
	return (
		`${!conversation.last_message.author.firstname && !conversation.last_message.author.lastname ? this.$t('not_defined') : conversation.last_message.author.firstname + ' ' + conversation.last_message.author.lastname}: `
	)
}
export function convertUserName (firstname, lastname) {
	const spacer = firstname && lastname ? ' ' : ''
	return !firstname && !lastname ? this.$t('not_defined') : firstname + spacer + lastname
}

export function splitMessage (element) {
	const sortedBlocks = []
	if (element.message_type !== 'system' && element.content_plain) {
		const spanBlocks = element.content_plain.split( magicRegex )
		spanBlocks.forEach(function (block) {
			let spanBlock
			if (magicUrlRegex.test(block)) {
				spanBlock = {
					type: 'url',
					content: block,
					text: block.length >= 140 ? block.substring(0, 140) + "..." : block,
				}
			} else if (magicEmojiRegex.test(block)) {
				const emojiWord = block.substring( 4, block.length - 4 )
				const foundEmojis = emojiIndex.search( emojiWord ).filter( function( emoji ) {
					return emoji.id == emojiWord
				} )

				spanBlock = {
					type: foundEmojis[0] ? 'emoji' : 'simple',
					content: emojiWord,
				}
			} else if (block && block != '') {
				spanBlock = {
					type: 'simple',
					content: block,
				}
			}
			if (spanBlock && spanBlock.content != '') {
				let markRemoved = false
				const lastChar = spanBlock.content[spanBlock.content.length - 1]
				const marks = '.?,!(){}[];\':*'
				if (marks.includes(lastChar) && spanBlock.type == 'url') {
					spanBlock.content = spanBlock.content.slice(0, -1)
					markRemoved = true
				}

				sortedBlocks.push(spanBlock)
				if (markRemoved) {
					sortedBlocks.push({
						type: 'simple',
						content: lastChar,
					})
				}
			}
		})
	}
	return sortedBlocks
}
export function sortMessages (unsortedMessages) {
	const messages = unsortedMessages
	const sortedMessages = []
	if (messages[0] && messages.length != 0) {
		let lastDate = moment( messages[0] ? messages[0].creation_date : '' )
		let lastUser = messages[0].author_user_id
		let lastType = messages[0].message_type
		const messageArray = [ [ [] ] ]
		let currentMessageGroup = 0
		let lastSystemMessageAuthor = messages[0].actor_user_id || null
		let lastSystemMessageType = messages[0].system_message_type || 0

		messages.forEach(function (currentElement) {
			const element = { ...currentElement }
			const sortedBlocks = splitMessage( element )
			const currentDate = moment( element.creation_date )

			if (sortedBlocks && sortedBlocks.length === 1 && sortedBlocks[0].type === 'emoji') {
				element.soloEmoji = true
			}
			// Splits messages into groups ( message rows )
			if (
				!currentDate.isSame(lastDate, 'day') ||
				// to avoid time duplications for polls
				(lastType != element.message_type && !(['poll', 'simple'].includes(lastType) && ['poll', 'simple'].includes(element.message_type))) ||
				(element.system_message_type && element.system_message_type != lastSystemMessageType) ||
				(element.actor_user_id && element.actor_user_id != lastSystemMessageAuthor)
			) {
				messageArray.push([[]])
				currentMessageGroup++
				lastUser = element.author_user_id
				lastSystemMessageType = element.system_message_type || 0
				lastSystemMessageAuthor = element.actor_user_id || null
			}

			lastType = element.message_type
			lastDate = moment(element.creation_date)

			// Splits messages into user groups inside time groups
			if (element.author_user_id != lastUser) {
				messageArray[currentMessageGroup].push([])
			}
			element.blocks = sortedBlocks
			lastUser = element.author_user_id

			messageArray[currentMessageGroup][messageArray[currentMessageGroup].length - 1].push(element)
		})
		messageArray.forEach(function (element) {
			sortedMessages.push(element)
		})
	}
	return sortedMessages
}

export function tests (extension) {
	return extension
}

// Converts some colors, guide: https://stackoverflow.com/questions/17242144/javascript-convert-hsb-hsv-color-to-rgb-accurately
export function HSVtoRGB (h, s, v) {
	let r, g, b, i, f, p, q, t
	if (arguments.length === 1) {
		(s = h.s), (v = h.v), (h = h.h)
	}
	i = Math.floor(h * 6)
	f = h * 6 - i
	p = v * (1 - s)
	q = v * (1 - f * s)
	t = v * (1 - (1 - f) * s)
	switch (i % 6) {
	case 0:
		(r = v), (g = t), (b = p)
		break
	case 1:
		(r = q), (g = v), (b = p)
		break
	case 2:
		(r = p), (g = v), (b = t)
		break
	case 3:
		(r = p), (g = q), (b = v)
		break
	case 4:
		(r = t), (g = p), (b = v)
		break
	case 5:
		(r = v), (g = p), (b = q)
		break
	}
	return {
		r: Math.round(r * 255),
		g: Math.round(g * 255),
		b: Math.round(b * 255),
	}
}

export function HSVtoHEX (h, s, l) {
	l /= 100;
	const a = s * Math.min(l, 1 - l) / 100;
	const f = n => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
	};
	return `#${f(0)}${f(8)}${f(4)}`;
}
const errorPageHandler = require('../components/pages/ErrorModalPage.vue')
export function chunkLoadFail () {
	return errorPageHandler
}


export let cookieHandler = {
	set: () => {
		document.cookie = 'disableCampaign=true' + ";path = /" + ";expires=" + new Date(moment().add(1, 'days').toDate()).toUTCString()
	},
	get:() => {
		return document.cookie.includes("disableCampaign")
	},
}

export let dynamicCookieHandler = {
	set: (cookieToSet) => {
		document.cookie = cookieToSet + '=true' + ";path = /" + ";expires=" + new Date(moment().add(6, 'months').toDate()).toUTCString()
	},
	get: (cookieToGet) => {
		return document.cookie.includes(cookieToGet)
	},
}

export function matchStringAgainst (str, search) {
	search = (search || '').toLocaleLowerCase().normalize('NFKD').replace(/[^\w\s]/g, '').trim()
	if (!search) {
		return true
	}
	const searchParts = search.split(/\s+/)
	str = (str || '').toLocaleLowerCase().normalize('NFKD').replace(/[^\w\s]/g, '').trim()
	for (let searchPartIndex = 0; searchPartIndex < searchParts.length; searchPartIndex++) {
		if (!str.includes(searchParts[searchPartIndex])) {
			return false
		}
	}
	return true
}

export function sortStrings (value1, value2) {
	value1 = typeof value1 === 'string' ? value1.toLowerCase() : value1
	value2 = typeof value2 === 'string' ? value2.toLowerCase() : value2
	if (value1 < value2) { return -1 }
	if (value1 > value2) { return 1 }
	return 0
}

export const state = () => ({
	sidebarOpen: false,
	uploadOpen: false,
	campaignOpen: false,
	emojiPickerOpen: false,
	emojiSearchActive: false,
	uploadEmojiSearchActive: false,
	uploadEmojiPickerOpen: false,
	pollingDialogOpen: false,
	pollingFeatureDialog: false,
	inputFocused: false,
	attachmentsViewOpen: false,
	reactionPickerOpen: false,
	usersListOpen: false,
	currentReactionsModalTab: 'all',
})

export const mutations = {
	updateSidebarOpen (state, value) {
		state.sidebarOpen = value
	},
	updateUploadOpen (state, value) {
		state.uploadOpen = value
	},
	updateCampaignOpen (state, value) {
		state.campaignOpen = value
	},
	updateEmojiPickerOpen (state, value) {
		state.emojiPickerOpen = value
	},
	updateEmojiSearchActive (state, value) {
		state.emojiSearchActive = value
	},
	updateUploadEmojiPickerOpen (state, value) {
		state.uploadEmojiPickerOpen = value
	},
	updatePollingDialogOpen (state, value) {
		state.pollingDialogOpen = value
	},
	updatePollingFeatureDialog (state, value) {
		state.pollingFeatureDialog = value
	},
	updateUploadEmojiSearchActive (state, value) {
		state.uploadEmojiSearchActive = value
	},
	updateInputFocused (state, value) {
		state.inputFocused = value
	},
	updateAttachmentsViewOpen (state, value) {
		state.attachmentsViewOpen = value
	},
	updateReactionPickerOpen (state, value) {
		state.reactionPickerOpen = value
	},
	updateCurrentReactionsModalTab (state, value) {
		state.currentReactionsModalTab = value
	},
	updateUsersListOpen (state, value) {
		state.usersListOpen = value
	},
}

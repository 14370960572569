// utils for function that needs to access vue instance
import store from '../store/store'
// import { dynamicCookieHandler } from '@utils'
import { getEnv } from '@env'

export function showErrorModal (error) {
	if (getEnv('VUE_APP_FRONTEND_DEBUG')) {
		// eslint-disable-next-line no-console
		console.log(error)
	}
	error = String(error)
	if(error === ''){
		return
	}

	this.$eDialog({
		title: this.$t('notice'),
		description: this.$t(error),
		actions: {
			close: {
				label: this.$t('close'),
				key: false
			},
		},
	})
}

export function kawaiErrorModal (error, title) {
	if (getEnv('VUE_APP_FRONTEND_DEBUG')) {
		// eslint-disable-next-line no-console
		console.log(error)
	}
	error = String(error)

	return this.$eDialog({
		title: title ? title : this.$t('notice'),
		description: error,
		actions: {
			close: {
				label: this.$t('ok'),
				key: false
			},
		},
	})
}

export function chunkLoadFail (error) {
	if (getEnv('VUE_APP_FRONTEND_DEBUG')) {
		// eslint-disable-next-line no-console
		console.log(error)
	}

	this.$eDialog({
		title: this.$t('notice'),
		description: this.$t('failed_chunk_message'),
		actions: {
			close: {
				label: this.$t('ok'),
				key: false
			},
		},
	})
}

export function pictureError (e, type, callback) {
	if (e.target && e.target.src && !store.state.general.isOffline) {
		if (type === 'profile') {
			e.target.src = '/img/no-picture.png'
		}
		if (type === 'link' && callback) {
			callback()
		}
	}
}

export function modalCloseHandler () {
	// return
	if (this.$store.state.general.isNative) {
		window.ReactNativeWebView.postMessage( '{"type": "modalHandler", "data": "hide"}' )
	}
}

export function trackGA4clickEvent (eventName, params) {
	this.$gtm.trackEvent({
		event: `GA:${eventName}`,
		...params
	})
}


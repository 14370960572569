import * as api from '../../api'
import * as types from '../mutation-types'
import { errorMessage, sortMessages } from '@utils'
import FileSaver from 'file-saver'

export const state = () => ({
	conversationMessages: [],
	conversationMessageSearchResults: [],
	conversationMessagesBusy: false,

	conversationMessagesLastPage: false,
	conversationMessagesFirstPage: false,

	conversationMessageSearchBusy: false,
	conversationMessageSearchString: '',
	conversationMessageSearchLastPage: false,
	conversationLastLoaded: {},

	conversationCursorLastMessage: null,
	conversationCursorFirstMessage: null,

	conversationMessagesSearchFirstPage: false,
	conversationMessagesSearchLastPage: false,
	conversationCursorSearchLastMessage: null,
	conversationCursorSearchFirstMessage: null,

	conversationsMessagesList: [],
	conversationMessagesStateLoading: false,
	conversationMessagesStateLoadingTimeout: null,
})

export const mutations = {
	[types.GET_CONVERSATION_MESSAGES] (state) {
		state.conversationMessagesBusy = true
	},
	[types.GET_CONVERSATION_MESSAGES_SUCCESS] (state, { response, groupId, clear }) {
		state.conversationLastLoaded = groupId
		state.conversationMessagesBusy = false
		if(response.data.data.pagination_type === "next"){
			state.conversationMessagesFirstPage = !response.data.data.next_request
			if(clear){
				state.conversationMessagesLastPage = !response.data.data.previous_request
			}
			state.conversationCursorLastMessage = response.data.data.next_cursor_message_id !== 0 ? response.data.data.next_cursor_message_id : null
			state.conversationMessages = [...state.conversationMessages, ...response.data.data.data]
			if(state.conversationCursorFirstMessage === null){
				state.conversationCursorFirstMessage = response.data.data.previous_cursor_message_id !== 0 ? response.data.data.previous_cursor_message_id : null
			}

		}else{
			state.conversationMessagesLastPage = !response.data.data.previous_request
			state.conversationCursorFirstMessage = response.data.data.previous_cursor_message_id !== 0 ? response.data.data.previous_cursor_message_id : null
			state.conversationMessages = [...response.data.data.data, ...state.conversationMessages]
		}


		if (this.state.overview.currentConversation.group) {
			if (window.parent && this.state.overview.currentConversation.unread_count) {
				var dataObject = {
					type: 'readMessages',
					amount: this.state.overview.currentConversation.unread_count,
				}
				window.parent.postMessage(dataObject, '*', [])
			}
			this.state.overview.currentConversation.unread_count = 0
		}

		this.commit('messageList/updateConversationsMessagesList', { response, groupId });
	},
	[types.GET_CONVERSATION_MESSAGES_FAIL] (state, error) {
		if (error.status != 0) {
			state.conversationMessagesBusy = false
		}
	},
	[types.GET_CONVERSATION_MESSAGES_SEARCH] (state) {
		if (state.conversationMessageSearchPage == 1) {
			// this._vm.$ga.sendEvent('USER_ACTION', 'GET_CONVERSATION_MESSAGES_SEARCH')
			this._vm.trackGA4clickEvent('GET_CONVERSATION_MESSAGES_SEARCH');
		}
		state.conversationMessageSearchBusy = true
	},
	[types.GET_CONVERSATION_MESSAGES_SEARCH_SUCCESS] (state, response) {
		state.conversationMessageSearchBusy = false

		if(response.data.data.pagination_type === "next"){
			state.conversationMessagesSearchFirstPage = !response.data.data.next_request
			state.conversationCursorSearchLastMessage = response.data.data.next_cursor_message_id !== 0 ? response.data.data.next_cursor_message_id : null
			state.conversationMessageSearchResults = [...state.conversationMessageSearchResults, ...response.data.data.data]
			if(state.conversationCursorSearchFirstMessage === null){
				state.conversationCursorSearchFirstMessage = response.data.data.previous_cursor_message_id !== 0 ? response.data.data.previous_cursor_message_id : null
			}

		}else{
			state.conversationMessagesSearchLastPage = !response.data.data.previous_request
			state.conversationCursorSearchFirstMessage = response.data.data.previous_cursor_message_id !== 0 ? response.data.data.previous_cursor_message_id : null
			state.conversationMessageSearchResults = [...response.data.data.data, ...state.conversationMessageSearchResults]
		}
	},
	[types.GET_CONVERSATION_MESSAGES_SEARCH_FAIL] (state) {
		state.conversationMessageSearchBusy = true
	},
	[types.CLEAR_CONVERSATION_MESSAGES] (state) {
		state.conversationMessages = []
		state.conversationMessagesLastPage = false
		state.conversationCursorLastMessage = null
		state.conversationCursorFirstMessage = null
		state.conversationMessagesFirstPage = false
		state.conversationMessagesLastPage = false
	},
	[types.CLEAR_CONVERSATION_MESSAGE_SEARCH] (state) {
		state.conversationMessageSearchResults = []
		state.conversationMessagesSearchFirstPage = false
		state.conversationMessagesSearchLastPage = false
		state.conversationCursorSearchLastMessage = null
		state.conversationCursorSearchFirstMessage = null
	},
	setConversationMessageSearchString (state, string) {
		state.conversationMessageSearchString = string
	},
	updateConversationLastLoaded (state, value) {
		state.conversationLastLoaded = value
	},
	setAllMessagesToRead (state) {
		let conversationMessageCopy = [ ...state.conversationMessages ]
		conversationMessageCopy.forEach((message) => {
			if (message.first_seen_by_me) {
				message.first_seen_by_me = true
			}
		})
		state.conversationMessages = conversationMessageCopy
	},
	clearConversationMessages (state, { groupId }) {
		let conversation = state.conversationsMessagesList.find(conversation => conversation.groupId == groupId)
		if (conversation) {
			conversation.messages = []
			conversation.conversationMessagesFirstPage = true
			conversation.conversationMessagesLastPage = true
			conversation.conversationCursorFirstMessage = undefined
		}
	},
	updateConversationsMessagesList (state, { response, groupId }) {
		if(response.data.data.data) {
			let messages = response.data.data.data
			let conversation = state.conversationsMessagesList.find(conversation => conversation.groupId == groupId)
			if(!conversation) {
				let conversationTemplate = {
					groupId,
					messages,
					conversationMessagesFirstPage: state.conversationMessagesFirstPage,
					conversationMessagesLastPage: state.conversationMessagesLastPage,
					conversationCursorFirstMessage: state.conversationCursorFirstMessage,
					messageDraft: this.state.messageDraft.messageDraft,
				}
				state.conversationsMessagesList = [conversationTemplate, ...state.conversationsMessagesList]
			} else {
				if (response.data.data.pagination_type === "next") {
					conversation.messages = [...messages, ...conversation.messages]
				} else {
					conversation.messages = [...conversation.messages, ...messages]
				}
			}
		}
	},
	updateConversationMessages (state, { conversation, groupId }) {
		api.cancelRequest('getConversationMessages')
		api.cancelRequest('getMessageDraft')
		state.conversationMessages = conversation.messages
		state.conversationLastLoaded = groupId
		state.conversationMessagesLastPage = conversation.conversationMessagesLastPage
		state.conversationCursorFirstMessage = conversation.conversationCursorFirstMessage
		state.conversationMessagesFirstPage = conversation.conversationMessagesFirstPage
		state.conversationMessagesStateLoading = true
		if (state.conversationMessagesStateLoadingTimeout) {
			clearTimeout(state.conversationMessagesStateLoadingTimeout)
		}
		// stop scroll load while rendering convo
		this.dispatch('socket/readLastMessage', false)
		state.conversationMessagesStateLoadingTimeout = setTimeout(() => this.commit('messageList/updateConversationMessagesStateLoading', false), 500)
	},
	updateConversationMessage (state, { message, groupId, action }) {
		let conversation = state.conversationsMessagesList.find(conversation => conversation.groupId == groupId)
		// since list entry is vue variable copy, its watchers are attached to it and it will auto sync, but if it doesnt just sync it manually and return
		if (this.state.overview.currentConversation.group_id === groupId) {
			if (conversation) {
				conversation.messages = state.conversationMessages
			}
			return
		}
		if (conversation) {
			if (action == 'delete' || action === 'edit' || action === 'read') {
				let foundMessage = conversation.messages.filter(conversationMessage => conversationMessage.message_id == message.message_id)
				if (foundMessage) {
					switch(action){
					case 'delete':
						foundMessage[0].attachments = []
						foundMessage[0].deleted_by_sender = true
						foundMessage[0].content_plain = message.messageContent
						foundMessage[0].editable = false
						break
					case 'edit':
						foundMessage[0].content_plain = message.content_plain
						foundMessage[0].last_edited = message.last_edited
						break
					case 'read':
						var foundMessages = conversation.messages.filter(
							(searchMessage) =>
								searchMessage.message_id <= message.message_id &&
								searchMessage.author &&
								searchMessage.author.id === this.state.user.user.id,
						)
						foundMessages.forEach((foundMessage) => {
							foundMessage.first_seen_any = message.first_seen_any;
						})
						break
					}
				}
			}else{
				conversation.messages.push(message)
			}

		}
	},
	updateConversationMessagesStateLoading (state, value) {
		state.conversationMessagesStateLoading = value
	},
	updatePoll (state, { pollId, data }) {
		const message = state.conversationMessages.find(conversation => conversation.poll?.id == pollId)
		if (message) {
			message.poll = data
		}
	},
}

export const actions = {
	getConversationMessages: (store, { groupId, messageCursor, paginationType, clear }) => {
		if (clear || store.state.conversationLastLoaded !== groupId) {
			store.commit('messageList/' + types.CLEAR_CONVERSATION_MESSAGES, {}, { root: true })
			store.commit('messageList/' + types.CLEAR_CONVERSATION_MESSAGE_SEARCH, {}, { root: true })
			store.commit('messageOptions/' + types.CLEAR_MESSAGE_LAST_SEEN, {}, { root: true })
			store.commit('members/' + types.CLEAR_CONVERSATION_MEMBERS, {}, { root: true })
			store.commit('attachments/' + types.CLEAR_CONVERSATION_ATTACHMENTS, {}, { root: true })
			store.commit('messageOptions/updateSendImportant', false, { root: true })
			store.commit('members/clearConversationMembersSearch', {}, { root: true })
			store.commit('messageOptions/updateCheckedMessages', [], { root: true })
			let conversation = store.state.conversationsMessagesList.find(conversation => conversation.groupId == groupId)
			if(conversation) {
				store.commit('updateConversationMessages', { conversation, groupId })
				return Promise.resolve()
			}
		}
		store.commit(types.GET_CONVERSATION_MESSAGES)
		let stateCursor = paginationType === "next" ? store.state.conversationCursorLastMessage : store.state.conversationCursorFirstMessage
		let messageId = (messageCursor !== null && typeof messageCursor !== 'undefined') ? messageCursor : stateCursor
		return api
			.getConversationMessages(messageId, groupId, paginationType)
			.then(
				(response) => {
					store.commit(types.GET_CONVERSATION_MESSAGES_SUCCESS, { response, groupId, clear })
					return Promise.resolve(response)
				},
				(error) => {
					store.commit(types.GET_CONVERSATION_MESSAGES_FAIL, error)
					return Promise.reject(errorMessage(error))
				},
			)
			.catch(() => {
				// Handle promise errors caused by request abort
			})
	},
	searchConversationMessages: (store, { groupId, paginationType }) => {
		store.commit(types.GET_CONVERSATION_MESSAGES_SEARCH)
		let stateCursor = paginationType === "next" ? store.state.conversationCursorSearchLastMessage : store.state.conversationCursorSearchFirstMessage
		let messageId = stateCursor
		return api
			.getConversationMessages(
				messageId,
				groupId,
				paginationType,
				store.state.conversationMessageSearchString,
			)
			.then(
				(response) => {
					store.commit(types.GET_CONVERSATION_MESSAGES_SEARCH_SUCCESS, response)
					return Promise.resolve(response)
				},
				(error) => {
					store.commit(types.GET_CONVERSATION_MESSAGES_SEARCH_FAIL, error)
					return Promise.reject(errorMessage(error))
				},
			)
	},
	clearConversationMessages: (store) => {
		store.commit(types.CLEAR_CONVERSATION_MESSAGES)
	},
	clearConversationMessageSearch: (store) => {
		store.commit(types.CLEAR_CONVERSATION_MESSAGE_SEARCH)
	},
	submitPollingVote: ({ commit }, { groupId, pollId, answerId }) => {
		api.submitPollingVote(groupId, pollId, answerId)
			.then((response) => {
				commit('updatePoll', { pollId, data: response.body.data })
			})
			.catch((error) => {
				return Promise.reject(errorMessage(error))
			})
	},
	resetPollingVote: ({ commit }, { groupId, pollId }) => {
		api.resetPollingVote(groupId, pollId)
			.then((response) => {
				commit('updatePoll', { pollId, data: response.body.data })
			})
			.catch((error) => {
				return Promise.reject(errorMessage(error))
			})
	},
	getConversationPollPdf: (store, { groupId, pollId }) => {
		api.getConversationPollPdf(groupId, pollId)
			.then((response) => {
				const blob = new Blob([response.body], { type: "application/pdf" });
				FileSaver.saveAs(blob, "poll.pdf");

			})
			.catch((error) => {
				return Promise.reject(errorMessage(error))
			})
	},

}

export const getters = {
	conversationMessagesUnsorted: (state) => state.conversationMessages,
	conversationMessages: (state) => sortMessages(state.conversationMessages),
	conversationMessageSearchResults: (state) => sortMessages(state.conversationMessageSearchResults),
	conversationAttachmentsSorted: (state) => {
		let messages = state.conversationMessages
		// TODO: Find why we get an undefined attachment after system announcement in chat
		let attachments = messages.filter(message => message).map(message => message.attachments ? message.attachments : []).flat()
		return attachments.filter(attachment => attachment?.extension !== 'link')
	},
}

const defaultState = {
	pollingId: null,
	pollingQuestion: '',
	pollingAnswers: [],
	pollingExpirationDateTime: null
}

export const state = () => ({
	...defaultState
})

export const mutations = {
	initializePolling (state, partialData = {}) {
		Object.keys(defaultState).forEach((key) => {
			state[key] = partialData[key] || defaultState[key]
		})
	},
	updatePolling (state, partialData = {}) {
		Object.keys(partialData).forEach((key) => {
			if (Object.prototype.hasOwnProperty.call(defaultState, key)) {
				state[key] = partialData[key]
			}
		})
	},
	deletePolling (state) {
		state.pollingId = null
		state.pollingQuestion = ''
		state.pollingAnswers = []
		state.pollingExpirationDateTime = null
	}
}

export const actions = {
	initializePolling ({ commit }, partialData = {}) {
		commit('initializePolling', partialData)
	},
	updatePolling ({ commit }, partialData = {}) {
		commit('updatePolling', partialData)
	},
	deletePolling ({ commit }) {
		commit('deletePolling')
	}
}

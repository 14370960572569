import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@store';
import i18n from '../i18n/index'

const ErrorPage = require('../components/pages/ErrorPage')

const Index = () => import(/* webpackChunkName: "js/overview" */ '../components/pages/Overview').catch(() => { return ErrorPage });
const Main = () => import(/* webpackChunkName: "js/main" */ '../components/pages/Main').catch(() => { return ErrorPage });
const Contacts = () => import(/* webpackChunkName: "js/contacts" */ '../components/pages/Contacts').catch(() => { return ErrorPage });

Vue.use(VueRouter);
const Router = new VueRouter({
	// hashbang: false,
	// history: true,
	linkActiveClass: 'active',
	linkExactActiveClass: 'active-exact',
	routes: [
		{
			name: 'Index',
			path: '/',
			component: Index,
			children: [
				{
					name: 'minichat',
					path: 'mini-chat/:id',
					component: Main,
					beforeEnter: (to, from, next) => {
						store.commit('general/updateMiniChat', true);
						next();
					},
				},
				{
					name: 'fullchat',
					path: 'full-chat/:id',
					component: Main,
					beforeEnter: (to, from, next) => {
						store.commit('general/updateFullChat', true);
						next();
					},
				},
				{
					name: 'native',
					path: 'native/:platform',
					component: Main,
					beforeEnter: (to, from, next) => {
						store.commit('general/updateIsNative', true);
						if (to.params) {
							store.commit('general/updatePlatform', to.params.platform);
						}
						next();
					},
				},
				{
					name: 'Main',
					path: 'main',
					component: Main,
					children: [
						{
							name: 'Contacts',
							path: 'contacts/:context',
							component: Contacts,
						},
					],
				},
			],
		},
	],
});

const validLocales = ['de', 'en', 'et', 'lv', 'ru', 'ja', 'fr']

Router.beforeEach((to, from, next) => {
	// Get value from query string key "locale"
	const locale = to.query.locale
	const theme = to.query.theme

	// If value of locale is valid then change default locale before API "/me" responds
	if (validLocales.includes(locale)) {
		i18n.locale = locale
	}
	if (['light', 'dark'].includes(theme)) {
		store.commit('general/updateAppTheme', theme);
	}

	next();
})

export default Router;

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { mapSetter } from '../utils/mappers'

// user.js
export const userComputed = {
	...mapSetter(
		mapState('user', [
			'user',
			'emailNotificationDelayType',
			'enterSendsMessage',
			'showEmptyConvo',
			'getUserBusy',
			'userSettingsBusy',
			'setUserSettingsBusy',
			'readAllMessagesBusy',
			'importantMessageAllowed',
			'schools'
		]),
		mapMutations('user', {
			emailNotificationDelayType: 'updateEmailNotificationDelayType',
			enterSendsMessage: 'updateEnterSendsMessage',
			showEmptyConvo: 'updateShowEmptyConvo',
		})
	),
}

export const userRenamedComputed = {
	...mapState('user', {
		appUser: 'user',
	}),
}

export const userMethods = {
	...mapActions('user', [ 'getUser', 'getUserSettings', 'getImportantMessageRole', 'getSchoolFeature' ]),
	...mapActions('user', { setUserSettingsMethod: 'setUserSettings', readAllMessagesMethod: 'readAllMessages' }),
}

// socket.js
export const socketMethods = {
	...mapActions('socket', [ 'setSocketFunctions', 'readLastMessage' ]),
	...mapMutations('socket', [ 'registerSocket', 'resetSocket' ]),
}

// interface.js
export const interfaceComputed = {
	...mapSetter(
		mapState('interface', [
			'sidebarOpen',
			'uploadOpen',
			'campaignOpen',
			'emojiPickerOpen',
			'emojiSearchActive',
			'uploadEmojiSearchActive',
			'uploadEmojiPickerOpen',
			'pollingDialogOpen',
			'pollingFeatureDialog',
			'inputFocused',
			'attachmentsViewOpen',
			'reactionPickerOpen',
			'currentReactionsModalTab',
			'usersListOpen',
		]),
		mapMutations('interface', {
			sidebarOpen: 'updateSidebarOpen',
			uploadOpen: 'updateUploadOpen',
			campaignOpen: 'updateCampaignOpen',
			emojiPickerOpen: 'updateEmojiPickerOpen',
			emojiSearchActive: 'updateEmojiSearchActive',
			uploadEmojiSearchActive: 'updateUploadEmojiSearchActive',
			uploadEmojiPickerOpen: 'updateUploadEmojiPickerOpen',
			pollingDialogOpen: 'updatePollingDialogOpen',
			pollingFeatureDialog: 'updatePollingFeatureDialog',
			inputFocused: 'updateInputFocused',
			attachmentsViewOpen: 'updateAttachmentsViewOpen',
			reactionPickerOpen: 'updateReactionPickerOpen',
			currentReactionsModalTab: 'updateCurrentReactionsModalTab',
			usersListOpen: 'updateUsersListOpen',
		})
	),
}

// contacts.js
export const contactsComputed = {
	...mapSetter(
		mapState('contacts', [
			'newConversationTitle',
			'selectedSchool',
			'selectedYear',
			'schools',
			'contacts',
			'expandedContactGroups',
			'processedContacts',
			'contactTags',
			'createConversationBusy',
			'contactsBusy',
			'allContactsEmpty',
		]),
		mapMutations('contacts', {
			newConversationTitle: 'updateNewConversationTitle',
			selectedSchool: 'updateSelectedSchool',
			selectedYear: 'updateSelectedYear',
		})
	),
}

export const contactsMethods = {
	...mapActions('contacts', [ 'getSchools' ]),
	...mapActions('contacts', {
		getContactsMethod: 'getContacts',
		createConversationMethod: 'createConversation',
		addConversationMembersMethod: 'addConversationMembers',
		setContactGroupExpanded: 'setContactGroupExpanded',
		resetContactGroupExpanded: 'resetContactGroupExpanded',
	}),
}

// conversations.js
export const overviewComputed = {
	...mapSetter(
		mapState('overview', [
			'currentConversation',
			'contactConversations',
			'conversationsPage',
			'contactConversationsPage',
			'conversationsLastPage',
			'conversationsLoaded',
			'contactConversationsLastPage',
			'conversationsBusy',
			'contactConversationsBusy',
			'contactConversationSearchString',
			'conversationFilter',
			'conversationSearchString',
			'contactConversationFilter',
			'conversationFilterTypes',
			'lastSelectedConversation',
			'uniqueSchoolIds',
			'schoolLogos',
			'schoolLogosBusy',
		]),
		mapMutations('overview', {
			conversationSearchString: 'updateConversationSearchString',
			contactConversationSearchString: 'updateContactConversationSearchString',
			conversationFilter: 'updateConversationFilter',
			contactConversationFilter: 'updateContactConversationFilter',
			currentConversation: 'updateCurrentConversation',
			lastSelectedConversation: 'updateLastSelectedConversation',
		})
	),
	...mapState('overview', [
		'currentConversationId',
	]),
	...mapGetters('overview', [ 'conversations', 'contactConversations' ]),
}

export const overviewMethods = {
	...mapActions('overview', [ 'getConversations', 'getContactConversations', 'setConversationSearchString', 'getSchoolLogos' ]),
	...mapActions('overview', {
		clearConversationsMethod: 'clearConversations',
		clearContactConversationsMethod: 'clearContactConversations',
	}),
}

// members.js
export const membersComputed = {
	...mapSetter(
		mapState('members', [
			'conversationMembersPage',
			'conversationMemberSearchPage',
			'conversationMembersLastPage',
			'conversationMemberSearchLastPage',
			'conversationMembersBusy',
			'conversationMemberSearchBusy',
			'conversationMembersBusyOverlay',
			'conversationMemberSearchString',
		]),
		mapMutations('members', {
			conversationMemberSearchString: 'updateConversationMemberSearchString',
		})
	),
	...mapGetters('members', [ 'conversationMembersSearch', 'conversationMembers' ]),
}

export const membersMethods = {
	...mapActions('members', [
		'changeUserPermissions',
		'getMemberStatus',
		'clearConversationMembers',
		'getConversationMembers',
	]),
	...mapActions('members', {
		getConversationMembersMethod: 'getConversationMembers',
		removeMemberMethod: 'removeMember',
		getConversationMembersSearchMethod: 'getConversationMembersSearch',
	}),
	...mapMutations('members', [ 'clearConversationMembersSearch' ]),
}

// attachments.js
export const attachmentsComputed = {
	...mapSetter(
		mapState('attachments', [
			'conversationAttachmentsLastPage',
			'conversationAttachmentsPage',
			'conversationAttachmentsBusy',
			'currentMediaObject',
			'currentFileTab',
			'galleryAttachments',
		]),
		mapMutations('attachments', {
			currentMediaObject: 'updateCurrentMediaObject',
			currentFileTab: 'updateCurrentFileTab',
			galleryAttachments: 'updateGalleryAttachments',
		})
	),
	...mapGetters('attachments', [ 'conversationAttachments' ]),
}

export const attachmentsMethods = {
	...mapActions('attachments', [ 'clearConversationAttachments', 'getAttachmentPdf' ]),
	...mapActions('attachments', { getConversationAttachmentsMethod: 'getConversationAttachments' }),
}

// messageList.js
export const messageListComputed = {
	...mapSetter(
		mapState('messageList', [
			'conversationMessagesBusy',
			'conversationMessageSearchPage',
			'conversationMessagesLastPage',
			'conversationMessagesFirstPage',
			'conversationMessageSearchBusy',
			'conversationMessageSearchString',
			'conversationMessageSearchLastPage',
			'conversationLastLoaded',
			'conversationMessagesSearchFirstPage',
			'conversationMessagesSearchLastPage',
			'conversationMessagesStateLoading',
			'conversationsMessagesList',
		]),
		mapMutations('messageList', {
			conversationMessageSearchString: 'setConversationMessageSearchString',
			conversationLastLoaded: 'updateConversationLastLoaded',
		})
	),
	...mapGetters('messageList', [
		'conversationMessagesUnsorted',
		'conversationMessages',
		'conversationMessageSearchResults',
		'conversationAttachmentsSorted',
	]),
}

export const messageListMethods = {
	...mapActions('messageList', [
		'getConversationMessages',
		'searchConversationMessages',
		'clearConversationMessages',
		'clearConversationMessageSearch',
		'setConversationMessageSearchString',
	]),
	...mapMutations('messageList', [ 'setAllMessagesToRead' ]),
}

// general.js
export const generalComputed = {
	...mapSetter(
		mapState('general', [
			'locationHash',
			'appTheme',
			'webActive',
			'desktop',
			'fullscreenChat',
			'windowWidth',
			'miniChat',
			'fullChat',
			'windowHasFocus',
			'mobile',
			'touchDevice',
			'showScrollDown',
			'firstLoad',
			'isIe',
			'isIOS',
			'isNative',
			'platform',
			'nativeVersion',
			'iosData',
			'offlineFunction',
			'unauthorizedFunction',
			'returnUrl',
			'loginStatuss',
			'virtualKeyboard'
		]),
		mapMutations('general', {
			appTheme: 'updateAppTheme',
			locationHash: 'updateLocationHash',
			webActive: 'updateWebActive',
			desktop: 'updateDesktop',
			fullscreenChat: 'updateFullscreenChat',
			windowWidth: 'updateWindowWidth',
			miniChat: 'updateMiniChat',
			fullChat: 'updateFullChat',
			windowHasFocus: 'updateWindowHasFocus',
			mobile: 'updateMobile',
			isIOS: 'updateIsIOS',
			showScrollDown: 'updateShowScrollDown',
			firstLoad: 'updateFirstLoad',
			isIe: 'updateIsIe',
			isNative: 'updateIsNative',
			nativeVersion: 'updateNativeVersion',
			iosData: 'updateIosData',
			offlineFunction: 'updateOfflineFunction',
			unauthorizedFunction: 'updateUnauthorizedFunction',
			loginStatuss: 'updateLoginStatuss',
			returnUrl: 'updateReturnUrl',
			virtualKeyboard: 'updateVirtualKeyboard'
		})
	),
}

// message.js
export const messageComputed = {
	...mapSetter(
		mapState('message', [
			'newMessageContent',
			'files',
			'messageAttachments',
			'currentMessage',
			'lastMessageContent',
			'lastUnreadMessage',
			'currentMessageUser',
			'newMessagePolling',
			'currentMessagePolling',
		]),
		mapMutations('message', {
			newMessageContent: 'updateNewMessageContent',
			lastMessageContent: 'updateLastMessageContent',
			currentMessage: 'updateCurrentMessage',
			files: 'setUploadedFiles',
			messageAttachments: 'setMessageAttachments',
			lastUnreadMessage: 'updateLastUnreadMessage',
			currentMessageUser: 'updateCurrentMessageUser',
			newMessagePolling: 'updateNewMessagePolling',
			currentMessagePolling: 'updateCurrentMessagePolling'
		})
	),
}

export const messageMethods = {
	...mapActions('message', [ 'createMessage', 'resendMessage', 'fileUpload', 'clearFiles', 'setUploadedFiles' ]),
	...mapActions('message', {
		resendMessageMethod: 'resendMessage',
		clearFileMethod: 'clearFile',
	}),
	...mapMutations('message', { manualFileFail: 'FILE_UPLOAD_FAIL' }),
}

// messageOptions.js
export const messageOptionsComputed = {
	...mapSetter(
		mapState('messageOptions', [
			'activeMessage',
			'messageInEdit',
			'checkedMessages',
			'messageLastSeenUsers',
			'messageLastSeenLastPage',
			'messageLastSeenUsersLastPage',
			'messageLastSeenBusy',
			'messageEditBusy',
			'forwardMessagesBusy',
			'forwardMessageActive',
			'firstSeenDelay',
			'sendImportant',
			'foundEmojis',
			'emojiSearchParams',
		]),
		mapMutations('messageOptions', {
			messageInEdit: 'updateMessageInEdit',
			checkedMessages: 'updateCheckedMessages',
			forwardMessageActive: 'updateForwardMessageActive',
			sendImportant: 'updateSendImportant',
			foundEmojis: 'updateFoundEmojis',
			emojiSearchParams: 'updateEmojiSearchParams',
		})
	),
}

export const messageOptionsMethods = {
	...mapActions('messageOptions', [
		'forwardMessages',
		'updateFirstSeen',
		'clearMessageLastSeen',
		'updateFirstSeenLauncher',
		'deleteMessageAttachment'
	]),
	...mapActions('messageOptions', {
		forwardMessagesMethod: 'forwardMessages',
		editMessageMethod: 'editMessage',
		hideMessageMethod: 'hideMessage',
		deleteMessageMethod: 'deleteMessage',
		getMessageSeenByMethod: 'getMessageSeenBy',
	}),
}
// messageDraft.js
export const messageDraftComputed = {
	...mapState('messageDraft', [ 'messageDraft', 'messageDraftBusy' ]),
}

export const messageDraftMethods = {
	...mapActions('messageDraft', [ 'getMessageDraft', 'deleteMessageDraft', 'createMessageDraft' ]),
	...mapMutations('messageDraft', [ 'clearDraft' ]),
}

// conversationOptions.js
// todo too many options, maybe split somehow
export const conversationOptionsComputed = {
	...mapSetter(
		mapState('conversationOptions', [
			'conversationDetails',
			'conversationGroupPicture',
			'conversationActionBusy',
			'conversationDetailsChangeBusy',
			'conversationDetailsBusy',
			'clearConversationBusy',
			'muteConversationBusy',
		]),
		mapMutations('conversationOptions', {
			conversationGroupPicture: 'updateConversationGroupPicture',
		})
	),
}

export const conversationOptionsMethods = {
	...mapActions('conversationOptions', [ 'toggleReplies', 'changeConversationPicture', 'changeConversationTitle' ]),
	...mapActions('conversationOptions', {
		getConversationDetailsMethod: 'getConversationDetails',
		clearConversationMethod: 'clearConversation',
		deleteConversationMethod: 'deleteConversation',
		deleteConversationPermanentlyMethod: 'deleteConversationPermanently',
		unmuteConversationMethod: 'unmuteConversation',
		muteConversationMethod: 'muteConversation',
		deleteConversationPictureMethod: 'deleteConversationPicture',
		archiveConversationMethod: 'archiveConversation',
		leaveConversationMethod: 'leaveConversation',
	}),
	...mapMutations('conversationOptions', [ 'clearConversationDetails' ]),
}
// campaign.js
export const campaignComputed = {
	...mapState('campaign', [ 'campaign' ]),
	...mapGetters('campaign', [ 'campaignContent' ]),
}

export const campaignMethods = {
	...mapActions('campaign', [ 'getCampaign' ]),
	...mapActions('campaign', { deleteCampaignMethod: 'deleteCampaign' }),
}

// selectedContacts.js
export const selectedContactsComputed = {
	...mapSetter(
		mapState('selectedContacts', [
			'checkedOfficialGroups',
			'checkedContactTags',
			'checkedContactUserTags',
			'checkedContacts',
			'checkedConversations',
			'currentContactTab',
			'conversationType',
			'contactTabNames',
			'showCreateSoloConversationModal',
			'showSoloConversationWarning',
			'userSearchString',
			'userSearchOpen',
			'selectedClass',
			'lastSelectedSchool',
			'lastSelectedYear',
		]),
		mapMutations('selectedContacts', {
			checkedOfficialGroups: 'updateCheckedOfficialGroups',
			checkedContactTags: 'updateCheckedContactTags',
			checkedContactUserTags: 'updateCheckedContactUserTags',
			checkedContacts: 'updateCheckedContacts',
			checkedConversations: 'updateCheckedConversations',
			currentContactTab: 'updateCurrentContactTab',
			conversationType: 'updateConversationType',
			showCreateSoloConversationModal: 'setShowCreateSoloConversationModal',
			showSoloConversationWarning: 'setShowSoloConversationWarning',
			userSearchString: 'updateUserSearchString',
			userSearchOpen: 'updateUserSearchOpen',
			selectedClass: 'updateSelectedClass',
			lastSelectedSchool: 'updateLastSelectedSchool',
			lastSelectedYear: 'updateLastSelectedYear',
		})
	),
	...mapGetters('selectedContacts', [
		'specialContactTabSelected',
		'classIds',
		'checkedClassTabs',
		'emptyClasses',
		'showContactTab',
	]),
}

export const selectedContactsMethods = {
	...mapActions('selectedContacts', [
		'toggleSelectAllClassContacts',
		'addCheckedContact',
		'selectAllContacts',
		'deselectAllContacts',
	]),
	...mapMutations('selectedContacts', [
		'toggleCheckedConversations',
		'removeCheckedTag',
		'toggleOfficialGroups',
	]),
}

export const reactionMethods = {
	...mapActions('reaction', ['createMessageReaction', 'deleteMessageReaction', 'getMessageReactions']),
}
export const reactionComputed = {
	...mapState('reaction', ['getMessageReactionsBusy', 'messageReactions', 'createMessageReactionBusy', 'deleteMessageReactionBusy', 'busyReactions']),
}

import * as api from '../../api';
import * as types from '../mutation-types';
import { errorMessage } from '@utils';

export const state = () => ({
	messageDraft: null,
	messageDraftBusy: false,
})

export const mutations = {
	[types.GET_MESSAGE_DRAFT] (state) {
		state.messageDraftBusy = true;
	},
	[types.GET_MESSAGE_DRAFT_SUCCESS] (state, value) {
		this.state.messageList.conversationMessagesBusy = false;
		state.messageDraftBusy = false;
		state.messageDraft = value ? { content: value.content_plain, poll: value.poll } : null;
	},
	[types.GET_MESSAGE_DRAFT_FAIL] (state, error) {
		if(!error.manual) return
		state.messageDraftBusy = false;
	},
	[types.CREATE_MESSAGE_DRAFT] () {
		this.state.messageList.conversationMessagesBusy = true;
	},
	[types.CREATE_MESSAGE_DRAFT_SUCCESS] () {
		this.state.messageList.conversationMessagesBusy = false;
	},
	[types.CREATE_MESSAGE_DRAFT_FAIL] () {
		this.state.messageList.conversationMessagesBusy = false;
	},
	[types.DELETE_MESSAGE_DRAFT] (state) {
		state.messageDraftBusy = true;
		this.state.overview.currentConversation.draftMessageContent = '';
		this.state.overview.currentConversation.draftMessagePolling = null;
	},
	[types.DELETE_MESSAGE_DRAFT_SUCCESS] (state) {
		state.messageDraft = null;
		state.messageDraftBusy = false;
	},
	[types.DELETE_MESSAGE_DRAFT_FAIL] (state) {
		state.messageDraftBusy = false;
	},
	clearDraft () {
		// state.messageDraft = ''
		this.state.overview.currentConversation.draftMessageContent = null
		this.state.overview.currentConversation.draftMessagePolling = null;
	},
	updateDraft (state, draft){
		state.messageDraft = draft
		state.messageDraftBusy = false;
	},
};

export const actions = {
	getMessageDraft: (store, groupId) => {
		store.commit(types.GET_MESSAGE_DRAFT);
		let conversation = store.rootState.messageList.conversationsMessagesList.find(conversation => conversation.groupId == groupId)
		if(conversation) {
			store.commit('updateDraft', conversation.messageDraft)
			return Promise.resolve({ update: true })
		}
		return api
			.getMessageDraft(groupId)
			.then(
				(response) => {
					store.commit(types.GET_MESSAGE_DRAFT_SUCCESS, response.data.data);
					store.commit('overview/updateConversations', response.body.data, { root: true });
					return Promise.resolve(response);
				},
				(error) => {
					store.commit(types.GET_MESSAGE_DRAFT_FAIL, error);
					return Promise.reject(errorMessage(error));
				}
			)
			.catch((response) => {
				return Promise.resolve(response);
			});
	},
	deleteMessageDraft: (store, groupId) => {
		store.commit(types.DELETE_MESSAGE_DRAFT);
		return api.deleteMessageDraft(groupId).then(
			(response) => {
				store.commit(types.DELETE_MESSAGE_DRAFT_SUCCESS, response);
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.DELETE_MESSAGE_DRAFT_FAIL, error);
			}
		).catch(() => {
			// Handle promise errors caused by request abort
		});
	},
	createMessageDraft: (store, { groupId, message, polling }) => {
		store.commit(types.CREATE_MESSAGE_DRAFT);
		return api.createMessage(groupId, message, [], true, null, polling).then(
			(response) => {
				store.commit(types.CREATE_MESSAGE_DRAFT_SUCCESS, response);
				store.commit('overview/updateConversations', response.body.data, { root: true });
				return Promise.resolve(response);
			},
			(error) => {
				store.commit(types.CREATE_MESSAGE_DRAFT_FAIL, error);
				return Promise.reject(errorMessage(error));
			}
		);
	},
};

export const getters = {};

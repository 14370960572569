<script>
import { mapState } from 'vuex'
import moment from 'moment'
import i18n from '@i18n/index'

export default {
	computed: {
		...mapState('user', ['user']),
		resetSocketTrigger () {
			return this.user.id
		},
		updateLocaleTrigger () {
			return this.user.locale || 'en'
		},
	},
	watch: {
		resetSocketTrigger: {
			immediate: true,
			handler (newId, oldId) {
				if (oldId) {
					this.$store.commit('socket/resetSocket')
				}
				if (newId) {
					this.$store.commit('socket/registerSocket')
				}
			},
		},
		updateLocaleTrigger: {
			immediate: true,
			handler (value) {
				i18n.locale = value
				moment.locale(value)
			},
		},
	},
	render () {
		return null
	},
}
</script>

import Vue from 'vue'
import VueResource from 'vue-resource'
import { getEnv } from '@env'
import store from '../store/store'

Vue.use(VueResource)
import { readUrlParam } from '@utilsDir/queryStringHandler'
let token = readUrlParam('token')
let token2 = readUrlParam('token2')

const v2Root = getEnv('VUE_APP_FRONTEND_API_MAIN_URL')

Vue.http.options.root = getEnv('VUE_APP_FRONTEND_API_URL')
Vue.http.options.credentials = true;



if(token && token !== ""){
	token = token.replace(/\s?$/,'');
	window.messaging.token = {
		url: "?token=" + token,
		base: token,
		type: 1,
	}
}

if(token2 && token2 !== ""){
	// removes spaces
	token2 = token2.replace(/ /g, '');
	window.messaging.token = {
		url: "?token2=Bearer " + token2,
		base: token2,
		type: 2,
	}
}

Vue.http.interceptors.push(function (request, next) {
	const removeAuthHeaders = request.url.includes(v2Root);
	if (removeAuthHeaders){
		if (token && token !== "") {
			request.headers.delete('Authorization')
			request.headers.set('java-oauth-token', token)
		}
		if (token2 && token2 !== "") {
			request.headers.delete('Authorization-2')
			request.headers.set('Authorization', token2)
		}
	}
	else {
		if (token && token !== "") {
			request.headers.delete('java-oauth-token')
			request.headers.set('Authorization', token)
		}
		if (token2 && token2 !== "") {
			request.headers.delete('Authorization')
			request.headers.set('Authorization-2', "Bearer " + token2)
		}
	}
	next()
})

const requests = {}
async function loginTimeout () {
	return await new Promise(resolve => {
		//spin till something happens in login
		const interval = setInterval(() => {
			if (store.state.general.loginStatuss !== '') {
				resolve();
				clearInterval(interval);
			}
		}, 100);
	});
}

Vue.http.interceptors.push(function (request) {
	return async function (response) {
		if(request.manual){
			response.manual = true
			return Promise.resolve(response)
		}
		if (response.status === 0 && !request.aborted) {
			response.status = 485
			store.state.general.offlineFunction()
			store.commit('general/updateOfflineStatus', true);
		}else{
			store.commit('general/updateOfflineStatus', false);
		}
		if (response.status === 401) {
			store.state.general.unauthorizedFunction()
			if(!store.state.general.isNative){
				store.commit('general/updateLoginStatuss', '');
				if(store.state.general.loginStatuss === ''){
					// wait for login actions, then replay request that failed
					await loginTimeout();
					if(store.state.general.loginStatuss === 'success'){
						return Vue.http(request).then((response) => {
							return response.data.success ? Promise.resolve(response) : Promise.reject(response)
						},
						(error) => Promise.reject(error))
					}
				}
			}
		}
		return response
	}
})

export function getSchools () {
	return Vue.http.get('contact-groups').then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getUser () {
	return Vue.http.get('users/me').then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getContacts (schoolId, yearId, groupId) {
	const options = {
		params: {
			school_id: schoolId,
			study_year_id: yearId,
			group_id: groupId,
		},
	}
	return Vue.http.get('contacts', options).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function createConversation (conversationObject) {
	return Vue.http
		.post('v2/conversations', conversationObject, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then(
			(response) => {
				return response.data.success ? Promise.resolve(response) : Promise.reject(response)
			},
			(error) => Promise.reject(error),
		)
}

export function addConversationMembers (groupId, users) {
	const options = {
		groupId: groupId,
		users: users,
	}
	return Vue.http.post('conversations/' + groupId + '/members', options).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getConversations (page, searchString, filterType, showEmptyContacts, forwarding) {
	const options = {
		params: {
			page: page,
			search: searchString,
			filter: filterType,
			empty: showEmptyContacts,
			forwarding: forwarding,
		},
	}
	return Vue.http
		.get('v2/conversations', {
			before (request) {
				request.params = options.params
				if (requests['getConversations']) {
					requests['getConversations'].aborted = true
					requests['getConversations'].abort()
				}
				requests['getConversations'] = request
			},
		})
		.then(
			(response) => {
				return response.data.success ? Promise.resolve(response) : Promise.reject(response)
			},
			(error) => Promise.reject(error),
		)
}

export function getConversationDetails (groupId) {
	return Vue.http.get('v2/conversations/' + groupId).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getImportantMessageRole (groupId) {
	return Vue.http
		.get('conversations/' + groupId + '/me', {
			before (request) {
				if (requests['getImportantMessageRole']) {
					requests['getImportantMessageRole'].aborted = true
					requests['getImportantMessageRole'].abort()
				}
				requests['getImportantMessageRole'] = request
			},
		})
		.then(
			(response) => {
				return response.data.success ? Promise.resolve(response) : Promise.reject(response)
			},
			(error) => Promise.reject(error),
		)
}

export function getMessageDraft (groupId) {
	return Vue.http
		.get('v2/conversations/' + groupId + '/messages/draft', {
			before (request) {
				if (requests['getMessageDraft']) {
					requests['getMessageDraft'].aborted = true
					requests['getMessageDraft'].abort()
				}
				requests['getMessageDraft'] = request
			},
		})
		.then(
			(response) => {
				return response.data.success ? Promise.resolve(response) : Promise.reject(response)
			},
			(error) => Promise.reject(error),
		)
}

export function deleteMessageDraft (groupId) {
	return Vue.http
		.delete('v2/conversations/' + groupId + '/messages/draft', {
			before (request) {
				if (requests['deleteMessageDraft']) {
					requests['deleteMessageDraft'].aborted = true
					requests['deleteMessageDraft'].abort()
				}
				requests['deleteMessageDraft'] = request
			},
		})
		.then(
			(response) => {
				return response.ok ? Promise.resolve(response) : Promise.reject(response)
			},
			(error) => Promise.reject(error),
		)
}

export function getConversationMembers (groupId, page, search) {
	const options = {
		params: {
			page,
			search,
		},
	}
	return Vue.http
		.get('v2/conversations/' + groupId + '/members', {
			before (request) {
				request.params = options.params
				if (requests['getConversationMembers']) {
					requests['getConversationMembers'].aborted = true
					requests['getConversationMembers'].abort()
				}
				requests['getConversationMembers'] = request
			},
		})
		.then(
			(response) => {
				return response.data.success ? Promise.resolve(response) : Promise.reject(response)
			},
			(error) => Promise.reject(error),
		)
}

export function getMemberStatus (groupId) {
	return Vue.http.get('conversations/' + groupId + '/members/status').then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getConversationAttachments (groupId, type, page) {
	const options = {
		params: {
			page,
			type,
		},
	}
	return Vue.http.get('v2/conversations/' + groupId + '/attachments', options).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getAttachmentPdf (attachmentPath) {
	return Vue.http.get('https://file-preview-staging.ekool.eu/convert?app=messaging&path=' + attachmentPath).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getConversationMessages (messageId, groupId, paginationType, searchString) {
	const options = {
		params: {
			pagination_type: paginationType,
			...(messageId ? { cursor_message_id: messageId } : {}),
			search: searchString,
			limit: 50,
		},
	}
	return Vue.http
		.get('v2/conversations/' + groupId + '/messages', {
			before (request) {
				request.params = options.params
				if (requests['getConversationMessages']) {
					requests['getConversationMessages'].aborted = true
					requests['getConversationMessages'].abort()
				}
				requests['getConversationMessages'] = request
			},
		})
		.then(
			(response) => {
				return response.data.success ? Promise.resolve(response) : Promise.reject(response)
			},
			(error) => Promise.reject(error),
		)
}

export function createMessage (groupId, message, attachments, draft, sendImportant, polling) {
	let options = null
	let url = null
	if (polling) {
		options = {
			group_id: groupId,
			content_plain: message,
			question: polling.question,
			response_visibility: 'all',
			poll_type: 'radio',
			is_draft: draft,
			end_date: polling.end_date,
			options: polling.text_options || polling.options.map(answer => answer.option)
		}
		url = 'v2/polls'
	} else {
		options = {
			group_id: groupId,
			content_plain: message,
			attachments: attachments,
			is_draft: draft,
			is_important: sendImportant,
			poll: null
		}
		url = 'v2/messages'
	}
	return Vue.http.post(url, options).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function editMessage (message, messageId, polling) {
	// TODO: check that polling update works
	// TODO: remove poll if it have been removed from chat-input
	let options = null
	let url = null
	if (polling) {
		options = {
			content_plain: message,
			question: polling.question,
			options: polling.text_options || polling.options.map(answer => answer.option)
		}
		url = 'v2/polls/' + polling.id
	} else {
		options = {
			content_plain: message,
		}
		url = 'v2/messages/' + messageId
	}
	return Vue.http.post(url, options).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function forwardMessages (groupId, users, groups, messages, currentSchool, currentYear) {
	const options = {
		users: users,
		groups: groups,
		messages: messages,
		school_id: currentSchool,
		study_year_id: currentYear,
	}
	return Vue.http.post('v2/conversations/' + groupId + '/messages/forward', options).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function updateFirstSeen (messageId) {
	return Vue.http.post('v2/messages/' + messageId + '/first-seen').then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function submitPollingVote (groupId, pollId, answerId) {
	return Vue.http.post(`v2/conversations/${groupId}/polls/${pollId}/vote`, { poll_option_id: answerId }).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function resetPollingVote (groupId, pollId) {
	return Vue.http.post(`v2/conversations/${groupId}/polls/${pollId}/vote/reset`).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function readAllMessages () {
	return Vue.http.post('v2/messages/read').then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function hideMessage (messageId) {
	return Vue.http.post('v2/messages/' + messageId).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function deleteMessage (messageId) {
	return Vue.http.delete('messages/' + messageId).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getMessageSeenBy (messageId, page) {
	const options = {
		params: {
			page: page,
		},
	}
	return Vue.http.get('v2/messages/' + messageId + '/first-seen', options).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function changeUserPermissions (groupId, userId, permissionLevel) {
	const options = {
		permission_level_id: permissionLevel,
	}
	return Vue.http.post('conversations/' + groupId + '/members/' + userId, options).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function archiveConversation (groupId, userId, archive) {
	const options = {
		is_archived: archive,
	}
	return Vue.http.post('conversations/' + groupId + '/members/' + userId, options).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function clearConversation (groupId) {
	return Vue.http.post('v2/conversations/' + groupId + '/messages').then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function leaveConversation (groupId, userId) {
	const options = {
		has_left: true,
	}
	return Vue.http.post('conversations/' + groupId + '/members/' + userId, options).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function deleteConversation (groupId, userId) {
	return Vue.http.delete('conversations/' + groupId + '/members/' + userId).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function deleteConversationPermanently (groupId) {
	return Vue.http.delete('conversations/' + groupId).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function muteConversation (groupId, muteLevel) {
	return Vue.http.post('conversations/' + groupId + '/members/notifications/' + muteLevel).then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function unmuteConversation (groupId) {
	return Vue.http.delete('conversations/' + groupId + '/members/notifications/').then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function toggleReplies (groupId, groupType) {
	const options = {
		group_type: groupType,
	}
	return Vue.http.post('conversations/' + groupId, options).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getCampaign () {
	return Vue.http.get('users/me/announcements').then(
		(response) => {
			return response.data.success ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function deleteCampaign (campaignId) {
	return Vue.http.delete('users/me/announcements/' + campaignId).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function changeConversationPicture (groupId, picture) {
	const formData = new FormData()
	formData.append('group_picture', picture)

	return Vue.http
		.post('conversations/' + groupId + '/group-picture', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then(
			(response) => {
				return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
			},
			(error) => Promise.reject(error),
		)
}

export function deleteConversationPicture (groupId) {
	return Vue.http.delete('conversations/' + groupId + '/group-picture').then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function changeConversationTitle (groupId, title) {
	const options = {
		title: title,
	}
	return Vue.http.post('conversations/' + groupId, options).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function saveFileToAttachments (payload) {
	return Vue.http.post('attachments', payload).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function fileUpload (file, schoolId, progressFunction) {
	const formData = new FormData()
	formData.append('file', file)
	formData.append('app_name', 'messaging')
	return Vue.http
		.post(`${getEnv('VUE_APP_FRONTEND_MAIN_UPLOAD_URL')}v1/upload`, formData, {
			params: { school_id: schoolId },
			progress (e) {
				if (e.lengthComputable) {
					const progress = Math.floor( e.loaded / e.total * 100 )
					progressFunction(progress)
				}
			},
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then(
			(response) => {
				return saveFileToAttachments(response.data?.data);
			},
			(error) => Promise.reject(error),
		)
}

export function setUserSettings (userSettings) {
	return Vue.http.post('users/me/settings', userSettings).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function getUserSettings () {
	return Vue.http.get('users/me/settings').then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}

export function cancelRequest (requestName) {
	if (requests[requestName]) {
		requests[requestName].manual = true
		requests[requestName].aborted = true
		requests[requestName].abort()
	}
}


export function createMessageReaction (messageId, reaction, preloadId) {
	const options = {
		reaction,
		process_id: preloadId,
	}
	return Vue.http.post(`v2/messages/${messageId}/reactions`, options).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}
export function deleteMessageReaction (messageId, reaction, processId) {
	const options = {
		reaction,
		process_id: processId,
	}
	return Vue.http.delete(`v2/messages/${messageId}/reactions`, { params: options }).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}
export function getMessageReactions (messageId) {
	return Vue.http.get(`v2/messages/${messageId}/reactions`).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}
export function getConversationPollPdf (groupId, pollId) {
	return Vue.http.get(`v2/conversations/${groupId}/polls/${pollId}/export`, { responseType: 'arraybuffer' }).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}
export function getSchoolFeature (schoolId) {
	// return Promise.resolve({ "status":true,"meta":[],"data":[{ "id":8,"feature_key":"alarmbell","name":"Alarmbell","created_at":"2021-11-03 08:15:36","updated_at":"2021-11-03 08:15:36" },{ "id":3,"feature_key":"homework_is_done","name":"Homework is done (detailed list)","created_at":"2021-03-22 12:07:15","updated_at":"2021-03-22 12:07:15" },{ "id":5,"feature_key":"development_cards_files","name":"Development card file upload and delete","created_at":"2021-03-22 12:08:33","updated_at":"2021-03-22 12:08:33" },{ "id":4,"feature_key":"riks","name":"RIKS library integration","created_at":"2021-03-22 12:07:37","updated_at":"2021-03-22 12:07:37" },{ "id":6,"feature_key":"development_cards","name":"Development cards functionality","created_at":"2021-09-23 06:24:10","updated_at":"2021-09-23 06:24:10" },{ "id":7,"feature_key":"consent_management","name":"Consent Management","created_at":"2021-09-23 06:24:25","updated_at":"2021-09-23 06:24:25" },{ "id":11,"feature_key":"messaging_polling","name":"Messaging polling","created_at":"2022-04-22 11:49:10","updated_at":"2022-04-22 11:49:10" },{ "id":11,"feature_key":"messaging_polling","name":"Messaging polling","created_at":"2022-04-22 11:49:10","updated_at":"2022-04-22 11:49:10" }] })
	return Vue.http.get(`${v2Root}schools/${schoolId}/package-features`).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}
export function getSchoolSizeLimit (schoolId) {
	return Vue.http.get(`${v2Root}schools/${schoolId}/file-size-max-upload`).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}


export function deleteMessageAttachment (attachmentId) {

	return Vue.http.delete(`attachments/${attachmentId}`).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}


export async function getSchoolLogos (schoolIds) {
	const options = {
		params: {
			'filter_groups[schools][school_ids]': schoolIds,
		},
	}
	return Vue.http.get(`${v2Root}schools/logo`, options).then(
		(response) => {
			return response.data.success || response.ok ? Promise.resolve(response) : Promise.reject(response)
		},
		(error) => Promise.reject(error),
	)
}